import React, { useContext } from 'react';
import { TemplateContext } from '../../state/TemplateContext';

const MagicPreview = () => {
  const [template] = useContext(TemplateContext);

  const PreviewText = () => (
    <div
      className="preview-text"
      style={{ backgroundColor: template.options.GlobalFont.gblBodyColor }}
    />
  );

  const PreviewTextSmall = () => (
    <div
      className="preview-text-small"
      style={{ backgroundColor: template.options.GlobalFont.gblBodyColor }}
    />
  );

  const PreviewTextTiny = () => (
    <div
      className="preview-text-tiny"
      style={{ backgroundColor: template.options.GlobalFont.gblBodyColor }}
    />
  );

  const PreviewHeader = () => (
    <div
      className="preview-header"
      style={{ backgroundColor: template.options.GlobalFont.gblHeaderColor }}
    />
  );

  return (
    <div
      className="preview-container"
      style={{
        backgroundColor: template.options.Settings.backgroundColor,
      }}
    >
      <div className="preview-body">
        <PreviewHeader />
        <PreviewText />
        <PreviewText />
        <div
          className="preview-button-large"
          style={{
            backgroundColor: template.options.GlobalButton.gblButtonColor,
          }}
        />
        <PreviewHeader />
        <PreviewText />
        <PreviewText />
        <PreviewText />
        <div className="weird-row">
          <div className="weird-1">
            <div className="image" />
            <PreviewText />
            <PreviewText />
          </div>
          <div className="weird-2">
            <PreviewTextSmall />
            <PreviewTextTiny />
          </div>
          <div className="weird-3">
            <div>
              <PreviewTextSmall />
            </div>
            <div>
              <PreviewTextSmall />
              <PreviewTextSmall />
            </div>
          </div>
        </div>
        <div className="image-row">
          <div className="image-container">
            <div className="image" />
            <div
              className="image-button"
              style={{
                backgroundColor: template.options.GlobalButton.gblButtonColor,
              }}
            />
          </div>
          <div className="image-container">
            <div className="image" />
            <div
              className="image-button"
              style={{
                backgroundColor: template.options.GlobalButton.gblButtonColor,
              }}
            />
          </div>
        </div>
      </div>
      <div className="footer-row">
        <div className="circles">
          <div className="circle" />
          <div className="circle" />
          <div className="circle" />
        </div>
        <PreviewText />
        <PreviewText />
        <PreviewText />
      </div>
    </div>
  );
};

export default MagicPreview;
