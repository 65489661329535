import React, { useState } from 'react';
import { ActionList, Button, Popover, Stack } from '@shopify/polaris';
import { DeleteMajor, ExchangeMajor } from '@shopify/polaris-icons';

const imgPlaceholder = '/Content/images/placeholder.png';

const ImageOption = ({ value, onChange, setDisplayOptions }) => {
  const [popOpen, setPopOpen] = useState(false);

  const selectNewImage = () => {
    setDisplayOptions({
      themeVisible: false,
      showAdvanced: false,
      showImages: (newVal) => {
        onChange(newVal);
        setDisplayOptions({
          themeVisible: false,
          showAdvanced: false,
          showImages: false,
          showGlobal: false,
        });
      },
      showGlobal: false,
    });
  };

  if (value && value.toLowerCase() === imgPlaceholder) {
    return (
      <div
        style={{
          backgroundColor: 'lightgray',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '3rem',
        }}
      >
        <Button onClick={selectNewImage}>Select Image</Button>
      </div>
    );
  }

  const url = value;
  return (
    <Stack vertical alignment="center">
      <img
        style={{
          maxHeight: 300,
          maxWidth: '100%',
        }}
        src={url}
        alt="Selected"
      />
      <Popover
        active={popOpen}
        onClose={() => setPopOpen(!popOpen)}
        activator={
          <Button disclosure onClick={() => setPopOpen(!popOpen)}>
            Change
          </Button>
        }
      >
        <ActionList
          actionRole="menuitem"
          items={[
            {
              content: 'Change Image',
              icon: ExchangeMajor,
              onAction: selectNewImage,
            },
            {
              content: 'Remove Image',
              icon: DeleteMajor,
              onAction: () => {
                onChange(imgPlaceholder);
                setPopOpen(false);
              },
            },
          ]}
        />
      </Popover>
    </Stack>
  );
};

export default ImageOption;
