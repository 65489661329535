export const getRefType = (templateId) => templateId.substring(0, 2);
export const getAfRefType = (templateId) => templateId.substring(0, 4);

export const showComponentForRefType = (
  allowedNotificationTypes,
  templateId
) => {
  const afRefType = getAfRefType(templateId);
  const refType = getRefType(templateId);
  if (allowedNotificationTypes) {
    if (
      allowedNotificationTypes.find((el) => el === refType) ||
      allowedNotificationTypes.find((el) => el === afRefType)
    ) {
      return true;
    }
    return false;
  }
  return true;
};
