import React from 'react';
import { Stack, Heading, TextStyle } from '@shopify/polaris';

const DataListRow = ({ name, value }) => (
  <Stack distribution="equalSpacing">
    <h2>{name}</h2>
    <Heading>
      <TextStyle variation="positive">{value}</TextStyle>
    </Heading>
  </Stack>
);

export default DataListRow;
