const Config = {
  spently: {
    server: 'https://prod-polaris.azurewebsites.net',
    publicApi: 'https://spently-api.azurewebsites.net',
    azureBlobContentFolderUrl:
      'https://productionpolaris.blob.core.windows.net/image-placeholders',
    reco: 'https://spently-recommendation.azurewebsites.net',
    shopify: {
      scopes: [
        'write_orders',
        'write_script_tags',
        'write_products',
        'write_content',
        'write_fulfillments',
        'write_themes',
        'write_customers',
        'write_marketing_events',
        'read_price_rules',
        'write_price_rules',
        'write_draft_orders',
        'read_all_orders',
        'read_inventory',
        'read_locations',
        'write_shipping',
        'read_checkouts',
      ],
      apiKey: '3f4db21645e8107404eb66513d54c987',
      retUrl: 'https://shopify.uporder.com/',
    },
    logging: {
      appInsights: {
        key: '863402c1-b180-46ec-b6f1-bac97326b667',
      },
      googleAnalytics: {
        key: 'G-XWSRENL1DX',
        debug: false,
      },
      fullStory: {
        orgId: 'GE9XS',
      },
    },
    intercom: {
      app_id: 'b287081fcfae01b51848211be18c9fddc0a57832',
    },
    googleAnalytics: {
      tracking_id: 'G-EEJM8WLP14',
    },
  },
  refersion: {
    publicKey: 'pub_618e816549c52def6036',
  },
};

export default Config;
