import {
  Navigation,
  Scrollable,
  SkeletonDisplayText,
  Stack,
} from '@shopify/polaris';
import React from 'react';
import theme from '../../../framework/util/theme';

const SkeletonNavItem = () => (
  <Stack wrap={false}>
    <div
      style={{
        width: '1.25rem',
        height: '1.25rem',
        opacity: 0.6,
        backgroundColor: 'lightgray',
        margin: '.5rem',
      }}
    />
    <div
      style={{
        width: '10rem',
        height: '1.25rem',
        opacity: 0.6,
        backgroundColor: 'lightgray',
        marginTop: '.5rem',
      }}
    />
  </Stack>
);

const SkeletonComponentTree = () => (
  <Scrollable
    shadow
    style={{
      height: 'calc(100vh - 3.5rem)',
      margin: -16,
      marginLeft: -32,
      backgroundColor: theme().colors.surface,
      padding: '0',
    }}
  >
    <Navigation>
      <div
        style={{
          padding: '1rem 0 1.5rem 1rem',
        }}
      >
        <SkeletonDisplayText />
        <br />
        <Navigation.Section separator items={[]} />
        <SkeletonNavItem />
        <Navigation.Section separator items={[]} />
        <SkeletonNavItem />
        <SkeletonNavItem />
        <SkeletonNavItem />
        <SkeletonNavItem />
        <SkeletonNavItem />
        <Navigation.Section separator items={[]} />
        <SkeletonNavItem />
      </div>
    </Navigation>
  </Scrollable>
);

export default SkeletonComponentTree;
