import { Subheading, Stack } from '@shopify/polaris';
import React from 'react';
import BuilderOption from '../../BuilderOption';

const ButtonFont = ({ settings, setFontSettings }) => (
  <Stack spacing="loose" vertical>
    <Subheading>BUTTON FONT</Subheading>
    <BuilderOption
      option={{
        type: 'font',
        label: 'Font Family',
        name: 'gblBtnFamily',
        defaultValue: settings.gblBtnFamily,
      }}
      isGlobal
      onChange={setFontSettings}
    />
    <BuilderOption
      option={{
        type: 'multicolor',
        name: 'gblBtnFontColor',
        label: 'Button Font color',
        defaultValue: settings.gblBtnFontColor,
      }}
      isGlobal
      onChange={setFontSettings}
    />
    <BuilderOption
      option={{
        type: 'number',
        label: 'Font Size',
        name: 'gblBtnFontSize',
        defaultValue: settings.gblBtnFontSize,
        max: 40,
        unit: 'px',
      }}
      isGlobal
      onChange={setFontSettings}
    />
    <BuilderOption
      option={{
        type: 'checkbox',
        label: 'Bold font',
        name: 'gblBtnBold',
        defaultValue: settings.gblBtnBold,
      }}
      isGlobal
      onChange={setFontSettings}
    />
    <BuilderOption
      option={{
        type: 'checkbox',
        label: 'Italicize font',
        name: 'gblBtnItalic',
        defaultValue: settings.gblBtnItalic,
      }}
      isGlobal
      onChange={setFontSettings}
    />
    <BuilderOption
      option={{
        type: 'checkbox',
        label: 'Uppercase font',
        name: 'gblBtnUppercase',
        defaultValue: settings.gblBtnUppercase,
      }}
      isGlobal
      onChange={setFontSettings}
    />
  </Stack>
);

export default ButtonFont;
