import React from 'react';
import {
  HomeMajor,
  NotificationMajor,
  SettingsMajor,
  StoreStatusMajor,
} from '@shopify/polaris-icons';
import { Navigation } from '@shopify/polaris';
import { useLocation } from 'react-router-dom';

const Nav = () => {
  const location = useLocation();
  if (location.pathname === '/Builder') {
    return null;
  }

  return (
    <Navigation location={location.pathname}>
      <Navigation.Section
        items={[
          {
            url: '/',
            exactMatch: true,
            label: 'Home',
            icon: HomeMajor,
            subNavigationItems: [
              {
                url: '/',
                exactMatch: true,
                disabled: false,
                icon: null,
                label: 'Analytics',
              },
              {
                url: '/Reports',
                label: 'Reports',
              },
            ],
          },
          {
            url: '/Notifications',
            label: 'Notification Templates',
            icon: NotificationMajor,
          },
          {
            url: '/CompanySettings',
            label: 'Settings',
            icon: SettingsMajor,
          },
          {
            url: '/Usage',
            label: 'Usage & Plans',
            icon: StoreStatusMajor,
          },
        ].filter(Boolean)}
      />
    </Navigation>
  );
};

export default Nav;
