import {
  Stack,
  Heading,
  Button,
  FormLayout,
  Checkbox,
  Layout,
} from '@shopify/polaris';
import { CancelSmallMinor, SettingsMajor } from '@shopify/polaris-icons';
import React, { useContext, useState } from 'react';
import PanNavItem from '../../../framework/ui/PanNavItem';
import { TemplateContext } from '../../../state/TemplateContext';
// eslint-disable-next-line import/no-cycle
import BuilderOption from '../BuilderOption';
import RightSidebar from './RightSidebar';

const OptionsSideBar = ({
  components,
  currentSelected,
  handleChange,
  displayOptions,
  setDisplayOptions,
  clearSelected,
  currentSelectedComponent,
}) => {
  const [, updateTemplate] = useContext(TemplateContext);

  const [optVal, setOptVal] = useState(
    currentSelected.child
      ? currentSelectedComponent.columns[currentSelected.child]
      : currentSelectedComponent
  );
  let currentComponent =
    components && components.find((c) => c.key === currentSelected.name);

  if (currentSelected.child) {
    currentComponent =
      components &&
      // eslint-disable-next-line no-underscore-dangle
      components.find((c) => c.key === optVal.id || c.key === optVal._id);
  }

  const optionChange = async (n, evt) => {
    const newOpts = { ...optVal };
    if (currentSelected.child) {
      const cid = currentSelected.child;
      const prop = cid ? `columns.${cid}.${n}` : n;
      const oldVal = currentSelectedComponent.columns[cid][n];
      newOpts[n] = evt;
      setOptVal(newOpts);
      handleChange(prop, oldVal, evt);
    } else {
      const oldVal = currentSelectedComponent[n] || newOpts[n];
      newOpts[n] = evt;
      setOptVal(newOpts);
      handleChange(n, oldVal, evt);
    }
  };

  const any =
    currentComponent && currentComponent.options.some((o) => o.advanced);

  let childComponent = null;
  if (currentSelected.child) {
    const childType =
      // eslint-disable-next-line no-underscore-dangle
      currentSelectedComponent.columns[currentSelected.child]._id;
    childComponent = components && components.find((c) => c.key === childType);
  }

  return (
    <RightSidebar
      footer={
        <PanNavItem
          item={{
            icon: SettingsMajor,
            label: 'Remove Component',
            onClick: () => {
              if (currentSelected.child) {
                updateTemplate(
                  'REMOVECHILD',
                  null,
                  JSON.stringify(
                    currentSelectedComponent.columns[currentSelected.child]
                  ),
                  currentSelected
                );
              } else {
                updateTemplate(
                  'REMOVE',
                  JSON.stringify(currentSelectedComponent),
                  currentSelected.key
                );
              }
              // setShowAdvanced(false);
              clearSelected();
            },
          }}
        />
      }
    >
      {currentComponent && (
        <Layout>
          <Layout.Section>
            <div style={{ paddingBottom: '1rem' }}>
              <Stack spacing="loose" distribution="equalSpacing">
                <Heading>{currentComponent.name}</Heading>
                <Button plain onClick={clearSelected} icon={CancelSmallMinor} />
              </Stack>
            </div>
            <Stack vertical>
              {any && (
                <Checkbox
                  label="Show Advanced"
                  checked={displayOptions.showAdvanced}
                  onChange={(e) =>
                    setDisplayOptions({ ...displayOptions, showAdvanced: e })
                  }
                />
              )}
              {currentComponent && currentComponent.global && (
                <Checkbox
                  label="Use Template Settings"
                  checked={`${displayOptions.showGlobal}` === 'true'}
                  onChange={(val) => {
                    setDisplayOptions({ ...displayOptions, showGlobal: val });
                    optionChange(currentComponent.global, val);
                  }}
                />
              )}
            </Stack>
          </Layout.Section>
          <Layout.Section>
            <div>
              <FormLayout>
                {currentComponent &&
                  currentComponent.html &&
                  (currentSelected.child
                    ? childComponent.options
                    : currentComponent.options
                  ).map(
                    (o) =>
                      (!o.advanced || displayOptions.showAdvanced) && (
                        <BuilderOption
                          key={o.name || o.label || o.type}
                          option={o}
                          setDisplayOptions={setDisplayOptions}
                          values={
                            currentSelected.child
                              ? currentSelectedComponent.columns[
                                  currentSelected.child
                                ]
                              : currentSelectedComponent
                          }
                          onChange={optionChange}
                          dependsOnValue={
                            optVal[o.dependsOn] || optVal[o.dependsOnBkwd]
                          }
                        />
                      )
                  )}
              </FormLayout>
            </div>
          </Layout.Section>
        </Layout>
      )}
    </RightSidebar>
  );
};

export default OptionsSideBar;
