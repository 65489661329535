/* eslint-disable max-lines */
import { getShop, getFeature } from '../framework/util/auth';

// check if the store has the new notification type flag
const hasNewNotifitionTypeFlag = () => getFeature('NewNotifitionTypeFlag');

// check if the template support new notification type
// we only support new notification type for themeId 0030 and hasNewNotifitionTypeFlag is true
const supportNewNotificaitonType = (template) =>
  hasNewNotifitionTypeFlag() && template.themeId === '0030';

export const urlDate = (date) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const currentDate = new Date(date);
  const day = currentDate.getDate();
  const month = months[currentDate.getMonth()];
  const year = currentDate.getFullYear();

  return `${day}-${month}-${year}`;
};

// list of template menus for publish page
export const notificationMenus = [
  {
    title: 'Order processing',
    options: [
      { value: 'OI', label: 'Order confirmation' },
      { value: 'DI', label: 'Draft order invoice' },
      { value: 'SC', label: 'Shipping confirmation' },
    ],
  },
  {
    title: 'Local pick up',
    options: [
      { value: 'OR', label: 'Ready for local pickup' },
      { value: 'OH', label: 'Picked up by customer' },
    ],
  },
  {
    title: 'Local delivery',
    options: [
      { value: 'OD', label: 'Order out for local delivery' },
      { value: 'DE', label: 'Order locally delivered' },
      { value: 'MD', label: 'Order missed local delivery' },
    ],
  },
  {
    title: 'Gift cards',
    options: [
      { value: 'GC', label: 'New gift card' },
      { value: 'GR', label: 'Gift card receipt' },
    ],
  },
  {
    title: 'Order exceptions',
    options: [
      { value: 'IN', label: 'Order invoice' },
      { value: 'OE', label: 'Order edited' },
      { value: 'OC', label: 'Order canceled' },
      { value: 'RN', label: 'Order refund' },
      { value: 'AC', label: 'Abandoned checkout' },
      { value: 'OP', label: 'Order payment receipt' },
    ],
  },
  {
    title: 'Payments',
    options: [
      { value: 'PY', label: 'Payment error' },
      { value: 'PF', label: 'Pending payment error' },
      { value: 'PS', label: 'Pending payment success' },
      { value: 'PD', label: 'Payment reminder' },
    ],
  },
  {
    title: 'Point of Sale',
    options: [
      { value: 'PR', label: 'POS and mobile receipt' },
      // store need to activate POS for the following options
      { value: 'EP', label: 'POS abandoned checkout' },
      { value: 'PE', label: 'POS exchange receipt' },
      { value: 'ER', label: 'POS Exchange V2 Receipt' },
      { value: 'PC', label: 'POS email to customer ' },
    ],
  },
  {
    title: 'Shipping updated',
    options: [
      { value: 'SU', label: 'Shipping update' },
      { value: 'SO', label: 'Out for delivery' },
      { value: 'SD', label: 'Delivered' },
    ],
  },
  {
    title: 'Returns',
    options: [
      { value: 'RL', label: 'Order-level return label created' },
      { value: 'RR', label: 'Return request received' },
      { value: 'RA', label: 'Return request approved' },
      { value: 'RD', label: 'Return request declined' },
      { value: 'RC', label: 'Return Created' },
    ],
  },
  {
    title: 'Accounts and outreach',
    options: [
      { value: 'AA', label: 'Customer account invite' },
      { value: 'CW', label: 'Customer account welcome' },
      { value: 'CP', label: 'Customer account password reset' },
      { value: 'CC', label: 'Contact customer' },
      {
        value: 'CU',
        label: 'Customer Payment Method Update Request',
        isFeature: true,
      },
      {
        value: 'CR',
        label: 'Customer Payment Method Restore Request',
        isFeature: true,
      },
      { value: 'BE', label: 'B2B access email' },
      {
        value: 'BL',
        label: 'B2B location update payment method',
        isFeature: true,
      },
    ],
  },
  {
    title: 'Marketing double opt-in',
    options: [{ value: 'MC', label: 'Customer marketing confirmation' }],
  },
  {
    title: 'Subscriptions',
    options: [
      { value: 'SN', label: 'New subscription order', isFeature: true },
      { value: 'SP', label: 'Subscription Paused', isFeature: true },
      { value: 'SS', label: 'Subscription Skipped', isFeature: true },
      { value: 'UC', label: 'Subscription canceled', isFeature: true },
      { value: 'SE', label: 'Subscription Expired', isFeature: true },
      { value: 'SF', label: 'Payment failure', isFeature: true },
      { value: 'SM', label: 'Subscription Resumed', isFeature: true },
      { value: 'SR', label: 'Payment Failure (Retrying)', isFeature: true },
      { value: 'SL', label: 'Payment Failure (Last Attempt)', isFeature: true },
    ],
  },
];

// filter out options that has isFeature is true base on notificationMenus,
const filterFeatureFlagMenus = notificationMenus.reduce((acc, menu) => {
  const filteredOptions = menu.options.filter((option) => !option.isFeature);

  acc.push({
    ...menu,
    options: filteredOptions,
  });

  return acc;
}, []);

// generate emailTypes from notificationMenus
// we only use emailTypes value for report dropdown, it is not related to template,
// so we don't need to pass in template themeId here
export const emailTypes = (
  hasNewNotifitionTypeFlag() ? notificationMenus : filterFeatureFlagMenus
).reduce((acc, menu) => {
  menu.options.forEach((option) => {
    acc[option.value] = option.label;
  });
  return acc;
}, []);

/*
 * Retrieves the supported notification menus based on the provided template themeId.
 * @param {Object} template - The template object.
 * @returns {Array} - An array of supported notification menus.
 */
export const getSupportedNotificationMenus = (template) => {
  // Check if a template is provided and its themeId is not '0030'
  if (template && !supportNewNotificaitonType(template)) {
    return filterFeatureFlagMenus;
  }

  // Copy the original notificationMenus array to avoid modifying the original
  return [...notificationMenus];
};

export const formatEmailType = (val) => emailTypes[val] || val;

// key value pair for all notification types
export const notificationLocations = {
  OI: 'order_confirmation',
  OC: 'order_cancelled',
  OE: 'order_edited',
  IN: 'order_invoice',
  RN: 'refund_notification',
  DI: 'draft_order_invoice',
  EP: 'buy_online',
  AC: 'abandoned_checkout_notification',
  PR: 'store_receipt',
  OH: 'pickup_receipt',
  OR: 'ready_for_pickup',
  PE: 'pos_exchange_receipt',
  GC: 'gift_card_notification',
  PY: 'failed_payment_processing',
  SC: 'shipping_confirmation',
  SU: 'shipping_update',
  SO: 'shipment_out_for_delivery',
  SD: 'shipment_delivered',
  RL: 'return_label_notification',
  OD: 'local_out_for_delivery',
  DE: 'local_delivered',
  MD: 'local_missed_delivery',
  AA: 'customer_account_activate',
  CW: 'customer_account_welcome',
  CP: 'customer_account_reset',
  CC: 'contact_buyer',
  MC: 'customer_marketing_confirmation',
  PS: 'pending_payment_success',
  PF: 'pending_payment_failure',
  GR: 'gift_card_confirmation',
  PD: 'payment_reminder',
  RR: 'return_requested',
  RA: 'return_approved',
  RD: 'return_declined',
  ER: 'pos_exchange_v2_receipt',
  CU: 'customer_update_payment_method',
  CR: 'customer_restore_payment_method',
  BE: 'company_contact_welcome_email',
  BL: 'company_location_update_payment_method',
  PC: 'pos_send_cart',
  RC: 'return_created',
  OP: 'order_payment_receipt',
  SN: 'subscription_new',
  SL: 'subscription_payment_failure_last_attempt',
  SR: 'subscription_payment_failure_retrying',
  SF: 'subscription_payment_failure',
  SS: 'subscription_skipped',
  SM: 'subscription_resumed',
  UC: 'subscription_canceled',
  SP: 'subscription_paused',
  SE: 'subscription_expired',
};

export const getNotificaitonEditUrl = (type) => {
  const storeName = getShop('spently');
  return `https://${storeName}.myshopify.com/admin/email_templates/${notificationLocations[type]}/edit`;
};

export const isValidEmail = (val) => {
  if (!val) {
    return false;
  }
  const regEmail =
    // eslint-disable-next-line max-len
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regEmail.test(val);
};
