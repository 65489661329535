/* eslint-disable jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events */
import React, { useContext, useState } from 'react';
import {
  Popover,
  ColorPicker,
  hsbToHex,
  rgbToHsb,
  Stack,
  TextField,
} from '@shopify/polaris';
import { TemplateContext } from '../../../state/TemplateContext';

// https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
const hexToRgb = (hex) => {
  if (!hex || hex[0] !== '#') {
    return { red: 0, green: 0, blue: 0 };
  }
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const replacedHex = hex.replace(
    shorthandRegex,
    (m, r, g, b) => r + r + g + g + b + b
  );

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(replacedHex);
  return result
    ? {
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16),
      }
    : null;
};

const MulticolorOption = ({ value, onChange, label }) => {
  const [picker, setPicker] = useState(false);
  const [template] = useContext(TemplateContext);
  const [textValue, setTextValue] = useState(value);
  const [pickedColor, setColor] = useState(rgbToHsb(hexToRgb(value)));

  // TODO: remove this as it's a temp fix for Followups
  if (!template.options.GlobalFont) {
    template.options.GlobalFont = {};
  }

  const onColorInputBlur = () => {
    if (hexToRgb(textValue)) {
      onChange(textValue);
    } else {
      onChange(value);
      setTextValue(value);
    }
    setPicker(false);
  };

  const ThemeColor = ({ color, colorLabel }) => {
    const style = {
      width: '1.75rem',
      height: '1.75rem',
      borderRadius: 3,
      border: '1px solid black',
      margin: '12px 0px',
      marginRight: '.5rem',
      backgroundColor: color,
      cursor: 'pointer',
    };

    return (
      <button
        style={style}
        onClick={() => {
          onChange(color);
          setTextValue(color);
        }}
        type="button"
        title={colorLabel}
        aria-label={colorLabel}
      />
    );
  };

  return (
    <Popover
      active={picker}
      sectioned
      activator={
        <Stack>
          <div
            role="button"
            aria-label="Color selector"
            onClick={() => setPicker(true)}
            style={{
              height: 40,
              width: 40,
              borderRadius: 40,
              boxShadow: 'inset 0 0 0 1px rgb(0 0 0 / 19%)',
              backgroundColor: value,
            }}
          />
          <Stack vertical>
            <h5>
              {label}
              <br />
              <small>{value}</small>
            </h5>
          </Stack>
        </Stack>
      }
      onClose={() => setPicker(false)}
    >
      <Popover.Pane fixed>
        <Popover.Section>
          <div style={{ width: '200px' }}>
            <ColorPicker
              color={pickedColor}
              onChange={(c) => {
                setColor(c);
                const hex = hsbToHex(c);
                setTextValue(hex);
                onChange(hex);
              }}
            />
            <br />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                style={{
                  borderRadius: 3,
                  backgroundColor: value,
                  width: 42,
                  marginRight: 8,
                  border: '1px solid black',
                }}
              />
              <TextField
                value={textValue}
                onChange={setTextValue}
                onBlur={onColorInputBlur}
              />
            </div>
            <div>
              <div
                style={{
                  paddingTop: 16,
                  fontSize: '.75rem',
                  lineHeight: '1rem',
                  color: 'var(--p-text-subdued)',
                }}
              >
                Template Colors
              </div>
              <ThemeColor
                colorLabel="Email Background Color"
                color={template.options.Settings.backgroundColor}
              />
              <ThemeColor
                colorLabel="Header Font Color"
                color={template.options.GlobalFont.gblHeaderColor}
              />
              <ThemeColor
                colorLabel="Body Font Color"
                color={template.options.GlobalFont.gblBodyColor}
              />
              <ThemeColor
                colorLabel="Button Color"
                color={template.options.GlobalButton.gblButtonColor}
              />
            </div>
          </div>
        </Popover.Section>
      </Popover.Pane>
    </Popover>

    // <TextField
    //   label={label}
    //   onChange={onChange}
    //   value={value}
    //   suffix={suffix}
    // />
  );
};

export default MulticolorOption;
