import { Stack } from '@shopify/polaris';
import React, { useContext } from 'react';
import { TemplateContext } from '../../../state/TemplateContext';
import BuilderOption from '../BuilderOption';

const ButtonSettings = () => {
  const [{ options }, updateTemplate] = useContext(TemplateContext);
  const btnSettings = options.GlobalButton;

  const change = (propName, val) => {
    const prop =
      propName === 'ButtonColor' ? `gbl${propName}` : `gblBtn${propName}`;
    updateTemplate(prop, btnSettings[prop], val, 'GlobalButton');
  };

  return (
    <Stack spacing="loose" vertical>
      <BuilderOption
        option={{
          type: 'multicolor',
          name: 'ButtonColor',
          label: 'Button Color',
          defaultValue: btnSettings.gblButtonColor,
        }}
        onChange={change}
      />
      <BuilderOption
        option={{
          type: 'number',
          label: 'Height',
          defaultValue: btnSettings.gblBtnHeight,
          min: 1,
          unit: 'px',
        }}
        onChange={change}
      />
      <BuilderOption
        option={{
          type: 'number',
          label: 'Width',
          defaultValue: btnSettings.gblBtnWidth,
          min: 1,
          unit: 'px',
        }}
        onChange={change}
      />
      <BuilderOption
        option={{
          type: 'number',
          name: 'Corner',
          label: 'Rounded Corners',
          defaultValue: btnSettings.gblBtnCorner,
          min: 1,
          unit: 'px',
        }}
        onChange={change}
      />
      <BuilderOption
        option={{
          type: 'number',
          name: 'BorderWidth',
          label: 'Border Width',
          defaultValue: btnSettings.gblBtnBorderWidth,
          min: 0,
          unit: 'px',
        }}
        onChange={change}
      />
      <BuilderOption
        option={{
          type: 'multicolor',
          name: 'BorderColor',
          label: 'Border Color',
          defaultValue: btnSettings.gblBtnBorderColor,
        }}
        onChange={change}
      />
    </Stack>
  );
};

export default ButtonSettings;
