/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import theme from '../../framework/util/theme';
import { buildHtmlForComponent } from '../../util/builder';
import { logError } from '../../util/logging';

const RenderedComponent = ({
  components,
  component,
  selected,
  componentName,
  renderKey,
  componentChange,
  global,
  setId,
  recType,
}) => {
  let html = '';

  const toRender = { ...component, recType };
  if (!component) {
    logError('component is null for', componentName);
    return null;
  }
  // NOTE: uncomment the next line for options to use with the Tester.
  // console.log('building with', JSON.stringify(toRender, null, 4));
  // eslint-disable-next-line no-underscore-dangle
  // if (component._id === 'Referral') {
  //   console.log(
  //     'building with',
  //     JSON.stringify({ ...toRender, global, setId }, null, 4)
  //   );
  // }

  try {
    const vals = { ...toRender, global, setId };
    if (vals.preview === undefined) {
      vals.preview = true;
    }

    // Added global settings to the group child elements
    if (vals.columns) {
      Object.keys(vals.columns).forEach((key) => {
        vals.columns[key].global = global;
      });
    }

    html = buildHtmlForComponent(componentName, vals, components);
  } catch (error) {
    logError('Liquid error ', error);
    html = `Could not parse HTML for component: ${componentName}`;
  }

  if (selected) {
    html = `<div id="selected-component" style="border: 3px solid ${
      theme().colors.primary
    }">${html}</div>`;
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        if (renderKey && componentChange) {
          componentChange(renderKey);
        }
      }}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

export default RenderedComponent;
