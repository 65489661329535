import {
  Button,
  DatePicker,
  Popover,
  Select,
  Stack,
  TextField,
} from '@shopify/polaris';
import { add, startOfMonth, startOfYear } from 'date-fns';
import React, { useState, useCallback } from 'react';
import { logError } from '../util/logging';

const DatePickerDropdown = ({ range, updateRange }) => {
  const [{ month, year }, setDate] = useState({
    month: range.start.getMonth(),
    year: range.start.getFullYear(),
  });

  const [startDate, setStartDate] = useState(range.start);
  const [endDate, setEndDate] = useState(range.end);

  const handleMonthChange = useCallback(
    (m, y) => setDate({ month: m, year: y }),
    []
  );
  const [open, setOpen] = useState(false);
  const options = [
    { label: 'Custom', value: 'custom' },
    { label: 'Today', value: 'today' },
    { label: 'Yesterday', value: 'yesterday' },
    { label: 'Last 7 days', value: 'lastWeek' },
    { label: 'Last 30 days', value: 'last30' },
    { label: 'Last 90 days', value: 'lastThreeMonths' },
    { label: 'Last 365 days', value: 'last365' },
    { label: 'Last Month', value: 'lastMonth' },
    { label: 'Last Year', value: 'lastYear' },
    { label: 'Year to date', value: 'yearToDate' },
  ];

  const [selected, setSelected] = useState('custom');

  const handleSelectChange = useCallback((value) => {
    switch (value) {
      case 'today':
        setStartDate(new Date());
        setEndDate(new Date());
        break;
      case 'yesterday':
        setStartDate(add(new Date(), { days: -1 }));
        setEndDate(add(new Date(), { days: -1 }));
        break;
      case 'lastWeek':
        setEndDate(new Date());
        setStartDate(add(new Date(), { days: -7 }));
        break;
      case 'last30':
        setEndDate(new Date());
        setStartDate(add(new Date(), { days: -30 }));
        break;
      case 'lastThreeMonths':
        setEndDate(new Date());
        setStartDate(add(new Date(), { days: -90 }));
        break;
      case 'last365':
        setEndDate(new Date());
        setStartDate(add(new Date(), { days: -365 }));
        break;
      case 'lastYear':
        setStartDate(add(startOfYear(new Date()), { years: -1 }));
        setEndDate(add(startOfYear(new Date()), { days: -1 }));
        break;
      case 'lastMonth':
        setEndDate(add(startOfMonth(new Date()), { days: -1 }));
        setStartDate(add(startOfMonth(new Date()), { months: -1 }));
        break;
      case 'yearToDate':
        setEndDate(new Date());
        setStartDate(startOfYear(new Date()));
        break;
      default:
        logError(
          'Received a data picker dropdown selecting that is not supported',
          value
        );
        break;
    }
    setSelected(value);
  }, []);

  const activator = (
    <Button onClick={() => setOpen(!open)}>
      {`${startDate.toDateString()} - ${endDate.toDateString()}`}
    </Button>
  );

  return (
    <Popover
      active={open}
      activator={activator}
      onClose={() => setOpen(false)}
      fluidContent
      sectioned
    >
      <Popover.Pane>
        <Popover.Section>
          <div style={{ maxWidth: '480px' }}>
            <Stack vertical>
              <Select
                label="Date range"
                options={options}
                onChange={handleSelectChange}
                value={selected}
              />
              <Stack distribution="fillEvenly">
                <TextField
                  label="Starting"
                  value={startDate.toDateString()}
                  onChange={(val) => setStartDate(val)}
                />
                <TextField
                  label="Ending"
                  value={endDate.toDateString()}
                  onChange={(val) => setEndDate(val)}
                />
              </Stack>
              <DatePicker
                month={month}
                year={year}
                onChange={(val) => {
                  setStartDate(val.start);
                  setEndDate(val.end);
                }}
                onMonthChange={handleMonthChange}
                selected={{ start: startDate, end: endDate }}
                multiMonth
                allowRange
              />
            </Stack>
          </div>
        </Popover.Section>
        <Popover.Section>
          <Stack distribution="equalSpacing">
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button
              primary
              onClick={() => {
                updateRange({ start: startDate, end: endDate });
                setOpen(false);
              }}
            >
              Apply
            </Button>
          </Stack>
        </Popover.Section>
      </Popover.Pane>
    </Popover>
  );
};

export default DatePickerDropdown;
