/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Page,
  Layout,
  Card,
  ExceptionList,
  Stack,
  TextField,
  RangeSlider,
  TextContainer,
  Button,
  Heading,
  TextStyle,
} from '@shopify/polaris';
import { TickMinor } from '@shopify/polaris-icons';
import React, { useState } from 'react';
import { formatCurrency } from '../framework/util/formatters';
import '../cssOverrides/Paywall.scss';
import { postSpently } from '../util/spentlyRest';
import {
  sendGoogleAnalyticsEvent,
  GoogleAnalyticsEvent,
} from '../widgets/googleAnalytics';

const Paywall = () => {
  const [amo, setAmo] = useState(0);
  const [upgrading, setUpgrading] = useState(false);

  const calcPlan = (value) => {
    let cost = 0;

    if (value < 26) {
      cost = 0;
    } else if (value > 26 && value <= 50) {
      cost = 10;
    } else if (value <= 1000) {
      cost = Math.floor(value / 50 + 0.99) * 10;
    } else if (value > 1000 && value <= 5000) {
      cost = Math.floor(value / 100 + 10.99) * 10;
    } else if (value > 5000 && value <= 10000) {
      cost = Math.floor(value / 500 + 30.99) * 10;
    }

    return cost;
  };

  return (
    <Page title="A tool that grows with your business">
      <div className="paywall">
        <Layout>
          <Layout.Section fullWidth>
            <p>
              Boost sales &amp; customer engagement from your most-opened
              emails. Whether you are just starting out or an established brand,
              our price automatically scales up or down based on your order
              volume each month
            </p>
          </Layout.Section>
          <Layout.Section>
            <Card title="Estimate your new price">
              <Card.Section>
                <Stack vertical>
                  <div style={{ width: '12rem' }}>
                    <TextField
                      value={`${amo}`}
                      onChange={(newVal) => {
                        if (!newVal) {
                          setAmo(0);
                          return;
                        }
                        if (!Number.isNaN(newVal)) {
                          const numVal = parseInt(newVal, 10);

                          if (numVal >= 0 && numVal <= 5000) {
                            setAmo(parseInt(newVal, 10));
                          }
                        }
                      }}
                      connectedRight={
                        <TextContainer>Orders per month</TextContainer>
                      }
                    />
                  </div>
                  <RangeSlider
                    value={amo}
                    onChange={setAmo}
                    min={0}
                    max={5000}
                  />
                  <Layout>
                    <Layout.Section oneThird>
                      <Stack vertical>
                        <span> Estimated new price</span>
                        <span>
                          <Heading>
                            <TextStyle variation="positive">
                              {formatCurrency(calcPlan(amo))}
                            </TextStyle>
                            /Month
                          </Heading>
                        </span>
                      </Stack>
                    </Layout.Section>
                    <Layout.Section>
                      <span>
                        The first 25 orders of each month are always free. Your
                        monthly price may go up or down automatically in $10
                        increments based on your order volume.
                      </span>
                    </Layout.Section>
                  </Layout>
                </Stack>
              </Card.Section>
              <Card.Section>
                <a href="mailto:accounts@spentlyhq.com">Contact us</a> if your
                monthly average order volumes are higher than 5,000/mo or you
                support multiple stores so we can tailor a plan for your
                business.
              </Card.Section>
            </Card>
          </Layout.Section>
          <Layout.Section oneThird>
            <Card title="Unlock all UpOrder features">
              <Card.Section>
                <ExceptionList
                  items={[
                    {
                      icon: TickMinor,
                      description:
                        '25+ customizable transactional email templates',
                    },
                    {
                      icon: TickMinor,
                      description: 'Drag-and-drop email designer',
                    },
                    {
                      icon: TickMinor,
                      description:
                        'Dynamic product recommendation & discount modules',
                    },
                    {
                      icon: TickMinor,
                      description: 'Built-in referral program',
                    },
                    {
                      icon: TickMinor,
                      description: 'One-click integrations',
                    },
                    {
                      icon: TickMinor,
                      description: 'ROI-based reporting & analytics',
                    },
                  ]}
                />
              </Card.Section>
            </Card>
          </Layout.Section>
          <Layout.Section fullWidth>
            <Stack distribution="center" alignment="center">
              <Stack vertical spacing="extraTight" alignment="center">
                <Button
                  size="large"
                  loading={upgrading}
                  primary
                  onClick={async () => {
                    sendGoogleAnalyticsEvent(
                      GoogleAnalyticsEvent.START_TRAIL,
                      {}
                    );

                    setUpgrading(true);
                    const resp = await postSpently('/api/account/upgrade', {
                      returnUrl: document.location.href,
                    });
                    document.location = resp.url;
                  }}
                >
                  Get your first 30 days free
                </Button>
                <TextContainer>
                  <small>
                    You can’t set your emails live unless you accept usage
                    billing
                  </small>
                </TextContainer>
              </Stack>
            </Stack>
          </Layout.Section>
        </Layout>
      </div>
    </Page>
  );
};

export default Paywall;
