import { showComponentForRefType } from '../../../util/componentFilter';

/**
 * Get all component keys present in the template, including conditional components.
 * @param {Object} template - The template object.
 * @returns {Array} - An array containing all the component keys.
 */
export const allComponentKeysInTemplate = (template) => {
  // Extract component keys from the main components array
  const componentTempKeys = template.components.map(({ _id }) => _id);

  // Extract keys from conditional components within the template
  let conditionalComponentsKeys = [];
  const conditionalComponents = template.components.filter(
    (c) => c.id === 'Conditional'
  );
  conditionalComponents.forEach((condc) => {
    conditionalComponentsKeys = conditionalComponentsKeys.concat(
      condc.columns
        ? Object.entries(condc.columns).map(([, value]) => value.id)
        : []
    );
  });

  // Combine and return all component keys
  return componentTempKeys.concat(conditionalComponentsKeys);
};

/**
 * Filter available components for selection based on visibility, template keys, notification types, and feature flags.
 * @param {Array} components - The array of components.
 * @param {Array} tempKeys - The array of template keys.
 * @param {Object} template - The template object.
 * @returns {Array} - The filtered array of components.
 */
export const filterAvailableComponents = (components, tempKeys, template) =>
  (components || []).filter(
    (c) =>
      c.visible &&
      (!c.maxPerTemplate || !tempKeys.includes(c.key)) &&
      showComponentForRefType(c.allowedNotificationTypes, template.templateId)
  );
