import { Liquid } from 'liquidjs';
import Config from '../Config';
import { getShop } from '../framework/util/auth';
import { emailShell } from './emailShell';
import FontEscapeFilter from './filters/fontEscapeFilter';

const engine = new Liquid();
engine.registerFilter('base64encode', (a) => btoa(a));
engine.registerFilter('fontescape', FontEscapeFilter);

export const buildHtmlForComponent = (componentName, vals, components) => {
  let html = '';
  // eslint-disable-next-line no-underscore-dangle
  const name = componentName || vals.id || vals._id;
  const comp = components && components.find((c) => c.key === name);
  if (comp && comp.html) {
    const values = {
      ...vals,
      DEFAULT_TABLE_ATTRIBUTES:
        ' cellpadding="0" cellspacing="0" width="100%" ',
      DEFAULT_TABLE_STYLES: 'mso-table-lspace: 0; mso-table-rspace: 0;',
      total_price: '$25.00',
      subtotal_price: '$15.00',
      total_tip: '$0',
      shipping_method: {
        price: '$10.00',
      },
      API_SERVER: Config.spently.server,
      AZURE_CONTENT_FOLDER_URL: Config.spently.azureBlobContentFolderUrl,
      RECO_SERVER: Config.spently.reco,
      PUBLIC_API_SERVER: Config.spently.publicApi,
      shop: getShop('spently'),
    };

    if (vals.columns) {
      const compHtml = [];
      Object.keys(vals.columns).forEach((columnId) => {
        const column = { ...vals.columns[columnId] };
        // eslint-disable-next-line no-underscore-dangle
        const columnType = column._id;
        column.preview = true;
        column.recType = vals.recType;
        const columnHtml = buildHtmlForComponent(
          columnType,
          column,
          components
        );
        compHtml.push(columnHtml);
      });
      values.compHtml = compHtml;
      delete values.columns;
    }

    let globalKey = '';
    Object.keys(values).forEach((v) => {
      if (v.indexOf('gbl') === 0) {
        globalKey = v;
      }
    });

    if (globalKey) {
      const isGlobal = values[globalKey];
      const overrides =
        comp.options.filter(
          (o) => o.dependsOn === globalKey || o.dependsOnBkwd === globalKey
        ) || [];

      overrides.forEach((over) => {
        if (isGlobal && over.dependsOn === globalKey) {
          if (over.overrides) {
            values[over.overrides] = values[over.name];
          } else if (over.name) {
            // logError(
            //   `[${name}]were looking at ${over.name} with: ${isGlobal}`,
            //   over
            // );
          }
        } else if (
          isGlobal &&
          !overrides.find((o) => o.overrides === over.name)
        ) {
          values[over.name] = over.defaultValue;
        }
      });
    }

    comp.options.forEach((opt) => {
      if (opt.name && opt.defaultValue && values[opt.name] === undefined) {
        values[opt.name] = opt.defaultValue;
      }
    });

    const newSection = engine.parseAndRenderSync(comp.html, values);
    html = newSection;
  }

  return html;
};

export const buildHtmlForTemplate = (template, components) => {
  let html = '';
  html += buildHtmlForComponent(
    'Header',
    {
      navBar: template.options.NavigationBar,
      // TODO: hideous data model is hideous!!!
      navLinks: (template.options.Settings.NavLinks || []).map((data) => {
        const link = {};
        link.label = data.text;
        link.value = data.url;
        return link;
      }),
      ...template.options.TemplateHeader,
    },
    components
  );
  // cycle through the components
  // wrap components in a tr
  html +=
    '<tr><td style="border-collapse:collapse;padding:0px 15px" width="100%">';
  template.components.forEach((c) => {
    html += buildHtmlForComponent(
      null,
      { ...c, global: template.options, setId: template.templateSetId },
      components
    );
  });
  html += '</td></tr>';
  // build the html for the footer
  html += buildHtmlForComponent(
    'Footer',
    { ...template.options.TemplateFooter, global: template.options },
    components
  );

  return emailShell(
    html,
    template.options.Settings || {},
    template.templateId.substring(0, 2)
  );
};
