import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';
import { appInsights } from './AppInsights';
import Config from '../Config';

const { logging } = Config.spently;
const { googleAnalytics } = logging;

if (googleAnalytics) {
  ReactGA.initialize(googleAnalytics.key, {
    debug: googleAnalytics.debug,
  });
}

const PageListener = () => {
  const history = useHistory();

  const trackPageView = () => {
    const { pathname } = document.location;
    appInsights.trackPageView({ name: pathname });
    if (googleAnalytics) {
      ReactGA.pageview(pathname);
    }
  };

  useEffect(() => {
    trackPageView();
    history.listen(trackPageView);
  });

  return null;
};

export default PageListener;
