import { Stack } from '@shopify/polaris';
import React, { useContext } from 'react';
import { TemplateContext } from '../../../state/TemplateContext';
import BuilderOption from '../BuilderOption';

const RecommendationSettings = () => {
  const [{ options }, updateTemplate] = useContext(TemplateContext);
  const recSettings = options.GlobalRecommendation || {};

  const change = (propName, val) => {
    const prop = `gblRecommended${propName}`;
    updateTemplate(prop, recSettings[prop], val, 'GlobalRecommendation');
  };

  return (
    <Stack spacing="loose" vertical>
      <BuilderOption
        option={{
          type: 'multicolor',
          name: 'BodyColor',
          label: 'Background Color',
          defaultValue: recSettings.gblRecommendedBodyColor,
        }}
        onChange={change}
      />
      <BuilderOption
        option={{
          type: 'multicolor',
          name: 'HeaderColor',
          label: 'Header Font Color',
          defaultValue: recSettings.gblRecommendedHeaderColor,
        }}
        onChange={change}
      />
      <BuilderOption
        option={{
          type: 'multicolor',
          name: 'Color',
          label: 'Font Color',
          defaultValue: recSettings.gblRecommendedColor,
        }}
        onChange={change}
      />
      <BuilderOption
        option={{
          type: 'number',
          name: 'HeaderSize',
          label: 'Header Font size',
          defaultValue: recSettings.gblRecommendedHeaderSize,
          unit: 'px',
        }}
        onChange={change}
      />
      <BuilderOption
        option={{
          type: 'number',
          label: 'Leading Text Margin',
          name: 'LeadingText',
          defaultValue: recSettings.gblRecommendedLeadingText,
          unit: 'px',
        }}
        onChange={change}
      />
      <BuilderOption
        option={{
          type: 'number',
          name: 'VerticalSpace',
          label: 'Vertical Spacing',
          defaultValue: recSettings.gblRecommendedVerticalSpace,
          unit: 'px',
        }}
        onChange={change}
      />
      <BuilderOption
        option={{
          type: 'number',
          name: 'HorizontalSpace',
          label: 'Horizontal Spacing',
          defaultValue: recSettings.gblRecommendedHorizontalSpace,
          unit: 'px',
        }}
        onChange={change}
      />
      <BuilderOption
        option={{
          type: 'number',
          name: 'TitleSpace',
          label: 'Title Spacing',
          defaultValue: recSettings.gblRecommendedTitleSpace,
          unit: 'px',
        }}
        onChange={change}
      />
    </Stack>
  );
};

export default RecommendationSettings;
