import { Button, FormLayout, Modal } from '@shopify/polaris';
import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { TemplateContext } from '../../../state/TemplateContext';
import NavLink from './NavLink';

const EditLinks = ({ label }) => {
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [{ options }, updateTemplate] = useContext(TemplateContext);
  const defaultNavLinks = useMemo(
    () => [
      {
        key: '0',
        url: '',
        text: 'SHOP',
      },
      {
        key: '1',
        url: '',
        text: 'BLOG',
      },
      {
        key: '2',
        url: '',
        text: 'ABOUT',
      },
      {
        key: '3',
        url: '',
        text: 'FAQ',
      },
    ],
    []
  );
  const [links, setLinks] = useState([]);

  useEffect(() => {
    const NavLinks = options.Settings.NavLinks || defaultNavLinks;
    if (open) {
      setLinks([...NavLinks]);
    }
  }, [open, defaultNavLinks, options.Settings.NavLinks]);

  const onSave = useCallback(() => {
    setSaving(true);
    updateTemplate('NavLinks', options.Settings.NavLinks, links, 'Settings');
    setSaving(false);
    setOpen(false);
  }, [links, options.Settings.NavLinks, updateTemplate]);

  const onChangeLinkValue = useCallback(
    (value, index) =>
      setLinks((prev) => {
        const arr = [...prev];
        arr[index] = value;
        return arr;
      }),
    []
  );

  return (
    <Modal
      instant
      open={open}
      activator={
        <Button
          onClick={() => {
            setOpen(true);
          }}
        >
          {label}
        </Button>
      }
      onClose={() => {
        setOpen(false);
      }}
      title="Edit Navigation Links"
      primaryAction={{
        content: 'Save',
        loading: saving,
        onAction: onSave,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: () => {
            const NavLinks = options.Settings.NavLinks || defaultNavLinks;
            setLinks([...NavLinks]);
            setOpen(false);
          },
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          {links.map((link, index) => (
            <NavLink
              key={link.key}
              index={index}
              defaultNavLinks={defaultNavLinks}
              value={link}
              onChange={(v) => onChangeLinkValue(v, index)}
            />
          ))}
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
};

export default EditLinks;
