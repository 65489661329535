import React, { useEffect, useState } from 'react';
import { getSpently } from '../util/spentlyRest';

const BaseComponentContext = React.createContext({});

const BaseComponentProvider = ({ children }) => {
  const [components, setComponents] = useState(null);

  useEffect(() => {
    const load = async () => setComponents(await getSpently('/api/Component'));

    if (!components) {
      load();
    }
  }, [components]);

  return (
    <BaseComponentContext.Provider value={[components]}>
      {children}
    </BaseComponentContext.Provider>
  );
};

export { BaseComponentContext, BaseComponentProvider };
