import {
  Card,
  Layout,
  Page,
  FormLayout,
  TextField,
  Loading,
  Toast,
  ContextualSaveBar,
} from '@shopify/polaris';
import React, { useEffect, useState } from 'react';
import { getSpently, postSpently } from '../util/spentlyRest';
import SocialSettings from '../widgets/settings/SocialSettings';

const CompanySettings = () => {
  const [settings, setSettings] = useState({});
  const [dirty, setDirty] = useState(false);
  const [saving, setSaving] = useState(false);
  const [active, setActive] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const loadSettings = async () =>
      setSettings(await getSpently('/api/settings'));

    if (!settings.companyId) {
      loadSettings();
    }
  });

  const saveSettings = async () => {
    setSaving(true);
    await postSpently('/api/settings', settings, () => {
      setError('Could not save Settings.');
    });
    setDirty(false);
    setSaving(false);
    setActive(true);
  };

  if (!settings.companyId) {
    return <Loading />;
  }
  const save = (newVals) => {
    setDirty(true);
    setSettings({ ...settings, ...newVals });
  };

  return (
    <>
      {dirty && (
        <ContextualSaveBar
          message="Unsaved changes"
          saveAction={{
            onAction: saveSettings,
            loading: saving,
          }}
          discardAction={{ onAction: () => document.location.reload() }}
        />
      )}
      <Page title="Company settings">
        <Layout>
          <Layout.AnnotatedSection
            title="Contact Information"
            description="For anti-spam law compliance, we use the below contact information 
        in the footer of your emails. All contact information has been synced initially from Shopify."
          >
            <Card sectioned>
              <FormLayout>
                <TextField
                  label="Company Name"
                  value={settings.companyName}
                  onChange={(val) => save({ companyName: val })}
                />
                <TextField
                  label="Website URL"
                  value={settings.companyWebsiteUrl}
                  onChange={(val) => save({ companyWebsiteUrl: val })}
                />
                <TextField
                  label="Sender email"
                  helpText="Your customer will see this address in the email footer and can use it to contact you."
                  value={settings.companySupportEmail}
                  onChange={(val) => save({ companySupportEmail: val })}
                />
                <TextField
                  label="Phone number"
                  value={settings.companyPhone}
                  onChange={(val) => save({ companyPhone: val })}
                />
                <TextField
                  label="Street address"
                  value={settings.companyAddress}
                  onChange={(val) => save({ companyAddress: val })}
                />
                <TextField
                  label="City"
                  value={settings.companyCity}
                  onChange={(val) => save({ companyCity: val })}
                />
                <TextField
                  label="State / Province"
                  value={settings.companyProvince}
                  onChange={(val) => save({ companyProvince: val })}
                />
                <TextField
                  label="Country"
                  value={settings.companyCountry}
                  onChange={(val) => save({ companyCountry: val })}
                />
                <TextField
                  label="ZIP / Postal code"
                  value={settings.companyPostal}
                  onChange={(val) => save({ companyPostal: val })}
                />
              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title="Social links"
            description="Add social icons to your emails for customers to engage with you on social media."
          >
            <Card sectioned>
              <SocialSettings settings={settings} save={save} />
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
        {active && (
          <Toast
            content={error || 'Settings Saved!'}
            error={error}
            onDismiss={() => setActive(!active)}
          />
        )}
      </Page>
    </>
  );
};

export default CompanySettings;
