import { Modal, Stack, TextContainer } from '@shopify/polaris';
import React, { useState } from 'react';

const LoseChangesModal = ({
  loseChangesOpen,
  setLoseChangesOpen,
  discard,
  save,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  return (
    <Modal
      open={loseChangesOpen}
      onClose={() => setLoseChangesOpen(false)}
      title="Discard Changes?"
      primaryAction={{
        content: 'Save',
        loading: isSaving,
        onAction: async () => {
          setIsSaving(true);
          await save();
          setIsSaving(false);
          setLoseChangesOpen(false);
        },
      }}
      secondaryActions={{
        content: 'Continue Anyways',
        onAction: () => {
          setLoseChangesOpen(false);
          discard();
        },
      }}
    >
      <Modal.Section>
        <Stack vertical>
          <Stack.Item>
            <TextContainer>
              <h4>Continue without saving this template?</h4>
            </TextContainer>
          </Stack.Item>
        </Stack>
      </Modal.Section>
    </Modal>
  );
};

export default LoseChangesModal;
