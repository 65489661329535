import { appInsights } from './AppInsights';

/* eslint-disable no-console */
export const logDebug = (msg, obj) => {
  if (!obj) {
    console.log(msg);
    appInsights.trackTrace({ message: msg });
  } else {
    console.log(msg, obj);
    appInsights.trackTrace({ message: `${msg}: ${JSON.stringify(obj)}` });
  }
};

export const logError = (msg, obj) => {
  if (!obj) {
    console.error(msg);
    appInsights.trackTrace({ message: msg });
  } else {
    console.error(msg, obj);
    appInsights.trackTrace({ message: `${msg}: ${JSON.stringify(obj)}` });
  }
};
