/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { RangeSlider, TextField } from '@shopify/polaris';

const NumberOption = ({ value, unit, label, min, max, onChange }) => {
  const [editing, setEditing] = useState(false);
  const suffix = `${value}${unit ? ` ${unit}` : ''}`;
  const suffixStyle = { minWidth: '24px', textAlign: 'right' };

  const Text = () => {
    if (editing) {
      return (
        <div style={{ width: '4rem' }}>
          <TextField
            autoFocus
            selectTextOnFocus
            inputMode="text"
            max={max}
            min={min}
            step={false}
            value={value}
            onChange={(val) => {
              if (!val) {
                onChange(val);
              }
              if (/^\+?(0|[1-9]\d*)$/.test(val)) {
                const num = parseInt(val, 10);
                if (num <= max) {
                  onChange(val);
                }
              }
            }}
            onBlur={() => {
              if (value < (min || 0)) {
                onChange(min || 0);
              }
              setEditing(false);
            }}
          />
        </div>
      );
    }
    return (
      <div onClick={() => setEditing(true)}>
        <p style={suffixStyle}>{suffix}</p>
      </div>
    );
  };

  return (
    <RangeSlider
      label={label}
      min={min}
      max={max}
      value={value}
      onChange={onChange}
      suffix={<Text />}
      output
    />
  );
};

export default NumberOption;
