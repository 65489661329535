import { TextStyle, Heading, Stack } from '@shopify/polaris';
import { ThemeEditMajor } from '@shopify/polaris-icons';
import React from 'react';
import RightSidebar from './RightSidebar';

const TipsSidebar = () => (
  <RightSidebar>
    <Stack vertical spacing="tight">
      <div style={{ width: 45 }}>
        <ThemeEditMajor fill="rgba(92, 95, 98, 1)" />
      </div>
      <Heading>Customize your templates</Heading>
      <TextStyle variation="subdued">
        Select a section or block in the sidebar to start.
      </TextStyle>
    </Stack>
  </RightSidebar>
);

export default TipsSidebar;
