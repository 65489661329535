import { Form, Button, FormLayout, TextField, Card } from '@shopify/polaris';
import React, { useEffect, useState, useCallback } from 'react';
import { login } from '../framework/util/auth';
import theme from '../framework/util/theme';
import Refersion from '../components/Refersion';

const Auth = () => {
  const query = new URLSearchParams(window.location.search);

  const incomingShop = query.get('shop') || '';
  const [loading, setLoading] = useState(false);
  const [shop, setShop] = useState(incomingShop.replace('.myshopify.com', ''));
  const rfid = query.get('rfsn') || '';
  const { logo, colors } = theme();

  const handleSubmit = useCallback(async () => {
    if (!shop) {
      return;
    }
    setLoading(true);
    login('spently', shop);
  }, [shop]);

  useEffect(() => {
    if (incomingShop) {
      handleSubmit();
    }
  }, [handleSubmit, incomingShop, shop]);

  return (
    <div
      style={{
        height: '100vh',
        margin: 0,
        padding: '20vh 10vw',
        background: colors.primary,
        backgroundImage: 'url(/bg.svg)',
        backgroundSize: 'cover',
      }}
    >
      <div
        style={{
          width: '80vw',
          maxWidth: '480px',
        }}
      >
        <Card>
          <div
            style={{
              minHeight: '50vh',
              padding: '2rem',
            }}
          >
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <FormLayout>
                <img src={logo.authLogo} width="240" alt="UpOrder" />
                <TextField
                  autoFocus
                  value={shop}
                  onChange={setShop}
                  label="Shop Name"
                  type="text"
                  helpText="This is the first part of your myshopify.com url."
                />

                <Button submit primary disabled={!shop} loading={loading}>
                  Log in
                </Button>
              </FormLayout>
            </Form>
          </div>
        </Card>
        {rfid && <Refersion redirect />}
      </div>
    </div>
  );
};

export default Auth;
