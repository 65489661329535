import {
  Stack,
  Button,
  Subheading,
  TextStyle,
  Collapsible,
} from '@shopify/polaris';
import React from 'react';

const StyleCollapsible = ({ title, children, opened, setOpened }) => (
  <>
    <Button
      fullWidth
      textAlign="left"
      disclosure={opened === title ? 'up' : 'down'}
      removeUnderline
      onClick={() => setOpened(opened === title ? null : title)}
    >
      <Subheading>
        <TextStyle variation="subdued">{title}</TextStyle>
      </Subheading>
    </Button>
    <Collapsible
      open={opened === title}
      id={`${title}-basic-collapsible`}
      transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
      expandOnPrint
    >
      <div
        style={{
          paddingLeft: 16,
          paddingRight: 16,
        }}
      >
        <Stack vertical spacing="loose">
          {children}
        </Stack>
      </div>
    </Collapsible>
  </>
);

export default StyleCollapsible;
