import { useEffect } from 'react';
import Config from '../Config';
import { getShop } from '../framework/util/auth';

/**
 * Component to handle Refersion tracking integration.
 * It dynamically adds a script tag to the HTML document for handling Refersion tracking.
 * The script initializes the Refersion tracking system and sends a checkout event.
 */
const Refersion = ({ redirect = false }) => {
  // Retrieves the shop identifier.
  const shop = getShop('spently');

  // useEffect hook to handle the side effect of adding a script tag for Refersion tracking.
  useEffect(() => {
    // Checks if the Refersion configuration and public key are available.
    if (!Config.refersion || !Config.refersion.publicKey) {
      return null;
    }

    // Creates a script element.
    const inlineScript = document.createElement('script');
    // Sets the innerHTML of the script element with Refersion tracking code.
    inlineScript.innerHTML = `
    function sendRefersionCheckoutEvent(cartID) {
      const rfsn = {
          cart: cartID,
          id: localStorage.getItem("rfsn_v4_id"),
          url: window.location.href,
          aid: localStorage.getItem("rfsn_v4_aid"),
          cs: localStorage.getItem("rfsn_v4_cs")
      };
      r.sendCheckoutEvent(rfsn.cart, rfsn.id, rfsn.url, rfsn.aid, rfsn.cs);
    }

    ! function(e, n, t, i, o, c, s, a) {
        e.TrackingSystemObject = "r", 
          (s = n.createElement(t)).async = 1, 
          s.src = "https://cdn.refersion.com/refersion.js", 
          s.onload = function() {
    
            // Replace with your Refersion Public API Key
            r.pubKey = "${Config.refersion.publicKey}";
    
            r.settings.fp_off = true;
    
            r.initializeXDLS().then(() => {
                r.launchDefault().then(() => {
                  if("${shop}" !== "undefined") {
                    sendRefersionCheckoutEvent("${shop}-cart");
                  }
                  if(${redirect}) {
                    document.location = 'https://apps.shopify.com/uporder';
                  }

                })
            })
        }, (a = n.getElementsByTagName(t)[0]).parentNode.insertBefore(s, a)
    }(window, document, "script");
    `;
    // Appends the script element to the document body.
    document.body.appendChild(inlineScript);

    // Cleanup function to remove the script element when the component unmounts.
    return () => {
      document.body.removeChild(inlineScript);
    };
  }, [redirect, shop]);

  // The component does not render anything.
  return null;
};

export default Refersion;
