/* eslint-disable max-len */
export const formatGoogleFonts = (arrayOfFontNames) => {
  const googleFontsText = arrayOfFontNames.join('|').replace(' ', '+');
  return `<link href="https://fonts.googleapis.com/css?family=${googleFontsText}" type="text/css" data-premailer="ignore"  rel="stylesheet">`;
};

const determinePreviewMessage = (templateType) => {
  switch (templateType) {
    case 'OI':
      return 'Hi {{ billing_address.first_name }}, Order {{order_name}} has been confirmed. Thank you for your purchase. We will notify you when it has been shipped.';
    case 'OC':
      return 'Hi {{ billing_address.first_name }}, Order {{order_name}} has been cancelled.';
    case 'OE':
      return 'Hi {{ billing_address.first_name }}, Order {{order_name}} has been updated. Please review this change.';
    case 'IN':
      return 'Hi {{ billing_address.first_name }}, Order {{order_name}} has been updated. Please review the updated balance.';
    case 'RN':
      return 'Hi {{ billing_address.first_name }}, Order {{order_name}} has been refunded. ';
    case 'DI':
      return 'Hi {{ billing_address.first_name }}, complete your purchase.  Items will be reserved for you until {{ reserve_inventory_until | date: "%A, %B %-d, %Y at %-l:%M%P %Z" }}.';
    case 'EP':
      return 'Hi {{ billing_address.first_name }}, thanks for stopping by. Click here to complete your purchase.';
    case 'AC':
      return 'Hi {{ billing_address.first_name }}, we noticed you left some items in your cart.';
    case 'PR':
      return 'Hi {{ billing_address.first_name }}, thanks for stopping by. Here’s your receipt for Order {{order_name}}.';
    case 'OH':
      return 'Hi {{ billing_address.first_name }}, your order has been picked up. Thank you for shopping at {{ shop.name }}';
    case 'OR':
      return 'Hi {{ billing_address.first_name }}, your order is ready for pickup. Bring your confirmation email when you come to collect your order.';
    case 'PE':
      return 'Thanks for stopping by — here is your exchange receipt.';
    case 'GC':
      return 'Your gift card is now available! Treat yourself, or send it to someone else as a gift.';
    case 'PY':
      return 'Your payment could not be processed. You can return to your cart to complete this purchase.';
    case 'SC':
      return 'Hi {{ billing_address.first_name }}, {% if fulfillment.item_count == item_count %}{% if fulfillment.item_count == 1 %}{% capture shipment_name %}The item{% endcapture %}{% else %}{% capture shipment_name %}All items{% endcapture %}{% endif %}{% endif %}{{ shipment_name }} from Order {{order_name}}  {% if fulfillment.item_count == 1 %}has {% else %}have{% endif %} been shipped.';
    case 'SU':
      return 'Hi {{ billing_address.first_name }}, your shipment has been updated for Order {{order_name}}.';
    case 'SO':
      return 'Hi {{ billing_address.first_name }}, your shipment is out for delivery for Order {{order_name}}.';
    case 'SD':
      return 'Hi {{ billing_address.first_name }}, your shipment has been been delivered for Order {{order_name}}.';
    case 'RL':
      return 'Hi {{ billing_address.first_name }}, here is your return label for Order {{order_name}}.';
    case 'OD':
      return 'Your order is on its way and will arrive soon';
    case 'DE':
      return 'Your order has been delivered';
    case 'MD':
      return 'Hi {{ billing_address.first_name }}, we weren’t able to deliver your order.';
    case 'AA':
      return 'An account has been created for you. Click below to activate your account, and choose a password.';
    case 'CW':
      return 'Thanks for signing up! Your account is now active.';
    case 'CP':
      return 'Forgot your password? No problem. Here’s a link to reset your password.';
    case 'CC':
      return 'Hi {{ billing_address.first_name }}, you have a private message from {{ shop.name }}.';
    default: {
      // AF fall under here
      return 'Hi there, if you have any questions feel free to reply to this email.';
    }
  }
};
/**
 * Wraps the provided template body in the email shell
 * and applies the template's theme settings.
 * @param {String} body - Template body
 * @param {Object} props - Theme settings
 */
export const emailShell = (body, settings, templateType) => {
  const { width, backgroundColor, googleFonts, emailColor = [] } = settings;
  const googleFontsLink =
    googleFonts.length !== 0 ? formatGoogleFonts(googleFonts) : '';
  const templateTypeLiquid = `{% capture recType %}${templateType}{% endcapture %}`;

  return `${templateTypeLiquid}<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  <html xmlns:v="urn:schemas-microsoft-com:vml">
    <head>
      <meta http-equiv="Content-Type" content="text/html;charset=utf-8" />
      <meta name="viewport" content="width=device-width">
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <meta name="x-apple-disable-message-reformatting">
      <title>Email</title>
      ${googleFontsLink}
      <style type="text/css" data-premailer="ignore"> * {-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; text-rendering: optimizeLegibility; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; } 
      </style>
      <style>
        @media only screen and (max-width:480px){.tr1, .tr2{max-width:100%!important;width:100%!important;padding:0!important;text-align:center!important;}}
        .ql-font-sansSerif{font-family: 'Sans Serif', sans-serif !important;}
        .ql-font-shopifyDefault{font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Fira Sans', Droid Sans, "Helvetica Neue", sans-serif !important;}
        .ql-font-arial{font-family: 'Arial', "Helvetica Neue", Helvetica, sans-serif !important;}
        .ql-font-georgia{font-family: 'georgia', serif !important;}
        .ql-font-comicSans{font-family: "Comic Sans MS", "Comic Sans", cursive !important;}
        .ql-font-courierNew{font-family: "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace !important;}
        .ql-font-lucida{font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana !important;}
        .ql-font-tahoma{font-family: Tahoma, Verdana, Segoe, sans-serif !important;}
        .ql-font-helvetica{font-family: "Helvetica Neue", Helvetica, Arial !important;}
        .ql-font-timesNewRoman{font-family: TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia, serif !important;}
        .ql-font-calibri{font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif !important;}
        [style*='Roboto'], .ql-font-roboto{font-family: 'Roboto',"Helvetica Neue", Helvetica, Arial, sans-serif !important;}
        [style*='Open Sans'], .ql-font-openSans{font-family: 'Open Sans', Arial, "Helvetica Neue", Helvetica, sans-serif !important;}
        [style*='Oswald'], .ql-font-oswald {font-family: 'Oswald', Arial, sans-serif !important;}
        [style*='Ubuntu'], .ql-font-ubuntu {font-family: 'Ubuntu', sans-serif !important;}
        [style*='Lato'], .ql-font-lato {font-family: 'Lato', Tahoma, Verdana, Segoe, sans-serif !important;}
        [style*='Bitter'], .ql-font-bitter {font-family: 'Bitter', TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia, serif !important;}
        [style*='Merriweather'], .ql-font-merriweather {font-family: 'Merriweather', 'georgia', serif !important;}
        [style*='Lora'], .ql-font-lora {font-family: 'Lora', "Palatino Linotype", "Book Antiqua", Palatino, serif !important;}
        [style*='Great Vibes'], .ql-font-greatVibes {font-family: 'Great Vibes', cursive !important;}
        [style*='Pacifico'],.ql-font-pacifico {font-family: 'Pacifico', cursive !important;}
        [style*='Montserrat'], .ql-font-montserrat {font-family: 'Montserrat', sans-serif !important;}
      </style>
      <style type="text/css" data-premailer="ignore">
        @media only screen and (max-width: ${width}px){
           h1,h2,h3,h4 {
             line-height:130%!important
           }
         }
 
         a,blockquote,body,li,p,table,td{
           -webkit-text-size-adjust:none!important
         }
 
         center[id=email-wrapper]{
           padding-top:0!important;
           padding-bottom:0!important
         }
 
         table[id=template-root],table[id=emailContainer]{
           max-width:${width}px!important;
         }
         body{
           width:100%!important;
           min-width:100%!important
           height:100% !important; 
           margin:0 !important; 
           padding:0 !important;
         }
         .emailBody { 
           max-width:${width}px !important; 
         }
         
         @media only screen and (max-width:480px){
           #tb1, #tb2{
              min-width:100% !important;
              width:100% !important;
              text-align:center !important;
              display:block !important;
           }
           .tbInner {
             text-align:center !important;
             max-width:480px !important;
             display:block !important;
             width:100% !important;
           }
           .responsiveImage {
              width:100% !important;
           }
           .mobileWidth {
            min-width:100% !important;
            width:100% !important;
            padding-left:0 !important;
            padding-right:0 !important;
           }
            #template-root {
              min-width:100vw;
            }
            body .first-table {
              width:100% !important;
              width:100vw !important;
              min-width:600px !important;
            }
            .gmailfix {
              display:none;
              display:none!important;
             }
         }
      </style>
    </head>
    <body class="body" leftmargin="0" topmargin="0" marginwidth="0" marginheight="0" bgcolor="#FFFFFF" style="-ms-text-size-adjust: none; -webkit-text-size-adjust: none; Margin: 0; padding: 0">
        <center>
        <div>
<div style="display: none; maxHeight: 0px; overflow: hidden">
  ${determinePreviewMessage(templateType)}
</div>
<div style="display: none; maxHeight: 0px; overflow: hidden">
  &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;
  &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;
  &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;
  &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;
  &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;
</div>
</div>
        <div class="gmailfix" style="white-space:nowrap; font:15px courier; line-height:0;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </div>
        <table class="first-table" border="0" cellpadding="0" align="center" cellspacing="0" height="100%" width="100%" bgcolor="${backgroundColor}" style="border-collapse: collapse; mso-table-lspace: 0;mso-table-rspace: 0; min-width: 100%">
        <!--[if mso]>
        <tr>
        <td style="padding:0px;margin:0px;">&nbsp;</td>
        <td style="padding:0px;margin:0px;" width="600">
        <![endif]-->
        <!--[if gte mso 9]>
        <center>
        <table width="600">
        <![endif]-->
          <tr>
            <td class="emailBodyCell" align="center" cellspacing="0" border="0" cellpadding="0" valign="top" style="border-collapse: collapse; mso-table-lspace: 0; mso-table-rspace: 0; margin:0;">
            <table style="border-collapse:collapse;border-spacing:0;float:none;text-align:center;vertical-align:top;max-width:600px;width:600px;margin:0 auto" align="center" bgcolor="${emailColor}">
            <tbody>
              ${body}
              </tbody>
              </table>
            </td>
          </tr>
        <!--[if gte mso 9]>
        </center>
        </table>
        <![endif]-->
        <!--[if mso]>
        </td>
        <td style="padding:0px;margin:0px;">&nbsp;</td>
        </tr>
        <![endif]-->
        </table>
        {{spently_watermark}}
      </center>
    </body>
  </html>
`;
};
