import {
  Button,
  Icon,
  Stack,
  TopBar,
  VisuallyHidden,
  Popover,
} from '@shopify/polaris';
import {
  DesktopMajor,
  MobileMajor,
  RedoMajor,
  UndoMajor,
  ViewportWideMajor,
} from '@shopify/polaris-icons';
import React, { useState, useContext } from 'react';
import PanFilteredSelect from '../framework/ui/PanFilteredSelect';
import { TemplateContext } from '../state/TemplateContext';
import { emailTypes, getSupportedNotificationMenus } from '../util/formatters';
import { getSpently } from '../util/spentlyRest';
import BuilderTopBarContext from './builder/BuilderTopBarContext';
import Divider from './builder/Divider';
import LoseChangesModal from './builder/modals/LoseChangesModal';
import MissingTitleModal from './builder/modals/MissingTitleModal';
import { BaseComponentContext } from '../state/BaseComponentContext';

const BuilderTopBar = ({
  device,
  setDevice,
  type,
  onAfterSave,
  copmonents,
}) => {
  const [template, , setTemplate, undo, redo, save, templateHasError] =
    useContext(TemplateContext);
  const [components] = useContext(BaseComponentContext);
  const [saving, setSaving] = useState(false);
  const [loseChangesOpen, setLoseChangesOpen] = useState(null);
  const [templateType, setTemplateType] = useState(type);
  const [isDeviceOpen, setIsDeviceOpen] = useState(false);
  const [nameModalOpen, setNameModalOpen] = useState(false);
  const query = new URLSearchParams(window.location.search);
  const canUndo = template && template.updates.length > 0;
  const canRedo = template && template.redos.length > 0;
  const reloadTemplate = async (et) => {
    const set = template.templateSetId
      ? template.templateSetId
      : query.get('set');
    const qs = `setId=${set}`;
    const queryString =
      set === 'true' ? `templateId=${et}0030` : `templateType=${et}`;
    const temp = await getSpently(`/api/template?${qs}&${queryString}`);
    if (components) {
      setTemplate(temp, components);
    }
  };

  const publishText = 'Publish';
  const userMenu = (
    <div style={{ width: '21rem' }}>
      <MissingTitleModal
        nameModalOpen={nameModalOpen}
        setNameModalOpen={setNameModalOpen}
        save={async () => {
          await save(copmonents);
          onAfterSave();
        }}
      />
      <LoseChangesModal
        loseChangesOpen={loseChangesOpen}
        setLoseChangesOpen={setLoseChangesOpen}
        discard={() => {
          setTemplateType(loseChangesOpen);
          reloadTemplate(loseChangesOpen);
        }}
        save={async () => {
          await save();
          onAfterSave();
        }}
      />
      <Stack vertical={false} alignment="center">
        <Divider height={56} />
        <Button onClick={undo} plain disabled={!canUndo}>
          <Icon source={UndoMajor} color={canUndo ? 'primary' : 'subdued'} />
        </Button>
        <Button onClick={redo} plain disabled={!canRedo}>
          <Icon source={RedoMajor} color={canRedo ? 'primary' : 'subdued'} />
        </Button>
        <Divider height={56} />
        <Button
          disabled={templateHasError}
          onClick={async () => {
            if (canUndo) {
              setSaving(true);
              if (!template.name) {
                setNameModalOpen(true);
                setSaving(false);
                return;
              }
              await save(copmonents);
              onAfterSave();
              setSaving(false);
            } else {
              setSaving(true);
              const updated = await save(copmonents);
              document.location = `/Publish?setId=${updated.templateSetId}`;
            }
          }}
          loading={saving}
        >
          {canUndo ? 'Save' : publishText}
        </Button>
      </Stack>
    </div>
  );
  const context = <BuilderTopBarContext />;

  const [popoverActive, setPopoverActive] = useState(false);
  const popupButton = (
    <Button
      fullWidth
      onClick={() => setPopoverActive(true)}
      disclosure
      textAlign="left"
    >
      {
        emailTypes[
          templateType ||
            (template &&
              template.templateId &&
              template.templateId.substring(0, 2))
        ]
      }
    </Button>
  );

  // generate the itemMenus for ActionList base on SupportedNotificationMenus
  const itemMenus = getSupportedNotificationMenus(template).map((menu) => ({
    title: menu.title,
    items: menu.options.map((option) => ({
      content: option.label,
      onAction: () => {
        if (canUndo) {
          setLoseChangesOpen(option.value);
          setPopoverActive(false);
        } else {
          setTemplateType(option.value);
          reloadTemplate(option.value);
          setPopoverActive(false);
        }
      },
    })),
  }));

  const search = (
    <div style={{ width: 610 }}>
      <div style={{ width: 480, margin: '0 auto' }}>
        <Popover
          onClose={() => setPopoverActive(false)}
          fullWidth
          active={popoverActive}
          activator={popupButton}
          autofocusTarget="first-node"
        >
          <Popover.Pane>
            <PanFilteredSelect items={itemMenus} />
          </Popover.Pane>
        </Popover>
      </div>
    </div>
  );

  let deviceIcon = device === 'desktop' ? DesktopMajor : MobileMajor;
  if (device === 'wide') {
    deviceIcon = ViewportWideMajor;
  }
  const deviceButton = (
    <span>
      <Icon source={deviceIcon} />
      <VisuallyHidden>Secondary menu</VisuallyHidden>
    </span>
  );

  const secondaryMenuMarkup = (
    <TopBar.Menu
      activatorContent={deviceButton}
      open={isDeviceOpen}
      onOpen={() => {
        setIsDeviceOpen(true);
      }}
      onClose={() => {
        setIsDeviceOpen(false);
      }}
      actions={[
        {
          items: [
            {
              content: 'Mobile',
              icon: MobileMajor,
              active: device === 'mobile',
              onAction: () => setDevice('mobile'),
            },
            {
              content: 'Desktop',
              icon: DesktopMajor,
              active: device === 'desktop',
              onAction: () => setDevice('desktop'),
            },
            {
              content: 'Fullscreen',
              icon: ViewportWideMajor,
              active: device === 'wide',
              onAction: () => setDevice('wide'),
            },
          ],
        },
      ]}
    />
  );

  return (
    <TopBar
      contextControl={context}
      searchField={search}
      userMenu={userMenu}
      secondaryMenu={secondaryMenuMarkup}
    />
  );
};

export default BuilderTopBar;
