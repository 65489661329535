import React from 'react';
import { TextField } from '@shopify/polaris';

const CodeOption = ({ value, label, onChange, placeholder }) => (
  <>
    <h5 style={{ marginBottom: '0.5rem' }}>{label}</h5>
    <TextField
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      multiline={6}
    />
  </>
);

export default CodeOption;
