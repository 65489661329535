import { Stack } from '@shopify/polaris';
import React, { useContext } from 'react';
import { TemplateContext } from '../../../state/TemplateContext';
import BuilderOption from '../BuilderOption';

const DiscountSettings = () => {
  const [{ options }, updateTemplate] = useContext(TemplateContext);
  const disSettings = options.GlobalDiscount || {};

  const change = (propName, val) => {
    const prop = `gblDis${propName}`;
    updateTemplate(prop, disSettings[prop], val, 'GlobalDiscount');
  };
  return (
    <Stack spacing="loose" vertical>
      <BuilderOption
        option={{
          type: 'multicolor',
          name: 'BodyColor',
          label: 'Background Color',
          defaultValue: disSettings.gblDisBodyColor,
        }}
        onChange={change}
      />
      <BuilderOption
        option={{
          type: 'multicolor',
          name: 'countHeaderColor',
          label: 'Discount Font Color',
          defaultValue: disSettings.gblDiscountHeaderColor,
        }}
        onChange={change}
      />
      <BuilderOption
        option={{
          type: 'multicolor',
          name: 'countColor',
          label: 'Font Color',
          defaultValue: disSettings.gblDiscountColor,
        }}
        onChange={change}
      />
      <BuilderOption
        option={{
          type: 'number',
          name: 'HeaderSize',
          label: 'Header Font Size',
          defaultValue: disSettings.gblDisHeaderSize,
          min: 1,
          unit: 'px',
        }}
        onChange={change}
      />
      <BuilderOption
        option={{
          type: 'number',
          name: 'Border',
          label: 'Coupon Border',
          defaultValue: disSettings.gblDisBorder,
          min: 1,
          unit: 'px',
        }}
        onChange={change}
      />
      <BuilderOption
        option={{
          type: 'number',
          name: 'VerticalSpacing',
          label: 'Vertical Spacing',
          defaultValue: disSettings.gblDisVerticalSpacing || 30,
          min: 1,
          unit: 'px',
        }}
        onChange={change}
      />
      <BuilderOption
        option={{
          type: 'number',
          name: 'HorizontalPadding',
          label: 'Discount Horizontal Spacing',
          defaultValue: disSettings.gblDisHorizontalPadding,
          min: 1,
          unit: 'px',
        }}
        onChange={change}
      />
      <BuilderOption
        option={{
          type: 'number',
          name: 'VerticalPadding',
          label: 'Discount Vertical Spacing',
          defaultValue: disSettings.gblDisVerticalPadding,
          min: 1,
          unit: 'px',
        }}
        onChange={change}
      />
      <BuilderOption
        option={{
          type: 'number',
          name: 'BodyMargin',
          label: 'Body Margin',
          defaultValue: disSettings.gblDisBodyMargin,
          min: 1,
          unit: 'px',
        }}
        onChange={change}
      />
    </Stack>
  );
};

export default DiscountSettings;
