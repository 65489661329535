import { Modal, Stack, TextContainer, TextField } from '@shopify/polaris';
import React, { useContext, useState } from 'react';
import { TemplateContext } from '../../../state/TemplateContext';

const MissingTitleModal = ({ nameModalOpen, setNameModalOpen, save }) => {
  const [template, updateTemplate] = useContext(TemplateContext);
  const [name, setName] = useState(template && template.name);
  const [saving, setSaving] = useState(false);

  return (
    <Modal
      open={nameModalOpen}
      onClose={() => setNameModalOpen(false)}
      title="Missing Title"
      primaryAction={{
        content: 'Save',
        loading: saving,
        disabled: template && !template.name,
        onAction: async () => {
          setSaving(true);
          await save();
          setSaving(false);
          setNameModalOpen(false);
        },
      }}
      secondaryActions={{
        content: 'Cancel',
        onAction: () => {
          setName('');
          updateTemplate('TITLE', template.name, '');
          setNameModalOpen(false);
        },
      }}
    >
      <Modal.Section>
        <Stack vertical>
          <Stack.Item>
            <TextContainer>
              <h4>Before we save it, let&apos;s give this template a name</h4>
            </TextContainer>
          </Stack.Item>
          <Stack.Item fill>
            <TextField
              placeholder="Template name"
              value={name}
              autoFocus
              onChange={(val) => {
                setName(val);
                updateTemplate('TITLE', template.name, val);
              }}
              autoComplete="off"
            />
          </Stack.Item>
        </Stack>
      </Modal.Section>
    </Modal>
  );
};

export default MissingTitleModal;
