import { Stack, Subheading } from '@shopify/polaris';
import React from 'react';
import BuilderOption from '../../BuilderOption';

const HeaderFont = ({ settings, setFontSettings }) => (
  <Stack spacing="loose" vertical>
    <Subheading>HEADER FONT</Subheading>
    <BuilderOption
      option={{
        key: 'gblHeaderFamily',
        type: 'font',
        label: 'Font Family',
        name: 'gblHeaderFamily',
        values: settings,
        defaultValue: settings.gblHeaderFamily,
      }}
      isGlobal
      onChange={setFontSettings}
    />
    <BuilderOption
      key="gblHeaderColor"
      option={{
        type: 'multicolor',
        name: 'gblHeaderColor',
        label: 'Font color',
        defaultValue: settings.gblHeaderColor,
      }}
      isGlobal
      onChange={setFontSettings}
    />
    <BuilderOption
      option={{
        type: 'number',
        label: 'Font Size',
        name: 'gblHeaderSize',
        defaultValue: settings.gblHeaderSize,
        max: 40,
        unit: 'px',
      }}
      isGlobal
      onChange={setFontSettings}
    />
    <BuilderOption
      option={{
        type: 'number',
        label: 'Letter Spacing',
        name: 'gblHeaderSpacing',
        defaultValue: settings.gblHeaderSpacing,
        max: 5,
        unit: 'px',
      }}
      isGlobal
      onChange={setFontSettings}
    />
    <BuilderOption
      option={{
        type: 'checkbox',
        label: 'Bold font',
        name: 'gblHeaderBold',
        defaultValue: settings.gblHeaderBold,
      }}
      isGlobal
      onChange={setFontSettings}
    />
    <BuilderOption
      option={{
        type: 'checkbox',
        label: 'Italicize font',
        name: 'gblHeaderItalic',
        defaultValue: settings.gblHeaderItalic,
      }}
      isGlobal
      onChange={setFontSettings}
    />
    <BuilderOption
      option={{
        type: 'checkbox',
        label: 'Uppercase font',
        name: 'gblHeaderUppercase',
        defaultValue: settings.gblHeaderUppercase,
      }}
      isGlobal
      onChange={setFontSettings}
    />
    <BuilderOption
      option={{
        type: 'position',
        label: 'Font Position',
        name: 'gblHeaderPos',
        defaultValue: settings.gblHeaderPos,
      }}
      isGlobal
      onChange={setFontSettings}
    />
  </Stack>
);

export default HeaderFont;
