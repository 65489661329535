import React from 'react';

const Divider = ({ height }) => (
  <div
    style={{
      width: '0.1rem',
      backgroundColor: '#c4cdd5',
      height: '100%',
      minHeight: height,
    }}
  />
);

export default Divider;
