import { Heading, Stack } from '@shopify/polaris';
import React, { useCallback, useContext, useState } from 'react';
import { TemplateContext } from '../../../state/TemplateContext';
import BuilderOption from '../BuilderOption';
import RightSidebar from './RightSidebar';
import StyleCollapsible from './StyleCollapsible';

const FooterSidebar = ({ setDisplayOptions }) => {
  const [{ options }, updateTemplate] = useContext(TemplateContext);
  const [opened, setOpened] = useState(null);
  const values = options.TemplateFooter;
  const change = useCallback(
    (propName, val) => {
      updateTemplate(propName, values[propName], val, 'TemplateFooter');
    },
    [values, updateTemplate]
  );
  const Option = ({ option }) => (
    <BuilderOption
      option={option}
      values={values}
      onChange={change}
      setDisplayOptions={setDisplayOptions}
      key={option.name}
    />
  );

  const renderTextInputOption = useCallback(
    (option) => (
      <BuilderOption
        option={{ type: 'text', ...option }}
        values={values}
        onChange={change}
        key={option.name}
      />
    ),
    [change, values] // need to listen to values to re-render when they change
  );

  const renderNumberOption = useCallback(
    (option) => <Option option={{ ...option, type: 'number' }} />,
    []
  );

  const ColorOption = useCallback(
    ({ option }) => <Option option={{ ...option, type: 'multicolor' }} />,
    []
  );

  const CheckOption = ({ option }) => (
    <Option option={{ defaultValue: true, ...option, type: 'checkbox' }} />
  );

  return (
    <RightSidebar>
      <div id="themeSettings">
        <Stack vertical>
          <Heading>Template Footer</Heading>
          <StyleCollapsible
            title="Styling"
            opened={opened}
            setOpened={setOpened}
          >
            <CheckOption option={{ name: 'enabled', label: 'Use a footer' }} />
            <ColorOption
              option={{ name: 'bgColor', label: 'Background Color' }}
            />
            <ColorOption
              option={{
                name: 'fontColor',
                label: 'Font Color',
                defaultValue: '#000000',
              }}
            />
            {renderNumberOption({
              name: 'textVerticalPadding',
              label: 'Text vertical space',
            })}
            {renderNumberOption({
              name: 'height',
              label: 'Height',
              max: 600,
            })}
          </StyleCollapsible>
          <StyleCollapsible
            title="Social Icons"
            opened={opened}
            setOpened={setOpened}
          >
            <CheckOption
              option={{ name: 'socialCheck', label: 'Include social icons' }}
            />
            <Option option={{ type: 'social', label: 'links' }} />
            <Option
              option={{
                type: 'button',
                name: 'Edit social links',
                action: 'editSocialLinks',
              }}
            />
            <Option
              option={{
                type: 'dropdown',
                name: 'style',
                label: 'Style',
                defaultValue: 'colors',
                options: [
                  { label: 'Colors', value: 'colors' },
                  { label: 'Black', value: 'black' },
                  { label: 'White', value: 'white' },
                ],
              }}
            />
            {renderNumberOption({
              name: 'spacing',
              label: 'Distance Between Icons',
              defaultValue: 8,
            })}
            <ColorOption
              option={{ name: 'backgroundColor', label: 'Background Color' }}
            />
            {renderNumberOption({
              name: 'verticalPadding',
              label: 'Vertical Space',
            })}
            <ColorOption
              option={{ name: 'color', label: 'Icon Background Color' }}
            />
            {renderNumberOption({
              name: 'padding',
              label: 'Icon Background Size',
            })}
            {renderNumberOption({
              name: 'borderRadius',
              label: 'Icon Corners',
            })}
            <Option
              option={{ type: 'position', name: 'position', label: 'Position' }}
            />
          </StyleCollapsible>
          <StyleCollapsible title="Logo" opened={opened} setOpened={setOpened}>
            <CheckOption
              option={{ name: 'logoCheck', label: 'Include a logo' }}
            />
            <Option
              option={{
                type: 'image',
                name: 'logoImg',
                defaultValue: '/Content/images/placeholder.png',
              }}
            />
            <Option option={{ name: 'logoUrl', label: 'Logo URL' }} />
            {renderNumberOption({
              name: 'logoWidth',
              label: 'Logo width',
              max: 600,
            })}
            {renderNumberOption({
              name: 'logoVerticalPadding',
              label: 'Logo space above and below',
            })}
            <Option
              option={{
                type: 'position',
                name: 'logoPosition',
                label: 'Logo position',
              }}
            />
          </StyleCollapsible>
          <StyleCollapsible
            title="Content"
            opened={opened}
            setOpened={setOpened}
          >
            {renderTextInputOption({
              name: 'storeEmail',
              label: 'Store email',
              defaultValue: '{{uporder.store.email}}',
            })}
            {renderTextInputOption({
              name: 'websiteLink',
              label: 'Store website link',
              defaultValue: '{{uporder.store.name}}',
            })}
            {renderTextInputOption({
              name: 'storeAddress',
              label: 'Store address',
              defaultValue: '{{uporder.store.address}}',
            })}
            {renderTextInputOption({
              name: 'phoneNumber',
              label: 'Phone number',
              defaultValue: '{{uporder.store.phone}}',
            })}
            <CheckOption
              option={{
                type: 'checkbox',
                name: 'unSubCheckbox',
                label: 'Include unsubscribe link',
              }}
            />
            {values.unSubCheckbox && (
              <Option
                option={{
                  type: 'message',
                  message:
                    // eslint-disable-next-line max-len
                    'This link will only be added to your abandoned checkout email - its the only marketing email sent by Shopify',
                }}
              />
            )}
            {!values.unSubCheckbox && (
              <Option
                option={{
                  type: 'message',
                  message:
                    // eslint-disable-next-line max-len
                    'By law many countries require you to include the ability to unsubscribe in marketing emails. Check your countries laws before you make changes to this option',
                }}
              />
            )}
          </StyleCollapsible>
          {renderTextInputOption({
            name: 'contactMessage',
            label: 'Contact Message',
            defaultValue: 'If you have any questions contact us at',
          })}
        </Stack>
      </div>
    </RightSidebar>
  );
};

export default FooterSidebar;
