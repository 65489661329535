import React from 'react';
import { Button, Label, ButtonGroup } from '@shopify/polaris';

const PositionOption = ({ value, placeholder, label, updateValue }) => {
  const selected = value || placeholder;
  return (
    <>
      <Label>{label}</Label>
      <ButtonGroup segmented>
        <Button
          primary={selected === 'left'}
          onClick={() => updateValue('left')}
        >
          Left
        </Button>
        <Button
          primary={selected === 'center'}
          onClick={() => updateValue('center')}
        >
          Center
        </Button>
        <Button
          primary={selected === 'right'}
          onClick={() => updateValue('right')}
        >
          Right
        </Button>
      </ButtonGroup>
    </>
  );
};

export default PositionOption;
