import React from 'react';
import {
  Layout,
  Card,
  Stack,
  TextStyle,
  DisplayText,
  SkeletonBodyText,
  SkeletonDisplayText,
} from '@shopify/polaris';
import { translate } from '../../util/localizer';

const HalfCard = ({
  title,
  leftTitleKey,
  leftValue,
  rightTitleKey,
  rightValue,
}) => {
  const leftTitle = translate(leftTitleKey);
  const rightTitle = translate(rightTitleKey);
  const displayTitle = () => {
    if (leftValue) {
      return title;
    }

    return (
      <div style={{ width: '15vw', minHeight: 24 }}>
        <SkeletonBodyText lines={1} />
      </div>
    );
  };

  const SubTitle = ({ children }) => (
    <DisplayText size="small">
      <TextStyle>{children}</TextStyle>
    </DisplayText>
  );
  const Value = ({ children }) => (
    <>
      {children !== undefined && (
        <DisplayText size="small">
          <TextStyle variation="positive">{children}</TextStyle>
        </DisplayText>
      )}
      {children === undefined && <SkeletonDisplayText size="small" />}
    </>
  );
  return (
    <Layout.Section oneHalf>
      <Card title={displayTitle()} sectioned>
        <Stack distribution="fillEvenly">
          <Stack vertical>
            <SubTitle>{leftTitle}</SubTitle>
            <Value>{leftValue}</Value>
          </Stack>
          <Stack vertical>
            <SubTitle>{rightTitle}</SubTitle>
            <Value>{rightValue}</Value>
          </Stack>
        </Stack>
      </Card>
    </Layout.Section>
  );
};

export default HalfCard;
