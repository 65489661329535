import React, { useState } from 'react';
import { TopBar } from '@shopify/polaris';
import {
  StarFilledMinor,
  LogOutMinor,
  QuestionMarkMinor,
  AddNoteMajor,
  LockMajor,
} from '@shopify/polaris-icons';
import { getUser, logout } from '../framework/util/auth';
import ErrorModal from '../widgets/builder/modals/ErrorModal';

const UserMenu = () => {
  const [userMenuOpen, setUserMenuOpen] = useState(false);

  try {
    const user = getUser('spently');

    const uName = user ? `${user.first_name} ${user.last_name}` : '';
    return (
      <TopBar.UserMenu
        open={userMenuOpen}
        colorScheme="inverse"
        name={uName}
        onToggle={() => setUserMenuOpen(!userMenuOpen)}
        actions={[
          {
            items: [
              {
                content: 'Logout',
                onAction: logout,
                icon: LogOutMinor,
              },
            ],
          },
          {
            items: [
              {
                content: 'Submit feedback',
                icon: AddNoteMajor,
                onAction: () => {
                  window.open('https://forms.gle/KSvS6GDQ8vd3FAaC6', '_blank');
                },
              },
              {
                content: 'UpOrder Help Desk',
                icon: QuestionMarkMinor,
                url: 'https://help.spently.com',
              },
              {
                content: 'Leave a review',
                icon: StarFilledMinor,
                url: 'https://apps.shopify.com/spently#reviews',
              },
              {
                content: 'Privacy policy',
                icon: LockMajor,
                onAction: () => {
                  window.open(
                    'https://brands.pantastic.com/privacy-policy',
                    '_blank'
                  );
                },
              },
            ],
          },
        ]}
      />
    );
  } catch (e) {
    return <ErrorModal e />;
  }
};

export default UserMenu;
