/* eslint-disable no-underscore-dangle */
/* eslint-disable no-case-declarations */
import React, { useState } from 'react';
import { buildHtmlForTemplate } from '../util/builder';
import { putSpently } from '../util/spentlyRest';

const TemplateContext = React.createContext({});

const TemplateProvider = ({ children }) => {
  const [template, setTemplate] = useState(null);
  const [templateHasError, setTemplateHasError] = useState(false);

  const updateTemplate = (propName, oldVal, newVal, component, isUndo) => {
    if (oldVal === newVal) {
      return;
    }
    const newTemplate = { ...template };

    switch (propName) {
      case 'TITLE':
        newTemplate.name = isUndo ? oldVal : newVal;
        break;
      case 'ADD':
        if (isUndo) {
          newTemplate.components.pop();
        } else {
          newTemplate.components.push(JSON.parse(newVal));
        }
        break;
      case 'REMOVE':
        const oldValJson = JSON.parse(oldVal);
        if (isUndo) {
          newTemplate.components.splice(oldValJson, 0, JSON.parse(newVal));
        } else {
          // if user is removing a deprecated component, we should allow user to save the template
          if (oldValJson?.id === 'DeprecatedComponent') {
            setTemplateHasError(false);
          }

          newTemplate.components.splice(newVal, 1);
        }
        break;
      case 'REMOVECHILD':
        if (isUndo) {
          newTemplate.components[component.key].columns[component.child] =
            JSON.parse(oldVal);
        } else {
          delete newTemplate.components[component.key].columns[component.child];
        }
        break;
      case 'REARANGE':
        const draggingIndex = oldVal.split('-')[1];
        let droppedIndex = newVal.split('-')[1];

        if (newVal === 'add-section') {
          droppedIndex = newTemplate.components.length;
        }

        if (droppedIndex > draggingIndex) {
          // Make up for the removed item
          droppedIndex = parseInt(droppedIndex, 10) - 1;
          // when is moving item from up to down, the position should  be the dropon value - 1
          // so the new Val to replace should be the component on top of the dropon component
          const droppedComponent = newTemplate.components[droppedIndex];
          // eslint-disable-next-line no-param-reassign
          newVal = `${droppedComponent.id}-${droppedIndex}`;
        }

        const compToMove = newTemplate.components[draggingIndex];
        const newComponents = [...newTemplate.components];
        newComponents.splice(draggingIndex, 1);
        newComponents.splice(droppedIndex, 0, compToMove);
        newTemplate.components = newComponents;
        break;
      default:
        let componentToUpdate = newTemplate.components[component];

        if (!componentToUpdate) {
          componentToUpdate = newTemplate.options[component];
        }
        if (propName.indexOf('.') === -1) {
          componentToUpdate[propName] = newVal;
        } else {
          // TODO: yup this is gross, but we'll drop the hideous data model soon.  Then we can all laugh at this.
          const parts = propName.split('.');
          if (!componentToUpdate[parts[0]]) {
            componentToUpdate[parts[0]] = {};
          }

          if (parts.length === 2) {
            if (newVal) {
              componentToUpdate[parts[0]][parts[1]] = newVal;
            } else {
              delete componentToUpdate[parts[0]][parts[1]];
            }
          } else {
            if (!componentToUpdate[parts[0]][parts[1]]) {
              componentToUpdate[parts[0]][parts[1]] = {};
            }
            componentToUpdate[parts[0]][parts[1]][parts[2]] = newVal;
          }
        }
        break;
    }

    newTemplate.updates.push({ propName, oldVal, newVal, component });
    if (isUndo) {
      newTemplate.updates.pop();
      newTemplate.redos.push({ propName, oldVal, newVal, component });
    }

    setTemplate(newTemplate);
  };

  const undo = () => {
    const toUndo = template.updates.pop();
    updateTemplate(
      toUndo.propName,
      toUndo.newVal,
      toUndo.oldVal,
      toUndo.component,
      true
    );
  };

  const redo = () => {
    const toRedo = template.redos.pop();
    updateTemplate(
      toRedo.propName,
      toRedo.newVal,
      toRedo.oldVal,
      toRedo.component
    );
  };

  const convert = (temp, availableComponents = null) => {
    if (!temp) {
      return null;
    }
    const options = {};

    const { _id, settings, components, TemplateId, TemplateHTML } = temp;

    if (Array.isArray(settings)) {
      settings.forEach((s) => {
        options[s._id] = s;
      });
    } else {
      options.Settings = settings;
    }

    const convComps = components.map((c) => {
      const newComp = {};
      // check if this component is on the available components list
      if (availableComponents) {
        const foundComponents = availableComponents.filter(
          (comp) => comp.key === c._id
        );
        if (!foundComponents || foundComponents.length === 0) {
          setTemplateHasError(true);
          return { id: 'DeprecatedComponent' };
        }
      }

      if (Array.isArray(c)) {
        c.forEach((ele) => {
          if (Array.isArray(ele.value)) {
            const inner = [];
            ele.value.forEach((e) => {
              inner.push({
                name: e[0].value,
                label: e[1].value,
                value: e[2].value,
              });
            });
            newComp[ele.name] = inner;
          } else {
            newComp[ele.name.replace('_', '')] = ele.value;
          }
        });
      } else {
        return { ...c, id: c.id || c._id };
      }
      return newComp;
    });

    const query = new URLSearchParams(window.location.search);
    return {
      id: _id,
      templateSetId: temp.SetId,
      themeId: temp.ThemeId,
      templateId: TemplateId || query.get('templateId'),
      name: temp.Name,
      html: TemplateHTML,
      components: convComps,
      options,
      updates: [],
      redos: [],
    };
  };

  const convertAndSetTemplate = (temp, availableComponents = null) =>
    setTemplate(convert(temp, availableComponents));

  const save = async (components) => {
    const html = buildHtmlForTemplate(template, components);
    const updatedTemplate = await putSpently('/api/Template', {
      ...template,
      html,
    });
    const updatedAndConverted = convert(updatedTemplate);
    setTemplate({ ...updatedAndConverted, updates: [] });
    return updatedAndConverted;
  };

  return (
    <TemplateContext.Provider
      value={[
        template,
        updateTemplate,
        convertAndSetTemplate,
        undo,
        redo,
        save,
        templateHasError,
      ]}
    >
      {children}
    </TemplateContext.Provider>
  );
};

export { TemplateContext, TemplateProvider };
