/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Icon, Popover, Stack } from '@shopify/polaris';
import { CirclePlusMajor, DragHandleMinor } from '@shopify/polaris-icons';
import React, { useState, useContext } from 'react';
import { Icons } from '../../util/superGrossIconFile';
import theme from '../util/theme';
import PanFilteredSelect from './PanFilteredSelect';
import { BaseComponentContext } from '../../state/BaseComponentContext';

const PanNavItem = ({
  item,
  onDragStart,
  onDroppedOn,
  childItems,
  availableComponents,
  updateTemplate,
}) => {
  const [components] = useContext(BaseComponentContext);

  const [isHover, setIsHover] = useState(false);
  const [isOver, setIsOver] = useState(false);
  const [childOver, setChildOver] = useState(null);
  const [childAddOpen, setChildAddOpen] = useState(false);
  const {
    seperator,
    label,
    icon,
    destructive,
    onClick,
    selected,
    draggable,
    key,
    currentSelected,
    groupOption,
  } = item;
  const { colors } = theme();
  let clr = destructive ? colors.error : 'black';
  clr = selected ? colors.primary : clr;
  const border = selected && draggable ? `4px solid ${colors.primary}` : 'none';
  let bg = draggable && isHover ? 'rgb(237, 238, 239)' : 'transparent';
  bg = draggable && selected ? '#F2F7FE' : bg;

  const aboveDrop = isOver ? `4px solid ${colors.primary}` : 'none';
  const padding = isOver
    ? 'calc(0.4rem - 4px) 1.25rem 0.4rem'
    : '0.4rem 1.25rem';

  // map and assign the name value into the childItems
  const mappedChildItems = Object.fromEntries(
    Object.entries(childItems || {}).map(([ciKey, value]) => {
      const foundItem = (components || []).find((c) => c.key === value._id);

      return foundItem
        ? [ciKey, { ...value, name: foundItem.name }]
        : [ciKey, value];
    })
  );

  const newComps = (availableComponents || [])
    .filter((c) => groupOption?.supportedComponents.includes(c.key))
    .map((c) => ({
      key: c.name,
      content: (
        <Stack>
          <div style={{ width: 20, height: 20, marginRight: 16 }}>
            {Icons[c.key]}
          </div>
          <span>{c.name}</span>
        </Stack>
      ),
      onAction: () => {
        const newComponent = {
          id: c.key,
          section: c.section,
        };

        c.options.forEach((opt) => {
          if (opt.defaultValue) {
            newComponent[opt.name] = opt.defaultValue;
          }
        });
        const nextColIndex = Object.keys(childItems).findIndex(
          (k, index) => k !== `${index + 1}`
        );
        const index =
          nextColIndex !== -1
            ? nextColIndex + 1
            : Object.keys(childItems).length + 1;

        updateTemplate(
          `columns.${index}`,
          null,
          { ...newComponent, _id: newComponent.id },
          key.split('-')[1]
        );
        setChildAddOpen(false);
      },
    }));

  return (
    <>
      {seperator && <hr />}
      <div
        style={{ borderTop: aboveDrop }}
        onDragOver={() => setIsOver(true)}
        onDragLeave={() => setIsOver(false)}
        onDragStart={() => onDragStart(key)}
      />
      <div
        draggable={draggable}
        onDragStart={() => {
          onDragStart(key);
        }}
        style={{
          opacity: 0.9,
          display: 'flex',
          alignItems: 'center',
          backgroundColor: bg,
          justifyItems: 'stretch',
          padding,
          cursor: 'pointer',
          color: clr,
          lineHeight: '1.25rem',
          fontSize: '0.875rem',
          fontWeight: 600,
          borderLeft: border,
        }}
        onClick={onClick}
        onMouseOver={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onDragOver={(evt) => {
          setIsOver(true);
          evt.preventDefault();
        }}
        onDragLeave={() => setIsOver(false)}
        onDrop={(evt) => {
          onDroppedOn(key);
          setIsOver(false);
          evt.preventDefault();
        }}
      >
        <div style={{ width: 20, marginRight: 16, fill: clr }}>{icon()}</div>
        <div style={{ flex: 1 }}>{label}</div>
        {isHover && draggable && <Icon source={DragHandleMinor} color="base" />}
      </div>
      {childItems &&
        Object.keys(mappedChildItems).map((ciId) => {
          const ci = mappedChildItems[ciId];
          let cBg = childOver === ciId ? bg : 'transparent';
          if (currentSelected && ciId === currentSelected.child) {
            cBg = '#F2F7FE';
          }
          return (
            <div
              key={ciId}
              draggable={draggable}
              onDragStart={() => onDragStart(key)}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyItems: 'stretch',
                padding,
                background: cBg,
                borderLeft: childOver === ciId ? border : 'transparent',
                cursor: 'pointer',
                lineHeight: '1.25rem',
                fontSize: '0.875rem',
                fontWeight: 600,
                marginLeft: 24,
              }}
              onClick={() => onClick(ciId)}
              onMouseOver={() => {
                setIsHover(true);
                setChildOver(ciId);
              }}
              onMouseLeave={() => {
                setIsHover(false);
                setChildOver(null);
              }}
              onDragOver={(evt) => {
                setIsOver(true);
                evt.preventDefault();
              }}
              onDragLeave={() => setIsOver(false)}
              onDrop={(evt) => {
                onDroppedOn(key);
                setIsOver(false);
                evt.preventDefault();
              }}
            >
              <div style={{ width: 20, marginRight: 16 }}>
                {Icons[ci._id] && <Icon source={() => Icons[ci._id]} />}
              </div>
              <div style={{ flex: 1 }}>{ci.name}</div>
            </div>
          );
        })}
      {childItems && groupOption && (
        <Popover
          active={childAddOpen}
          activator={
            <div style={{ marginLeft: 24 }}>
              <PanNavItem
                item={{
                  key: 'add-section',
                  label: groupOption.label,
                  icon: CirclePlusMajor,
                  onClick: () => setChildAddOpen(true),
                  selected: true,
                }}
              />
            </div>
          }
          fullWidth
          autofocusTarget="first-node"
          onClose={() => setChildAddOpen(false)}
        >
          <PanFilteredSelect
            items={[{ title: 'components', items: newComps }]}
          />
        </Popover>
      )}
    </>
  );
};

export default PanNavItem;
