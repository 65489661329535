// Function to get the page title
const getPageTitle = () => {
  const pathName = window.location.pathname.substring(1)
    ? window.location.pathname.substring(1)
    : 'Home';

  switch (pathName) {
    case 'CompanySettings':
      return 'Company Settings';
    default:
      return pathName;
  }
};

const updateDocumentTitle = () => {
  document.title = `UpOrder - ${getPageTitle()}`;
};

export default updateDocumentTitle;
