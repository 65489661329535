import React, { useState } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { DateRangeProvider } from './state/DateRangeContext';
import Auth from './screens/Auth';
import Builder from './pages/NewBuilder';
import Site from './pages/Site';
import { isLoggedIn } from './framework/util/auth';
import PageListener from './util/PageListener';
import { TemplateProvider } from './state/TemplateContext';
import { BaseComponentProvider } from './state/BaseComponentContext';
import { getIntercomSettings, attachIntercomScript } from './widgets/intercom';
import { setServerUriToSession } from './framework/util/rest';
import ErrorModal from './widgets/builder/modals/ErrorModal';
import { attachGoogleAnalyticsScript } from './widgets/googleAnalytics';
import updateDocumentTitle from './util/DocumentUtil';
import ErrorBoundary from './util/ErrorBoundary';

export default function App() {
  const [errorState, setIsErrorState] = useState({
    isError: false,
    exception: null,
  });
  const path = document.location.pathname
    ? document.location.pathname.toLowerCase()
    : 'Home';

  try {
    if (!isLoggedIn('spently') && path !== '/authorize') {
      return <Auth />;
    }
  } catch (e) {
    setIsErrorState({ isError: true, e });
    return <ErrorModal />;
  }
  updateDocumentTitle();
  window.intercomSettings = getIntercomSettings();
  attachIntercomScript();
  attachGoogleAnalyticsScript();
  setServerUriToSession();

  if (errorState.isError) {
    return <ErrorModal />;
  }

  return (
    <DateRangeProvider>
      <Router>
        <Switch>
          <Route path="/Builder">
            <ErrorBoundary>
              <BaseComponentProvider>
                <TemplateProvider>
                  <Builder />
                </TemplateProvider>
              </BaseComponentProvider>
            </ErrorBoundary>
          </Route>
          <Route path="/">
            <Site />
          </Route>
        </Switch>
        <PageListener />
      </Router>
    </DateRangeProvider>
  );
}
