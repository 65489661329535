import { Page } from '@shopify/polaris';
import React, { useState } from 'react';
import PanCardList from '../framework/ui/PanCardList';
import PanConfirmModal from '../framework/ui/PanConfirmModal';
import PanMediaCard from '../framework/ui/PanMediaCard';
import { deleteSpently, postSpently } from '../util/spentlyRest';

const Notifications = () => {
  const [confirmState, setConfirmState] = useState({ active: false });

  return (
    <Page
      title="Notification Templates"
      fullWidth
      primaryAction={{
        content: 'New Template Set',
        url: '/Builder?template=OI0030&set=true',
      }}
    >
      <PanCardList
        url="/api/Notifications/Sets"
        card={(n) => (
          <PanMediaCard
            title={n.name}
            url={n.imageUrl}
            key={n.id}
            primaryAction={{
              content: 'Publish',
              url: `/Publish?setId=${n.id}`,
            }}
            popoverActions={[
              {
                content: 'Edit',
                url: `/Builder?set=${n.id}`,
              },
              {
                content: 'Delete',
                onAction: () => {
                  setConfirmState({
                    active: true,
                    action: async () => {
                      await deleteSpently(`/api/notifications/${n.id}`);
                      setConfirmState({ ...confirmState, active: false });
                      document.location = '/Notifications';
                    },
                  });
                },
              },
              {
                content: 'Duplicate',
                onAction: async () => {
                  await postSpently(`/api/notifications/${n.id}/duplicate`);
                  document.location = '/Notifications';
                },
              },
            ]}
          />
        )}
      />
      <PanConfirmModal
        cancel={() => {
          setConfirmState({ ...confirmState, active: false });
        }}
        title="Delete Notification Set?"
        description="Please note that this operation is non-reversable"
        active={confirmState.active}
        action={confirmState.action}
      />
    </Page>
  );
};

export default Notifications;
