import {
  DropZone,
  Heading,
  Modal,
  Spinner,
  Subheading,
  TextContainer,
  TextStyle,
} from '@shopify/polaris';
import React, { useContext, useState } from 'react';
import { TemplateContext } from '../../state/TemplateContext';
import { logError } from '../../util/logging';
import { uploadSpently } from '../../util/spentlyRest';
import MagicPreview from './MagicPreview';
import MulticolorOption from './options/MulticolorOption';

import './styles/MagicPreview.scss';

const MagicModal = () => {
  const [open, setOpen] = useState(
    window.location.search.indexOf('&set=true') >= 0
  );
  const [template, updateTemplate, , undo] = useContext(TemplateContext);
  const options = template && template.options;

  const handleDrop = async (_allImages, accepted) => {
    // TODO: to match the functionality of the old site, we upload absolutely everything,
    //        that's probably not something we want to do going forward.

    if (accepted.length > 0) {
      const res = await uploadSpently('/api/Media', accepted[0], (error) => {
        logError('could not upload file', error);
      });
      updateTemplate(
        'image',
        options.TemplateHeader.image,
        res.url,
        'TemplateHeader'
      );
      // setBgImage(accepted[0]);
    }
  };

  let currentImg = template && template.options.TemplateHeader.image;
  if (currentImg === '/Content/images/placeholder.png') {
    currentImg = null;
  }

  return (
    <Modal
      instant
      open={open}
      onClose={() => setOpen(false)}
      title="Select your brand colors"
      primaryAction={{
        content: 'Confirm',
        onAction: () => {
          setOpen(false);
        },
      }}
      secondaryActions={[
        {
          content: 'Skip',
          onAction: () => {
            const toUndo = template.updates.length;
            for (let index = 0; index < toUndo; index += 1) {
              undo();
            }
            setOpen(false);
          },
        },
      ]}
    >
      {!template && (
        <div
          style={{
            display: 'flex',
            height: 400,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner size="large" />
        </div>
      )}
      {template && (
        <Modal.Section>
          <TextContainer spacing="loose">
            These colors will be applied across all notifications within your
            template set. They can also be adjusted within the builder at any
            time.
          </TextContainer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '3rem',
              marginTop: 16,
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                padding: 8,
                display: 'flex',
                width: '20vw',
                gap: 10,
                flexDirection: 'column',
              }}
            >
              <Heading>Logo</Heading>
              <DropZone
                accept="image/*"
                labelHidden
                allowMultiple={false}
                type="image"
                onDrop={handleDrop}
              >
                <DropZone.FileUpload
                  actionTitle={
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {currentImg && (
                        <img
                          style={{ maxWidth: '100%' }}
                          src={currentImg}
                          alt="Current"
                        />
                      )}
                      {!currentImg && (
                        <>
                          <Heading>Add Files</Heading>
                          <Subheading>
                            <TextStyle variation="subdued">
                              Accepts .gif, .jpg, .png
                            </TextStyle>
                          </Subheading>
                        </>
                      )}
                    </div>
                  }
                />
              </DropZone>
              <MulticolorOption
                label="Email Background Color"
                value={options.Settings.backgroundColor}
                onChange={(val) => {
                  updateTemplate(
                    'backgroundColor',
                    options.Settings.backgroundColor,
                    val,
                    'Settings'
                  );
                }}
              />
              <MulticolorOption
                label="Header Font Color"
                value={options.GlobalFont.gblHeaderColor}
                onChange={(val) => {
                  updateTemplate(
                    'gblHeaderColor',
                    options.GlobalFont.gblHeaderColor,
                    val,
                    'GlobalFont'
                  );
                }}
              />
              <MulticolorOption
                label="Body Font Color"
                value={options.GlobalFont.gblBodyColor}
                onChange={(val) => {
                  updateTemplate(
                    'gblBodyColor',
                    options.GlobalFont.gblBodyColor,
                    val,
                    'GlobalFont'
                  );
                }}
              />
              <MulticolorOption
                label="Button Color"
                value={options.GlobalButton.gblButtonColor}
                onChange={(val) => {
                  updateTemplate(
                    'gblButtonColor',
                    options.GlobalButton.gblButtonColor,
                    val,
                    'GlobalButton'
                  );
                }}
              />
            </div>
            <div>
              <MagicPreview />
            </div>
          </div>
        </Modal.Section>
      )}
    </Modal>
  );
};

export default MagicModal;
