import { Modal, TextContainer } from '@shopify/polaris';
import React from 'react';

const SavedModal = ({ setId, saveActive, setSaveActive }) => {
  const query = new URLSearchParams(window.location.search);
  const set = query.get('set') || '';
  return (
    <div style={{ height: saveActive ? '500px' : 0 }}>
      <Modal
        instant
        open={saveActive}
        onClose={() => setSaveActive(false)}
        title="Saved"
        primaryAction={{
          content: set ? 'Publish' : 'Review',
          onAction: () => {
            window.location = `/Publish?setId=${setId}`;
          },
        }}
        secondaryActions={[
          {
            content: 'Continue editing',
            onAction: () => setSaveActive(false),
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>
              Your Templates have been saved, would you like to publish them
              now, or continue editing?
            </p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default SavedModal;
