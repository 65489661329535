import { logout } from '../framework/util/auth';
import {
  deleteJson,
  getJson,
  postJson,
  putJson,
  uploadFile,
} from '../framework/util/rest';

const APP_NAME = 'spently';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const getSpently = async (endpoint, errorHandler) => {
  await sleep(0);

  return getJson(APP_NAME, endpoint, (error) => {
    if (error.status === 401) {
      logout();
      sessionStorage.setItem('spently-returnUrl', document.location.href);
      document.location = '/';
    }

    if (errorHandler) {
      errorHandler();
    }
  });
};

export const postSpently = async (endpoint, data, errorHandler) =>
  postJson(APP_NAME, endpoint, data, errorHandler);

export const putSpently = async (endpoint, data, errorHandler) =>
  putJson(APP_NAME, endpoint, data, errorHandler);

export const uploadSpently = async (endpoint, file, errorHandler) =>
  uploadFile(APP_NAME, endpoint, file, errorHandler);

export const deleteSpently = async (endpoint, errorHandler) =>
  deleteJson(APP_NAME, endpoint, errorHandler);
