import { Stack } from '@shopify/polaris';
import React, { useContext } from 'react';
import { TemplateContext } from '../../../state/TemplateContext';
import BuilderOption from '../BuilderOption';

const DividerSettings = () => {
  const [{ options }, updateTemplate] = useContext(TemplateContext);
  const divSettings = options.GlobalDivider;

  const change = (propName, val) => {
    const prop = `gblDiv${propName}`;
    updateTemplate(prop, divSettings[prop], val, 'GlobalDivider');
  };

  return (
    <Stack spacing="loose" vertical>
      <BuilderOption
        option={{
          type: 'number',
          label: 'Thickness',
          defaultValue: divSettings.gblDivThickness,
          min: 1,
          max: 100,
          unit: 'px',
        }}
        onChange={change}
      />
      <BuilderOption
        option={{
          type: 'number',
          label: 'Width',
          defaultValue: divSettings.gblDivWidth,
          min: 1,
          max: 100,
          unit: '%',
        }}
        onChange={change}
      />
      <BuilderOption
        option={{
          type: 'multicolor',
          label: 'Color',
          defaultValue: divSettings.gblDivColor,
        }}
        onChange={change}
      />
      <BuilderOption
        option={{
          type: 'position',
          label: 'Position',
          defaultValue: divSettings.gblDivPosition,
        }}
        onChange={change}
      />
    </Stack>
  );
};

export default DividerSettings;
