import { Subheading, Stack } from '@shopify/polaris';
import React from 'react';
import BuilderOption from '../../BuilderOption';

const BodyFont = ({ settings, setFontSettings }) => (
  <Stack spacing="loose" vertical>
    <Subheading>BODY FONT</Subheading>
    <BuilderOption
      option={{
        type: 'font',
        label: 'Font Family',
        name: 'gblBodyFamily',
        defaultValue: settings.gblBodyFamily,
      }}
      isGlobal
      onChange={setFontSettings}
    />
    <BuilderOption
      option={{
        type: 'multicolor',
        name: 'gblBodyColor',
        label: 'Font color',
        defaultValue: settings.gblBodyColor,
      }}
      isGlobal
      onChange={setFontSettings}
    />
    <BuilderOption
      option={{
        type: 'number',
        label: 'Font Size',
        name: 'gblBodySize',
        defaultValue: settings.gblBodySize,
        max: 40,
        unit: 'px',
      }}
      isGlobal
      onChange={setFontSettings}
    />
    <BuilderOption
      option={{
        type: 'number',
        label: 'Letter Spacing',
        name: 'gblBodySpacing',
        defaultValue: settings.gblBodySpacing,
        max: 5,
        unit: 'px',
      }}
      isGlobal
      onChange={setFontSettings}
    />
    <BuilderOption
      option={{
        type: 'checkbox',
        label: 'Bold font',
        name: 'gblBodyBold',
        defaultValue: settings.gblBodyBold,
      }}
      isGlobal
      onChange={setFontSettings}
    />
    <BuilderOption
      option={{
        type: 'checkbox',
        label: 'Italicize font',
        name: 'gblBodyItalic',
        defaultValue: settings.gblBodyItalic,
      }}
      isGlobal
      onChange={setFontSettings}
    />
    <BuilderOption
      option={{
        type: 'position',
        label: 'Font Position',
        name: 'gblBodyPos',
        defaultValue: settings.gblBodyPos,
      }}
      isGlobal
      onChange={setFontSettings}
    />
  </Stack>
);

export default BodyFont;
