import { FormLayout, TextField } from '@shopify/polaris';
import React from 'react';
import { SocialIcon } from 'react-social-icons';

const SocialSettings = ({ settings, save }) => {
  const ThemedSocialIcon = ({ network }) => (
    <SocialIcon
      style={{ height: 24, width: 24, marginTop: -3, marginLeft: -6 }}
      network={network}
      bgColor="black"
      fgColor="white"
    />
  );

  return (
    <FormLayout>
      <TextField
        label="Facebook"
        value={settings.facebookUrl}
        prefix={<ThemedSocialIcon network="facebook" />}
        onChange={(val) => save({ facebookUrl: val })}
      />
      <TextField
        label="Instagram"
        value={settings.instagramUrl}
        prefix={<ThemedSocialIcon network="instagram" />}
        onChange={(val) => save({ instagramUrl: val })}
      />
      <TextField
        label="Rss Feed"
        value={settings.rssFeedUrl}
        prefix={<ThemedSocialIcon network="rss" />}
        onChange={(val) => save({ rssFeedUrl: val })}
      />
      <TextField
        label="Twitter"
        value={settings.twitterUrl}
        prefix={<ThemedSocialIcon network="twitter" />}
        onChange={(val) => save({ twitterUrl: val })}
      />
      <TextField
        label="Facebook Messenger"
        value={settings.messengerUrl}
        prefix={<ThemedSocialIcon network="facebook" />}
        onChange={(val) => save({ messengerUrl: val })}
      />
      <TextField
        label="YouTube"
        value={settings.youtubeUrl}
        prefix={<ThemedSocialIcon network="youtube" />}
        onChange={(val) => save({ youtubeUrl: val })}
      />
      <TextField
        label="Pinterest"
        value={settings.pinterestUrl}
        prefix={<ThemedSocialIcon network="pinterest" />}
        onChange={(val) => save({ pinterestUrl: val })}
      />
      <TextField
        label="Snapchat"
        value={settings.snapchatUrl}
        prefix={<ThemedSocialIcon network="snapchat" />}
        onChange={(val) => save({ snapchatUrl: val })}
      />
      <TextField
        label="LinkedIn"
        value={settings.linkedInUrl}
        prefix={<ThemedSocialIcon network="linkedin" />}
        onChange={(val) => save({ linkedInUrl: val })}
      />
      <TextField
        label="Tiktok"
        value={settings.tikTokUrl}
        prefix={<ThemedSocialIcon network="tiktok" />}
        onChange={(val) => save({ tikTokUrl: val })}
      />
    </FormLayout>
  );
};

export default SocialSettings;
