/* eslint-disable jsx-a11y/anchor-is-valid */
import { Banner } from '@shopify/polaris';
import React from 'react';
import { getIsOverUsage } from '../../framework/util/auth';

const PlanLimitUpgrade = () => {
  if (!getIsOverUsage()) {
    return null;
  }

  return (
    <div className="skinny-banner">
      <Banner
        title="Plan limit reached."
        status="critical"
        action={{ content: 'Upgrade', url: '/Usage' }}
      >
        <p>
          Publishing has been temporarily disabled - please upgrade your plan to
          publish notification emails.
        </p>
      </Banner>
    </div>
  );
};

export default PlanLimitUpgrade;
