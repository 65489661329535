import {
  MediaCard,
  SkeletonThumbnail,
  Stack,
  Card,
  SkeletonBodyText,
  DisplayText,
  EmptyState,
} from '@shopify/polaris';
import React, { useEffect, useState } from 'react';
import { getSpently } from '../../util/spentlyRest';

const PanCardList = ({ card, vertical, url, massageData, emptyMessage }) => {
  const [results, setResults] = useState(null);
  useEffect(() => {
    const loadData = async () => {
      const response = await getSpently(url);
      if (massageData) {
        setResults(massageData(response));
      } else {
        setResults(response);
      }
    };

    if (!results) {
      loadData();
    }
  });

  const verticalSkeleton = () => (
    <MediaCard portrait title={<SkeletonBodyText lines={2} />}>
      <div
        style={{
          width: 200,
          height: 200,
          backgroundColor: 'rgb(228, 229, 231)',
        }}
      />
    </MediaCard>
  );

  const horizontalSkeleton = () => (
    <Card sectioned>
      <Stack>
        <SkeletonThumbnail />
        <Stack.Item fill>
          <Stack vertical>
            <SkeletonBodyText lines={3} />
          </Stack>
        </Stack.Item>
      </Stack>
    </Card>
  );

  if (!results) {
    return (
      <Stack vertical={vertical} distribution="leading">
        {!vertical && verticalSkeleton()}
        {!vertical && verticalSkeleton()}
        {!vertical && verticalSkeleton()}
        {!vertical && verticalSkeleton()}
        {vertical && horizontalSkeleton()}
        {vertical && horizontalSkeleton()}
        {vertical && horizontalSkeleton()}
      </Stack>
    );
  }

  if (emptyMessage && results.length === 0) {
    return (
      <Card>
        <EmptyState>
          <DisplayText size="small">{emptyMessage}</DisplayText>
        </EmptyState>
      </Card>
    );
  }

  return (
    <Stack vertical={vertical} distribution="leading">
      {results.map(card)}
    </Stack>
  );
};

export default PanCardList;
