/* eslint-disable no-underscore-dangle */
import {
  Scrollable,
  Icon,
  Navigation,
  Heading,
  Popover,
  Stack,
} from '@shopify/polaris';
import {
  CirclePlusMajor,
  FooterMajor,
  HeaderMajor,
  QuestionMarkMajor,
  SettingsMajor,
} from '@shopify/polaris-icons';
import React, { useContext, useState } from 'react';
import { emailTypes } from '../../util/formatters';
import { Icons } from '../../util/superGrossIconFile';
import '../../cssOverrides/builder_componentTree.css';
import { logDebug } from '../../util/logging';
import { TemplateContext } from '../../state/TemplateContext';
import PanNavItem from '../../framework/ui/PanNavItem';
import { BaseComponentContext } from '../../state/BaseComponentContext';
import PanFilteredSelect from '../../framework/ui/PanFilteredSelect';
import theme from '../../framework/util/theme';
import SkeletonComponentTree from './sidebars/SkeletonComponentTree';
import {
  allComponentKeysInTemplate,
  filterAvailableComponents,
} from './util/TemplateSelector';

const ComponentTree = ({ onSelect, selectedKey, onThemeSettings }) => {
  const query = new URLSearchParams(window.location.search);
  const isAF = !query.get('set');
  const [template, updateTemplate] = useContext(TemplateContext);
  const [components] = useContext(BaseComponentContext);
  const [draggingComponent, setDraggingCompnoent] = useState(null);
  const [newCompActive, setNewCompActive] = useState(false);
  const templateComponents = template ? template.components : [];
  const templateName =
    template &&
    template.templateId &&
    emailTypes[template.templateId.substring(0, 2)];
  const { colors } = theme();

  if (!template || !components) {
    return <SkeletonComponentTree />;
  }

  const items = templateComponents.map((c, ind) => {
    const name = c && (c.id || c._id);
    const key = `${name}-${ind}`;
    const media = Icons[name] && <Icon source={() => Icons[name]} />;
    const component = components.filter((comp) => comp.key === name)[0];
    const realName = component.name;
    let { groupOption } = component;
    const isGroupComponent = !!groupOption;

    if (groupOption && c.columns && c.numColumns) {
      const numColumns = parseInt(c.numColumns, 10);
      const canAdd = numColumns > Object.keys(c.columns).length;
      groupOption = canAdd ? groupOption : null;
    }
    return {
      key,
      label: realName,
      draggable: true,
      onClick: (childId) => {
        const keyWithChild =
          typeof childId === 'string' ? `${key}-${childId}` : key;
        logDebug(`User selected ${keyWithChild}`);
        onSelect(keyWithChild);
      },
      icon: () => media || QuestionMarkMajor,
      selected: `${ind}` === selectedKey,
      children: c && isGroupComponent && (c.columns || []),
      groupOption,
      currentSelected: selectedKey,
    };
  });

  const tempKeys = allComponentKeysInTemplate(template);

  // components that available for selected template type
  const availableComponents = filterAvailableComponents(
    components,
    tempKeys,
    template
  );

  // component items that still avaliable to select for main template
  const availableComponentsItems = (availableComponents || [])
    // filter out component that only allow use once and already selected
    .map((c) => ({
      key: c.name,
      content: (
        <Stack>
          <div style={{ width: 20, height: 20, marginRight: 16 }}>
            {Icons[c.key]}
          </div>
          <span>{c.name}</span>
        </Stack>
      ),
      onAction: () => {
        const newComponent = {
          _id: c.key,
          section: c.section,
        };

        c.options.forEach((opt) => {
          if (opt.defaultValue) {
            newComponent[opt.name] = opt.defaultValue;
          }
        });

        updateTemplate('ADD', null, JSON.stringify(newComponent));
        setNewCompActive(false);
      },
    }));

  return (
    <Scrollable
      shadow
      style={{
        height: 'calc(100vh - 3.5rem)',
        margin: -16,
        marginLeft: -32,
        backgroundColor: colors.surface,
        padding: '0',
      }}
    >
      <Navigation>
        {!isAF && (
          <div
            style={{
              padding: '1rem 0 1.5rem 1rem',
            }}
          >
            <Heading element="h1">{templateName}</Heading>
          </div>
        )}
        <Navigation.Section
          items={[
            {
              label: 'Header',
              icon: HeaderMajor,
              selected: selectedKey === '999',
              onClick: () => onSelect('Header-999'),
            },
          ]}
          separator
        />
        <div className="components">
          {items.map((item, index) => (
            <PanNavItem
              key={item.key}
              item={{ ...item, seperator: index === 0 }}
              onDragStart={(evt) => setDraggingCompnoent(evt)}
              onDroppedOn={(evt) => {
                if (draggingComponent) {
                  updateTemplate('REARANGE', draggingComponent, evt);
                  setDraggingCompnoent(null);
                  let newIndex = evt.split('-')[1];
                  const oldParts = draggingComponent.split('-');
                  const oldPath = oldParts[0];
                  if (newIndex > oldParts[1]) {
                    // Make up for the removed item
                    newIndex = parseInt(newIndex, 10) - 1;
                  }
                  onSelect(`${oldPath}-${newIndex}`);
                }
              }}
              availableComponents={availableComponents}
              updateTemplate={updateTemplate}
              childItems={item.children}
            />
          ))}
          <Popover
            active={newCompActive}
            activator={
              <PanNavItem
                item={{
                  key: 'add-section',
                  label: 'Add section',
                  icon: CirclePlusMajor,
                  onClick: () => setNewCompActive(true),
                  selected: true,
                }}
                onDroppedOn={(evt) => {
                  updateTemplate('REARANGE', draggingComponent, evt);
                  setDraggingCompnoent(null);
                  const oldParts = draggingComponent.split('-');
                  const oldPath = oldParts[0];
                  onSelect(`${oldPath}-${template.components.length - 1}`);
                }}
              />
            }
            fullWidth
            autofocusTarget="first-node"
            onClose={() => setNewCompActive(false)}
          >
            <PanFilteredSelect
              items={[{ title: 'components', items: availableComponentsItems }]}
            />
          </Popover>
        </div>
        <Navigation.Section
          separator
          fill
          items={[
            {
              label: 'Footer',
              icon: FooterMajor,
              selected: selectedKey === '998',
              onClick: () => onSelect('Footer-998'),
            },
          ]}
        />
        <Navigation.Section
          separator
          items={[
            {
              label: 'Template Settings',
              icon: SettingsMajor,
              onClick: onThemeSettings,
            },
          ]}
        />
      </Navigation>
    </Scrollable>
  );
};

export default ComponentTree;
