import React, { useState, useEffect, useContext } from 'react';
import { Autocomplete, Icon } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';
import { getSpently } from '../../../util/spentlyRest';
import OptionsContext from '../../../state/OptionsContext';

const ProductsOption = ({ onChange, label }) => {
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(false);
  const [dropDownList, setDropdownList] = useState();
  const {
    prods,
    setProds,
    searchTrigger,
    setSearchTrigger,
    productError,
    setProductError,
    filter,
    setFilter,
  } = useContext(OptionsContext);

  const loadOptions = (productSearchValue) => {
    if (!loading) {
      setLoading(true);
    }
    if (productSearchValue !== '') {
      const results = prods.filter((option) =>
        option.label.toLowerCase().includes(productSearchValue)
      );
      setDropdownList(results);
    } else {
      setDropdownList(prods);
    }
  };

  const applyProducts = (prod) => {
    setFilter(prod.label);
    onChange({
      src: prod.image ? prod.image.src : [],
      title: prod.label,
      handle: prod.handle,
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      const response = await getSpently(`/api/Products`);
      const products = response.map((p) => ({
        label: p.title,
        value: `${p.id}`,
        handle: p.handle,
        image: p.image,
      }));
      setProds(products);
      setDropdownList(prods);
      setLoading(false);
    };

    if (!prods) {
      if (!initialLoad) {
        setInitialLoad(true);
      }
    }

    if (searchTrigger) {
      const prod = prods[prods.length - 1];
      applyProducts(prod);
      setSearchTrigger(false);
    }

    if (!prods) {
      loadData();
    } else {
      setProds(prods);
      if (!dropDownList) {
        setDropdownList(prods);
      }
      setLoading(false);
    }
  });
  const textField = (
    <Autocomplete.TextField
      onChange={(txt) => {
        setFilter(txt);
        loadOptions(txt);
        setProductError(false);
      }}
      selected={filter}
      label={label}
      value={filter}
      prefix={<Icon source={SearchMinor} color="base" />}
      placeholder="Search"
      error={productError}
    />
  );
  return (
    <>
      <Autocomplete
        textField={textField}
        selected={filter}
        onSelect={(s) => {
          const prod = prods.find((p) => p.value === s[0]);
          applyProducts(prod);
        }}
        loading={loading}
        options={dropDownList || []}
      />
    </>
  );
};

export default ProductsOption;
