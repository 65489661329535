import { Stack } from '@shopify/polaris';
import React, { useState } from 'react';
import { HeaderFont, BodyFont, ButtonFont } from './fonts';

const FontSettings = ({ values, updateTemplate }) => {
  const [optVal, setOptVal] = useState(values);

  const updateFont = async (n, evt) => {
    const newOpts = { ...optVal };
    const oldVal = values[n] || newOpts[n];
    newOpts[n] = evt;
    setOptVal(newOpts);
    updateTemplate(n, oldVal, evt, 'GlobalFont');
  };

  return (
    <Stack spacing="loose" vertical>
      <HeaderFont settings={optVal} setFontSettings={updateFont} />
      <hr />
      <BodyFont settings={optVal} setFontSettings={updateFont} />
      <hr />
      <ButtonFont settings={optVal} setFontSettings={updateFont} />
      <hr />
    </Stack>
  );
};

export default FontSettings;
