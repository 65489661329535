import { Button } from '@shopify/polaris';
import React, { useCallback, useState, useContext } from 'react';
import { getSpently } from '../../../util/spentlyRest';
import OptionsContext from '../../../state/OptionsContext';

const LoadMoreCollections = () => {
  const [, setCols] = useState(null);
  const {
    setDropdown,
    customCursor,
    setCustomCursor,
    smartCursor,
    setSmartCursor,
    disabled,
    setDisable,
  } = useContext(OptionsContext);

  const getMoreResults = useCallback(async () => {
    setDisable(true);
    const colRes = await getSpently(
      `/api/Collections?cc=${encodeURIComponent(
        customCursor
      )}&sc=${encodeURIComponent(smartCursor)}`
    );

    const fromStore = colRes.collections.map((c) => ({
      label: c.title,
      value: `${c.id}`,
      id: c.id,
    }));
    setDropdown(fromStore);
    setSmartCursor(colRes.smartLink);
    setCustomCursor(colRes.customLink);
    setCols([...fromStore]);

    setDisable(false);

    if (colRes.smartLink == null && colRes.customLink == null) {
      setDisable(true);
    }
  }, [
    customCursor,
    smartCursor,
    setDropdown,
    setSmartCursor,
    setCustomCursor,
    setDisable,
  ]);

  return (
    <Button disabled={disabled} onClick={getMoreResults}>
      Load More Collections
    </Button>
  );
};

export default LoadMoreCollections;
