import { Stack } from '@shopify/polaris';
import React, { useCallback, useContext } from 'react';
import { TemplateContext } from '../../../state/TemplateContext';
import BuilderOption from '../BuilderOption';

const NavigationSideBar = ({ setDisplayOptions }) => {
  const [{ options }, updateTemplate] = useContext(TemplateContext);
  const values = options.NavigationBar;
  const change = useCallback(
    (propName, val) => {
      updateTemplate(propName, values[propName], val, 'NavigationBar');
    },
    [updateTemplate, values]
  );

  const Option = ({ option }) => (
    <BuilderOption
      option={option}
      values={values}
      onChange={change}
      setDisplayOptions={setDisplayOptions}
      key={option.name}
    />
  );

  const ColorOption = useCallback(
    ({ option }) => <Option option={{ ...option, type: 'multicolor' }} />,
    []
  );

  const renderInputOption = useCallback(
    (option) => (
      <BuilderOption
        option={option}
        values={values}
        onChange={change}
        setDisplayOptions={setDisplayOptions}
      />
    ),
    [change, setDisplayOptions, values]
  );

  return (
    <Stack vertical>
      <Option
        option={{
          type: 'dropdown',
          name: 'textType',
          options: [
            { label: 'Body', value: 'body' },
            { label: 'Header', value: 'header' },
          ],
          label: 'Follow Font Family Style',
          defaultValue: 'header',
        }}
      />
      {renderInputOption({
        type: 'number',
        name: 'verticalMargin',
        label: 'Vertical margin',
        min: 0,
        max: 100,
        defaultValue: 0,
      })}
      {renderInputOption({
        type: 'number',
        name: 'fontSize',
        label: 'Font size',
        min: 10,
        max: 40,
        defaultValue: 15,
      })}
      {renderInputOption({
        type: 'number',
        name: 'horizontalSpacing',
        label: 'Horizontal spacing',
        min: 10,
        max: 50,
        defaultValue: 25,
      })}

      {renderInputOption({
        type: 'number',
        name: 'height',
        label: 'Height',
        min: 0,
        max: 100,
        defaultValue: 15,
      })}
      <ColorOption
        option={{
          type: 'multicolor',
          name: 'backgroundColor',
          label: 'Background color',
        }}
      />
      <ColorOption
        option={{
          type: 'multicolor',
          name: 'textColor',
          label: 'Font color',
        }}
      />
      <Option
        option={{
          type: 'button',
          action: 'editNavLinks',
          label: 'Edit Links',
        }}
      />
    </Stack>
  );
};

export default NavigationSideBar;
