/* eslint-disable max-lines */
import {
  Card,
  DisplayText,
  Layout,
  Banner,
  Button,
  Page,
  Stack,
  TextStyle,
  Loading,
  Popover,
  EmptyState,
} from '@shopify/polaris';
import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { add, format, parseISO } from 'date-fns';
import { CalendarMajor } from '@shopify/polaris-icons';
import { getSpently } from '../util/spentlyRest';
import DatePickerDropdown from '../components/DatePickerDropdown';
import { emailTypes, formatEmailType, urlDate } from '../util/formatters';
import { formatCurrency } from '../framework/util/formatters';
import { DateRangeContext } from '../state/DateRangeContext';
import RoiTable from '../widgets/reports/RoiTable';
import ReportStats from '../widgets/reports/ReportStats';
import PanFilteredSelect from '../framework/ui/PanFilteredSelect';
import theme from '../framework/util/theme';

const Reports = () => {
  const query = new URLSearchParams(useLocation().search);
  const emailType = query.get('emailType');
  const { colors } = theme();

  const [results, setResults] = useState(null);
  const [error, setError] = useState(null);
  const [range, setRange] = useContext(DateRangeContext);
  const [typeSelector, setTypeSelector] = useState(false);

  useEffect(() => {
    const load = async () => {
      let qs = `?start=${urlDate(range.start)}&end=${urlDate(
        range.end
      )} 23:59:59`;
      if (emailType) {
        qs += `&emailType=${emailType}`;
      }
      const r = await getSpently(`/api/Report${qs}`, setError);
      setResults(r);
    };

    load();
  }, [range, emailType]);

  if (error) {
    return (
      <Banner
        title="Could not Retrieve Data"
        action={{ content: 'Report this Error' }}
        status="critical"
      >
        <p>{error.statusText}</p>
      </Banner>
    );
  }

  if (!results) {
    return (
      <Page fullWidth title="Reports">
        <Loading />
      </Page>
    );
  }
  const data = {
    labels: [],
    datasets: [
      {
        label: 'Revenue',
        data: [],
        fill: true,
        backgroundColor: colors.graph.fill,
        borderColor: colors.graph.border,
        lineTension: 0,
      },
    ],
  };

  const opts = {
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      callbacks: {
        label: (obj) => formatCurrency(parseFloat(obj.value)),
        title: (obj) => format(parseISO(obj[0].label), 'dd-MMM-yyyy'),
      },
    },
    scales: {
      x: {
        type: 'time',
        min: range.start,
        max: range.end,
        time: {
          parser: 'yyyy-MM-dd',
          minUnit: 'week',
          displayFormats: {
            hour: 'MMM dd',
            day: 'MMM dd',
            week: 'MMM dd',
            month: 'MMM yyyy',
          },
        },
      },
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      tooltip: {
        callbacks: { label: (value) => formatCurrency(value.parsed.y) },
      },
    },
  };

  if (results.roiByTimePeriod.length === 1) {
    // In the case of a single data point we'll put in extras just to make
    // it look nice and centered.
    const single = results.roiByTimePeriod[0];
    data.labels.push(add(parseISO(single.period), { days: -1 }));
    data.datasets[0].data.push(null);
    data.labels.push(results.roiByTimePeriod[0].period);
    data.datasets[0].data.push(results.roiByTimePeriod[0].value);
    data.labels.push(add(parseISO(single.period), { days: 1 }));
    data.datasets[0].data.push(null);
  } else {
    results.roiByTimePeriod
      .sort((a, b) => (a.period > b.period ? 1 : -1))
      .forEach((result) => {
        data.labels.push(result.period);
        data.datasets[0].data.push(result.value);
      });
  }

  const showChart = results && results.roiByTimePeriod.length > 0;
  const errorMsg = "We couldn't find any data in the range you selected.";
  return (
    <Page fullWidth title="Reports">
      <Layout>
        <Layout.Section fullWidth>
          <Layout>
            <Layout.Section>
              <Card sectioned>
                <Stack distribution="equalSpacing">
                  <Popover
                    activator={
                      <Button
                        plain
                        removeUnderline
                        disclosure={typeSelector ? 'up' : 'down'}
                        onClick={() => setTypeSelector(!typeSelector)}
                      >
                        <DisplayText>
                          {formatEmailType(results.emailType)}
                        </DisplayText>
                      </Button>
                    }
                    autofocusTarget="first-node"
                    active={typeSelector}
                    onClose={() => setTypeSelector(!typeSelector)}
                  >
                    <Popover.Pane>
                      <PanFilteredSelect
                        items={[
                          {
                            title: 'Email Types',
                            items: Object.keys(emailTypes).map((et) => ({
                              key: emailTypes[et],
                              content: emailTypes[et],
                              onAction: () => {
                                document.location = `/Reports?emailType=${et}`;
                              },
                            })),
                          },
                        ]}
                      />
                    </Popover.Pane>
                  </Popover>
                  <DisplayText>
                    <TextStyle variation="positive">
                      {formatCurrency(results.revenue)}
                    </TextStyle>
                  </DisplayText>
                </Stack>
              </Card>
            </Layout.Section>

            <Layout.Section oneThird>
              <Card sectioned>
                <Stack distribution="center">
                  <DatePickerDropdown
                    range={range}
                    updateRange={(r) => {
                      setResults(null);
                      setRange(r);
                    }}
                  />
                </Stack>
              </Card>
            </Layout.Section>
          </Layout>
        </Layout.Section>
        <Layout.Section fullWidth>
          <Layout>
            <Layout.Section>
              <Card title="Revenue" sectioned>
                <div
                  style={{
                    height: 385,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {results && !showChart && (
                    <div>
                      <EmptyState fullWidth>
                        <CalendarMajor
                          style={{ height: 48, fill: 'var(--p-text-subdued)' }}
                        />
                        <DisplayText size="small">{errorMsg}</DisplayText>
                        <p>Try selecting a different date range.</p>
                      </EmptyState>
                    </div>
                  )}
                  {showChart && (
                    <Chart type="line" data={data} options={opts} />
                  )}
                </div>
              </Card>
            </Layout.Section>

            <Layout.Section secondary>
              <ReportStats results={results} />
            </Layout.Section>
          </Layout>
        </Layout.Section>
        <Layout.Section fullWidth>
          <RoiTable emailType={emailType} range={range} />
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default Reports;
