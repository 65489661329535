/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
/* eslint-disable max-lines */
import React from 'react';

export const Icons = {
  Text: (
    <svg viewBox="0 0 16 16">
      <path d="M0 1h16v2H0zm0 3h10v2H0zm0 6h10v2H0zm0-3h16v2H0zm0 6h16v2H0z" />
    </svg>
  ),
  LiquidText: (
    <svg viewBox="0 0 16 16">
      <path d="M0 1h16v2H0zm0 3h10v2H0zm0 6h10v2H0zm0-3h16v2H0zm0 6h16v2H0z" />
    </svg>
  ),
  Image: (
    <svg viewBox="0 0 16 16">
      <path d="M14.998 2l.002.002v11.996l-.002.002H1.002L1 13.998V2.002L1.002 2h13.996zM15 1H1c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h14c.55 0 1-.45 1-1V2c0-.55-.45-1-1-1z" />
      <path d="M13 4.5a1.5 1.5 0 1 1-3.001-.001A1.5 1.5 0 0 1 13 4.5zM14 13H2v-2l3.5-6 4 5h1L14 7z" />
    </svg>
  ),
  Divider: (
    <svg viewBox="0 0 16 16">
      <path d="M0 6.5v3a.5.5 0 0 0 .5.5h15a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5H.5a.5.5 0 0 0-.5.5z" />
    </svg>
  ),
  Button: (
    <svg viewBox="0 0 16 16">
      <path d="M15 9.5V7a1.502 1.502 0 0 0-2.236-1.307 1.5 1.5 0 0 0-2.264-.31A1.494 1.494 0 0 0 9 5.086V1.5C9 .673 8.327 0 7.5 0S6 .673 6 1.5v6.167L3.25 6.201a1.502 1.502 0 0 0-1.789 2.381l.012.011L5.21 12H4.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-.691l1.138-2.276A.496.496 0 0 0 15 9.5zm-1 4a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0-4.118L12.691 12H6.694L2.15 7.857a.5.5 0 0 1 .614-.782l3.5 1.866a.499.499 0 0 0 .735-.441v-7a.5.5 0 0 1 1 0v5a.5.5 0 0 0 1 0 .5.5 0 0 1 1 0 .5.5 0 0 0 1 0 .5.5 0 0 1 1 0V7a.5.5 0 0 0 1 0 .5.5 0 0 1 1 0v2.382z" />
    </svg>
  ),
  Group: (
    <svg viewBox="0 0 16 16">
      <path d="M6 3h2v1H6zm3 0h2v1H9zm5 0v4h-3V6h2V4h-1V3zM5 6h2v1H5zm3 0h2v1H8zM3 4v2h1v1H2V3h3v1zm3 5h2v1H6zm3 0h2v1H9zm5 0v4h-3v-1h2v-2h-1V9zm-9 3h2v1H5zm3 0h2v1H8zm-5-2v2h1v1H2V9h3v1zm12-9H1v14h14V1zm1-1v16H0V0h16z" />
    </svg>
  ),
  Code: (
    <svg viewBox="0 0 20 16">
      <path d="M13 11.5l1.5 1.5 5-5-5-5L13 4.5 16.5 8zM7 4.5L5.5 3l-5 5 5 5L7 11.5 3.5 8zM10.958 2.352l1.085.296-3 11-1.085-.296 3-11z" />
    </svg>
  ),
  SocialIcons: (
    <svg viewBox="0 0 16 16">
      <path d="M13.5 11c-.706 0-1.342.293-1.797.763L4.969 8.396a2.46 2.46 0 0 0 0-.792l6.734-3.367a2.5 2.5 0 1 0-.672-1.341L4.297 6.263a2.5 2.5 0 1 0 0 3.474l6.734 3.367A2.5 2.5 0 1 0 13.5 11z" />
    </svg>
  ),
  Discount: (
    <svg viewBox="0 0 16 16">
      <path d="M0,3.85v0A3.59,3.59,0,0,1,3.53,0,3.54,3.54,0,0,1,7,3.76v0a3.59,3.59,0,0,1-3.53,3.8A3.55,3.55,0,0,1,0,3.85ZM12.39,0.17h2.1L8.63,8.1,3.61,15H1.51l5.84-7.9ZM1.89,3.76v0C1.89,5,2.57,6,3.53,6S5.12,5,5.12,3.85v0A1.93,1.93,0,0,0,3.48,1.64C2.49,1.64,1.89,2.61,1.89,3.76ZM9,11.45v0a3.52,3.52,0,1,1,7,0v0a3.6,3.6,0,0,1-3.53,3.8A3.55,3.55,0,0,1,9,11.45Zm5.12,0v0a1.93,1.93,0,0,0-1.64-2.17c-1,0-1.59,1-1.59,2.12v0a1.91,1.91,0,0,0,1.64,2.17C13.51,13.58,14.11,12.6,14.11,11.45Z" />
    </svg>
  ),
  NewDiscount: (
    <svg viewBox="0 0 16 16">
      <path d="M0,3.85v0A3.59,3.59,0,0,1,3.53,0,3.54,3.54,0,0,1,7,3.76v0a3.59,3.59,0,0,1-3.53,3.8A3.55,3.55,0,0,1,0,3.85ZM12.39,0.17h2.1L8.63,8.1,3.61,15H1.51l5.84-7.9ZM1.89,3.76v0C1.89,5,2.57,6,3.53,6S5.12,5,5.12,3.85v0A1.93,1.93,0,0,0,3.48,1.64C2.49,1.64,1.89,2.61,1.89,3.76ZM9,11.45v0a3.52,3.52,0,1,1,7,0v0a3.6,3.6,0,0,1-3.53,3.8A3.55,3.55,0,0,1,9,11.45Zm5.12,0v0a1.93,1.93,0,0,0-1.64-2.17c-1,0-1.59,1-1.59,2.12v0a1.91,1.91,0,0,0,1.64,2.17C13.51,13.58,14.11,12.6,14.11,11.45Z" />
    </svg>
  ),
  Tweet: (
    <svg viewBox="0 0 16 16">
      <path d="M16 3.538a6.461 6.461 0 0 1-1.884.516 3.301 3.301 0 0 0 1.444-1.816 6.607 6.607 0 0 1-2.084.797 3.28 3.28 0 0 0-2.397-1.034 3.28 3.28 0 0 0-3.197 4.028 9.321 9.321 0 0 1-6.766-3.431 3.284 3.284 0 0 0 1.015 4.381A3.301 3.301 0 0 1 .643 6.57v.041A3.283 3.283 0 0 0 3.277 9.83a3.291 3.291 0 0 1-1.485.057 3.293 3.293 0 0 0 3.066 2.281 6.586 6.586 0 0 1-4.862 1.359 9.286 9.286 0 0 0 5.034 1.475c6.037 0 9.341-5.003 9.341-9.341 0-.144-.003-.284-.009-.425a6.59 6.59 0 0 0 1.637-1.697z" />
    </svg>
  ),
  Sufio: (
    <svg viewBox="0 0 16 16">
      <path d="M14.3,3.6A15.3,15.3,0,0,0,13,2,10,10,0,0,0,11.4.6,3,3,0,0,0,10,0H2.3A1.32,1.32,0,0,0,1,1.3V14.8A1.31,1.31,0,0,0,2.3,16H13.8a1.32,1.32,0,0,0,1.3-1.3V5A5.57,5.57,0,0,0,14.3,3.6Zm-2-.9A5.83,5.83,0,0,1,13.4,4H11V1.6a10.9,10.9,0,0,1,1.3,1.1h0ZM14,14.8a0.32,0.32,0,0,1-.3.3H2.3A0.43,0.43,0,0,1,2,14.8V1.3A0.27,0.27,0,0,1,2.3,1H10V4.5a0.47,0.47,0,0,0,.5.5H14v9.8Z" />
      <path d="M5.69,9.91A1.32,1.32,0,0,1,6.11,11,1.32,1.32,0,0,1,5.69,12a1.74,1.74,0,0,1-1.18.37H3.75V14H2.94V9.54H4.51A1.74,1.74,0,0,1,5.69,9.91ZM4.51,11.67a0.76,0.76,0,0,0,.57-0.2A0.71,0.71,0,0,0,5.27,11a0.72,0.72,0,0,0-.19-0.52,0.75,0.75,0,0,0-.57-0.2H3.75v1.43H4.51Z" />
      <path d="M7.84,9.54a2.26,2.26,0,0,1,1.64.6,2.15,2.15,0,0,1,.63,1.63,2.14,2.14,0,0,1-.63,1.62,2.26,2.26,0,0,1-1.64.6H6.39V9.54H7.84ZM7.21,13.26H7.84a1.33,1.33,0,0,0,1-.43,1.53,1.53,0,0,0,.37-1.06,1.56,1.56,0,0,0-.37-1.08,1.32,1.32,0,0,0-1-.42H7.21v3Z" />
      <path d="M10.56,9.54h2.81v0.7h-2v1.17H13.2v0.69H11.37V14H10.56V9.54Z" />
    </svg>
  ),
  YouTube: (
    <svg viewBox="0 0 16 16">
      <path d="M15.841 4.8s-.156-1.103-.637-1.587c-.609-.637-1.291-.641-1.603-.678-2.237-.163-5.597-.163-5.597-.163h-.006s-3.359 0-5.597.163c-.313.038-.994.041-1.603.678C.317 3.697.164 4.8.164 4.8S.005 6.094.005 7.391v1.213c0 1.294.159 2.591.159 2.591s.156 1.103.634 1.588c.609.637 1.409.616 1.766.684 1.281.122 5.441.159 5.441.159s3.363-.006 5.6-.166c.313-.037.994-.041 1.603-.678.481-.484.637-1.588.637-1.588s.159-1.294.159-2.591V7.39c-.003-1.294-.162-2.591-.162-2.591zm-9.494 5.275V5.578l4.322 2.256-4.322 2.241z" />
    </svg>
  ),
  Marketing: (
    <svg viewBox="0 0 16 16">
      <path
        fill="#33CC99"
        d="M15.299-0.017H9.267c-0.415,0-0.994,0.24-1.287,0.533L0.5,7.995c-0.293,0.294-0.293,0.774,0,1.066  l6.474,6.475c0.293,0.293,0.772,0.293,1.066,0l7.479-7.478c0.293-0.294,0.533-0.873,0.533-1.288V0.737  C16.053,0.323,15.713-0.017,15.299-0.017z M11.528,6.015c-0.833,0-1.508-0.675-1.508-1.508S10.695,3,11.528,3  s1.508,0.675,1.508,1.508S12.361,6.015,11.528,6.015z"
      />
      <g>
        <path
          fill="#FFFFFF"
          d="M6.944,8.472l0.591-0.213c-0.084-0.297-0.081-0.551,0.128-0.75c0.27-0.258,0.668-0.225,1.012,0.135   c0.048,0.05,0.093,0.104,0.132,0.158L9.33,7.613C9.258,7.498,9.172,7.384,9.068,7.276C8.472,6.652,7.707,6.61,7.2,7.094   C6.771,7.504,6.775,7.947,6.944,8.472z"
        />
        <path
          fill="#FFFFFF"
          d="M8.775,8.94c0.15,0.157,0.391,0.173,0.592-0.021c0.193-0.184,0.246-0.481,0.174-0.805L8.744,8.402   C8.624,8.547,8.609,8.768,8.775,8.94z"
        />
        <path
          fill="#FFFFFF"
          d="M7.724,8.77l-0.583,0.21c0.008,0.02,0.016,0.039,0.025,0.059c0.201,0.467,0.34,0.897,0.035,1.189   c-0.32,0.306-0.751,0.238-1.127-0.156c-0.185-0.192-0.306-0.4-0.359-0.579L5.201,9.677c0.086,0.256,0.242,0.521,0.471,0.761   c0.606,0.636,1.397,0.764,1.987,0.199C8.219,10.102,8.037,9.504,7.724,8.77z"
        />
        <path
          fill="#FFFFFF"
          d="M6.043,8.795c-0.019-0.059-0.051-0.117-0.101-0.17c-0.164-0.173-0.415-0.168-0.621,0.03   C5.205,8.767,5.138,8.933,5.122,9.127L6.043,8.795z"
        />
      </g>
      <path
        fill="#FFFFFF"
        d="M3.435,9.733l6.459-2.322l0.186,0.511l-6.464,2.325L3.435,9.733z"
      />
    </svg>
  ),
  ConditionalRecommendedProduct: (
    <svg viewBox="0 0 16 16">
      <path
        fill="#010101"
        d="M15.299-0.017H9.267c-0.415,0-0.994,0.24-1.287,0.533L0.5,7.995c-0.293,0.294-0.293,0.774,0,1.066  l6.474,6.475c0.293,0.293,0.772,0.293,1.066,0l7.479-7.478c0.293-0.294,0.533-0.873,0.533-1.288V0.737  C16.053,0.323,15.713-0.017,15.299-0.017z M11.528,6.015c-0.833,0-1.508-0.675-1.508-1.508S10.695,3,11.528,3  s1.508,0.675,1.508,1.508S12.361,6.015,11.528,6.015z"
      />
      <g>
        <path
          fill="#FFFFFF"
          d="M6.944,8.472l0.591-0.213c-0.084-0.297-0.081-0.551,0.128-0.75c0.27-0.258,0.668-0.225,1.012,0.135   c0.048,0.05,0.093,0.104,0.132,0.158L9.33,7.613C9.258,7.498,9.172,7.384,9.068,7.276C8.472,6.652,7.707,6.61,7.2,7.094   C6.771,7.504,6.775,7.947,6.944,8.472z"
        />
        <path
          fill="#FFFFFF"
          d="M8.775,8.94c0.15,0.157,0.391,0.173,0.592-0.021c0.193-0.184,0.246-0.481,0.174-0.805L8.744,8.402   C8.624,8.547,8.609,8.768,8.775,8.94z"
        />
        <path
          fill="#FFFFFF"
          d="M7.724,8.77l-0.583,0.21c0.008,0.02,0.016,0.039,0.025,0.059c0.201,0.467,0.34,0.897,0.035,1.189   c-0.32,0.306-0.751,0.238-1.127-0.156c-0.185-0.192-0.306-0.4-0.359-0.579L5.201,9.677c0.086,0.256,0.242,0.521,0.471,0.761   c0.606,0.636,1.397,0.764,1.987,0.199C8.219,10.102,8.037,9.504,7.724,8.77z"
        />
        <path
          fill="#FFFFFF"
          d="M6.043,8.795c-0.019-0.059-0.051-0.117-0.101-0.17c-0.164-0.173-0.415-0.168-0.621,0.03   C5.205,8.767,5.138,8.933,5.122,9.127L6.043,8.795z"
        />
      </g>
      <path
        fill="#FFFFFF"
        d="M3.435,9.733l6.459-2.322l0.186,0.511l-6.464,2.325L3.435,9.733z"
      />
    </svg>
  ),
  RecommendedProducts: (
    <svg viewBox="0 0 16 16">
      <path
        fill="#010101"
        d="M15.299-0.017H9.267c-0.415,0-0.994,0.24-1.287,0.533L0.5,7.995c-0.293,0.294-0.293,0.774,0,1.066  l6.474,6.475c0.293,0.293,0.772,0.293,1.066,0l7.479-7.478c0.293-0.294,0.533-0.873,0.533-1.288V0.737  C16.053,0.323,15.713-0.017,15.299-0.017z M11.528,6.015c-0.833,0-1.508-0.675-1.508-1.508S10.695,3,11.528,3  s1.508,0.675,1.508,1.508S12.361,6.015,11.528,6.015z"
      />
      <g>
        <path
          fill="#FFFFFF"
          d="M6.944,8.472l0.591-0.213c-0.084-0.297-0.081-0.551,0.128-0.75c0.27-0.258,0.668-0.225,1.012,0.135   c0.048,0.05,0.093,0.104,0.132,0.158L9.33,7.613C9.258,7.498,9.172,7.384,9.068,7.276C8.472,6.652,7.707,6.61,7.2,7.094   C6.771,7.504,6.775,7.947,6.944,8.472z"
        />
        <path
          fill="#FFFFFF"
          d="M8.775,8.94c0.15,0.157,0.391,0.173,0.592-0.021c0.193-0.184,0.246-0.481,0.174-0.805L8.744,8.402   C8.624,8.547,8.609,8.768,8.775,8.94z"
        />
        <path
          fill="#FFFFFF"
          d="M7.724,8.77l-0.583,0.21c0.008,0.02,0.016,0.039,0.025,0.059c0.201,0.467,0.34,0.897,0.035,1.189   c-0.32,0.306-0.751,0.238-1.127-0.156c-0.185-0.192-0.306-0.4-0.359-0.579L5.201,9.677c0.086,0.256,0.242,0.521,0.471,0.761   c0.606,0.636,1.397,0.764,1.987,0.199C8.219,10.102,8.037,9.504,7.724,8.77z"
        />
        <path
          fill="#FFFFFF"
          d="M6.043,8.795c-0.019-0.059-0.051-0.117-0.101-0.17c-0.164-0.173-0.415-0.168-0.621,0.03   C5.205,8.767,5.138,8.933,5.122,9.127L6.043,8.795z"
        />
      </g>
      <path
        fill="#FFFFFF"
        d="M3.435,9.733l6.459-2.322l0.186,0.511l-6.464,2.325L3.435,9.733z"
      />
    </svg>
  ),
  GlobalRecommendedProducts: (
    <svg viewBox="0 0 16 16">
      <path
        fill="#33CC99"
        d="M15.299-0.017H9.267c-0.415,0-0.994,0.24-1.287,0.533L0.5,7.995c-0.293,0.294-0.293,0.774,0,1.066  l6.474,6.475c0.293,0.293,0.772,0.293,1.066,0l7.479-7.478c0.293-0.294,0.533-0.873,0.533-1.288V0.737  C16.053,0.323,15.713-0.017,15.299-0.017z M11.528,6.015c-0.833,0-1.508-0.675-1.508-1.508S10.695,3,11.528,3  s1.508,0.675,1.508,1.508S12.361,6.015,11.528,6.015z"
      />
      <g>
        <path
          fill="#FFFFFF"
          d="M6.944,8.472l0.591-0.213c-0.084-0.297-0.081-0.551,0.128-0.75c0.27-0.258,0.668-0.225,1.012,0.135   c0.048,0.05,0.093,0.104,0.132,0.158L9.33,7.613C9.258,7.498,9.172,7.384,9.068,7.276C8.472,6.652,7.707,6.61,7.2,7.094   C6.771,7.504,6.775,7.947,6.944,8.472z"
        />
        <path
          fill="#FFFFFF"
          d="M8.775,8.94c0.15,0.157,0.391,0.173,0.592-0.021c0.193-0.184,0.246-0.481,0.174-0.805L8.744,8.402   C8.624,8.547,8.609,8.768,8.775,8.94z"
        />
        <path
          fill="#FFFFFF"
          d="M7.724,8.77l-0.583,0.21c0.008,0.02,0.016,0.039,0.025,0.059c0.201,0.467,0.34,0.897,0.035,1.189   c-0.32,0.306-0.751,0.238-1.127-0.156c-0.185-0.192-0.306-0.4-0.359-0.579L5.201,9.677c0.086,0.256,0.242,0.521,0.471,0.761   c0.606,0.636,1.397,0.764,1.987,0.199C8.219,10.102,8.037,9.504,7.724,8.77z"
        />
        <path
          fill="#FFFFFF"
          d="M6.043,8.795c-0.019-0.059-0.051-0.117-0.101-0.17c-0.164-0.173-0.415-0.168-0.621,0.03   C5.205,8.767,5.138,8.933,5.122,9.127L6.043,8.795z"
        />
      </g>
      <path
        fill="#FFFFFF"
        d="M3.435,9.733l6.459-2.322l0.186,0.511l-6.464,2.325L3.435,9.733z"
      />
    </svg>
  ),
  LineItems: (
    <svg viewBox="0 0 32 32">
      <path d="M0 0h8v8h-8zM12 2h20v4h-20zM0 12h8v8h-8zM12 14h20v4h-20zM0 24h8v8h-8zM12 26h20v4h-20z" />
    </svg>
  ),
  AFLineItems: (
    <svg viewBox="0 0 32 32">
      <path d="M0 0h8v8h-8zM12 2h20v4h-20zM0 12h8v8h-8zM12 14h20v4h-20zM0 24h8v8h-8zM12 26h20v4h-20z" />
    </svg>
  ),
  ExchangeParentLineItems: (
    <svg viewBox="0 0 32 32">
      <path d="M0 0h8v8h-8zM12 2h20v4h-20zM0 12h8v8h-8zM12 14h20v4h-20zM0 24h8v8h-8zM12 26h20v4h-20z" />
    </svg>
  ),
  ExchangeChildLineItems: (
    <svg viewBox="0 0 32 32">
      <path d="M0 0h8v8h-8zM12 2h20v4h-20zM0 12h8v8h-8zM12 14h20v4h-20zM0 24h8v8h-8zM12 26h20v4h-20z" />
    </svg>
  ),
  Spacer: (
    <svg viewBox="0 0 16 16">
      <path d="M0 8h2v1H0zm3 0h3v1H3zm4 0h2v1H7zm3 0h3v1h-3zm4 0h2v1h-2zm-.25-8L14 7H2l.25-7h.5L3 6h10l.25-6zM2.25 16L2 10h12l-.25 6h-.5L13 11H3l-.25 5z" />
    </svg>
  ),
  FeaturedProduct: (
    <svg viewBox="0 0 16 16">
      <path d="M15.25 0h-6c-.412 0-.989.239-1.28.53L.531 7.969a.752.752 0 0 0 0 1.061l6.439 6.439a.752.752 0 0 0 1.061 0L15.47 8.03c.292-.292.53-.868.53-1.28v-6a.753.753 0 0 0-.75-.75zM11.5 6a1.5 1.5 0 1 1 .001-3.001A1.5 1.5 0 0 1 11.5 6z" />
    </svg>
  ),
  FeaturedCollection: (
    <svg viewBox="0 0 20 16">
      <path d="M19.25 0h-6c-.412 0-.989.239-1.28.53L4.531 7.969a.752.752 0 0 0 0 1.061l6.439 6.439a.752.752 0 0 0 1.061 0L19.47 8.03c.292-.292.53-.868.53-1.28v-6a.752.752 0 0 0-.75-.75zM15.5 6a1.5 1.5 0 1 1 .001-3.001A1.5 1.5 0 0 1 15.5 6z" />
      <path d="M2 8.5L10.5 0H9.25c-.412 0-.989.239-1.28.53L.531 7.969a.752.752 0 0 0 0 1.061l6.439 6.439a.752.752 0 0 0 1.061 0l.47-.47-6.5-6.5z" />
    </svg>
  ),
  Feedback: (
    <svg viewBox="0 0 32 32">
      <path d="M16 32c8.837 0 16-7.163 16-16s-7.163-16-16-16-16 7.163-16 16 7.163 16 16 16zM16 3c7.18 0 13 5.82 13 13s-5.82 13-13 13-13-5.82-13-13 5.82-13 13-13zM8 10c0-1.105 0.895-2 2-2s2 0.895 2 2c0 1.105-0.895 2-2 2s-2-0.895-2-2zM20 10c0-1.105 0.895-2 2-2s2 0.895 2 2c0 1.105-0.895 2-2 2s-2-0.895-2-2zM22.003 19.602l2.573 1.544c-1.749 2.908-4.935 4.855-8.576 4.855s-6.827-1.946-8.576-4.855l2.573-1.544c1.224 2.036 3.454 3.398 6.003 3.398s4.779-1.362 6.003-3.398z" />
    </svg>
  ),
  Footer: (
    <svg viewBox="0 0 16 16">
      <path d="M10 4V0H3L0 3v9h6v4h10V4h-6zM3 1.414V3H1.414L3 1.414zM1 11V4h3V1h5v3L6 7v4H1zm8-5.586V7H7.414L9 5.414zM15 15H7V8h3V5h5v10z" />
    </svg>
  ),
  Header: (
    <svg viewBox="0 0 16 16">
      <path d="M10 4V0H3L0 3v9h6v4h10V4h-6zM3 1.414V3H1.414L3 1.414zM1 11V4h3V1h5v3L6 7v4H1zm8-5.586V7H7.414L9 5.414zM15 15H7V8h3V5h5v10z" />
    </svg>
  ),
  Referral: (
    <svg viewBox="0 0 16 16">
      <path d="M16 6.707C16 3.568 15.081 1.02 13.946 1h.014-1.296S9.62 3.287 5.239 4.184c-.134.708-.219 1.551-.219 2.523s.085 1.816.219 2.523c4.382.897 7.425 3.184 7.425 3.184h1.296l-.014-.001C15.082 12.393 16 9.846 16 6.706zm-2.487 4.844c-.147 0-.305-.152-.387-.243-.197-.22-.387-.562-.55-.989-.363-.957-.564-2.239-.564-3.611s.2-2.655.564-3.611c.162-.428.353-.77.55-.99.081-.091.24-.243.387-.243s.305.152.387.243c.197.22.387.562.55.99.363.957.564 2.239.564 3.611s-.2 2.655-.564 3.611c-.162.428-.353.77-.55.989-.081.091-.24.243-.387.243zM3.935 6.707c0-.812.06-1.6.173-2.33-.74.102-1.39.161-2.193.161H.867L0 6.017v1.378l.867 1.479h1.048c.803 0 1.453.059 2.193.161a15.326 15.326 0 0 1-.173-2.33zm1.817 3.327l-2-.383 1.279 5.024c.066.26.324.391.573.291l1.852-.741a.427.427 0 0 0 .222-.611l-1.926-3.581zm7.761-1.46c-.057 0-.118-.059-.149-.094a1.268 1.268 0 0 1-.212-.381c-.14-.369-.217-.863-.217-1.392s.077-1.023.217-1.392c.063-.165.136-.297.212-.381.031-.035.092-.094.149-.094s.118.059.149.094c.076.085.149.217.212.381.14.369.217.863.217 1.392s-.077 1.023-.217 1.392a1.243 1.243 0 0 1-.212.381c-.031.035-.092.094-.149.094z" />
    </svg>
  ),
  Limespot: (
    <svg viewBox="0 0 300 300">
      <g>
        <path
          fill="#090909"
          d="M144,187.65l.05-31.7V148.5c15.79,3.73,70.21,16.57,78.71,18.54,7,1.62,7,4.46,7,6.53,0,2.65-.85,5.31-7,6.78l-54.76,12.89Z"
        />
        <path
          fill="#090909"
          d="M150.61,242.76c-3.35,0-4.5-1-5.08-1.7-1-1.29-1.54-3.66-1.54-7.06V205.75c26.09-6.08,80-18.67,80.55-18.81a27.41,27.41,0,0,0,5.27-1.93l0,34.6c0,3.69-3.36,5.83-5.62,6.38l-58.93,14-4.85,1.14-2.67.63a33.31,33.31,0,0,1-7.09,1Z"
        />
        <path
          fill="#090909"
          d="M82.76,173.16c-4.61-1.09-6.94-3.07-6.94-5.88s2.46-4.92,6.93-6L92.21,159v16.38Z"
        />
        <path
          fill="#090909"
          d="M99.15,57.18,129.9,80.53a18.64,18.64,0,0,1,7.16,14.55L99.15,104Z"
        />
        <path
          fill="#090909"
          d="M103.67,209.78a12.43,12.43,0,0,1-4.49-8.41v-44l37.95-8.92-.1,86.38Z"
        />
        <path
          fill="#090909"
          d="M75.6,122.51c0-4.74,2.29-6.22,6.81-7.4l123.22-29v27.25c0,4.92-2.47,11.51-14.22,15-4.6,1.36-55.25,13.23-85.51,20.32-14,3.27-24,5.64-25.2,5.93a19.68,19.68,0,0,0-4.94,2C75.7,143.41,75.6,122.89,75.6,122.51Z"
        />
        <path
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="11.91px"
          d="M35.2,85.83V229.27a38.39,38.39,0,0,0,38.39,38.39H230.45a38.39,38.39,0,0,0,38.39-38.39V72.41A38.39,38.39,0,0,0,230.45,34H76.77L99.16,14.74"
        />
      </g>
    </svg>
  ),
  CustomerInfo: (
    <svg viewBox="0 0 32 32">
      <path d="M0 2h32v4h-32zM0 8h32v4h-32zM0 14h32v4h-32zM0 20h32v4h-32zM0 26h32v4h-32z" />
    </svg>
  ),
  Totals: (
    <svg viewBox="0 0 16 16">
      <path d="M0 3v11h16V3H0zm6 7V8h4v2H6zm4 1v2H6v-2h4zm0-6v2H6V5h4zM5 5v2H1V5h4zM1 8h4v2H1V8zm10 0h4v2h-4V8zm0-1V5h4v2h-4zM1 11h4v2H1v-2zm10 2v-2h4v2h-4z" />
    </svg>
  ),
  ReturnTotals: (
    <svg viewBox="0 0 16 16">
      <path d="M0 3v11h16V3H0zm6 7V8h4v2H6zm4 1v2H6v-2h4zm0-6v2H6V5h4zM5 5v2H1V5h4zM1 8h4v2H1V8zm10 0h4v2h-4V8zm0-1V5h4v2h-4zM1 11h4v2H1v-2zm10 2v-2h4v2h-4z" />
    </svg>
  ),
  SubscriptionTotals: (
    <svg viewBox="0 0 16 16">
      <path d="M0 3v11h16V3H0zm6 7V8h4v2H6zm4 1v2H6v-2h4zm0-6v2H6V5h4zM5 5v2H1V5h4zM1 8h4v2H1V8zm10 0h4v2h-4V8zm0-1V5h4v2h-4zM1 11h4v2H1v-2zm10 2v-2h4v2h-4z" />
    </svg>
  ),
  InvoiceTotals: (
    <svg viewBox="0 0 16 16">
      <path d="M0 3v11h16V3H0zm6 7V8h4v2H6zm4 1v2H6v-2h4zm0-6v2H6V5h4zM5 5v2H1V5h4zM1 8h4v2H1V8zm10 0h4v2h-4V8zm0-1V5h4v2h-4zM1 11h4v2H1v-2zm10 2v-2h4v2h-4z" />
    </svg>
  ),
  ExchangeParentTotals: (
    <svg viewBox="0 0 16 16">
      <path d="M0 3v11h16V3H0zm6 7V8h4v2H6zm4 1v2H6v-2h4zm0-6v2H6V5h4zM5 5v2H1V5h4zM1 8h4v2H1V8zm10 0h4v2h-4V8zm0-1V5h4v2h-4zM1 11h4v2H1v-2zm10 2v-2h4v2h-4z" />
    </svg>
  ),
  ExchangeChildTotals: (
    <svg viewBox="0 0 16 16">
      <path d="M0 3v11h16V3H0zm6 7V8h4v2H6zm4 1v2H6v-2h4zm0-6v2H6V5h4zM5 5v2H1V5h4zM1 8h4v2H1V8zm10 0h4v2h-4V8zm0-1V5h4v2h-4zM1 11h4v2H1v-2zm10 2v-2h4v2h-4z" />
    </svg>
  ),
  TemplateFooter: (
    <svg viewBox="0 0 32 32">
      <path d="M22 16v12h2v-12h5l-6-6-6 6z" />
      <path d="M2 6h3v2h-3v-2z" />
      <path d="M6 6h3v2h-3v-2z" />
      <path d="M10 6h2v3h-2v-3z" />
      <path d="M2 13h2v3h-2v-3z" />
      <path d="M5 14h3v2h-3v-2z" />
      <path d="M9 14h3v2h-3v-2z" />
      <path d="M2 9h2v3h-2v-3z" />
      <path d="M10 10h2v3h-2v-3z" />
      <path d="M10 22v6h-6v-6h6zM12 20h-10v10h10v-10z" />
    </svg>
  ),
  TemplateHeader: (
    <svg viewBox="0 0 32 32">
      <path d="M24 22v-12h-2v12h-5l6 6 6-6z" />
      <path d="M10 8v6h-6v-6h6zM12 6h-10v10h10v-10z" />
      <path d="M2 20h3v2h-3v-2z" />
      <path d="M6 20h3v2h-3v-2z" />
      <path d="M10 20h2v3h-2v-3z" />
      <path d="M2 27h2v3h-2v-3z" />
      <path d="M5 28h3v2h-3v-2z" />
      <path d="M9 28h3v2h-3v-2z" />
      <path d="M2 23h2v3h-2v-3z" />
      <path d="M10 24h2v3h-2v-3z" />
    </svg>
  ),
  Color: (
    <svg viewBox="0 0 32 32">
      <path d="M30.828 1.172c-1.562-1.562-4.095-1.562-5.657 0l-5.379 5.379-3.793-3.793-4.243 4.243 3.326 3.326-14.754 14.754c-0.252 0.252-0.358 0.592-0.322 0.921h-0.008v5c0 0.552 0.448 1 1 1h5c0 0 0.083 0 0.125 0 0.288 0 0.576-0.11 0.795-0.329l14.754-14.754 3.326 3.326 4.243-4.243-3.793-3.793 5.379-5.379c1.562-1.562 1.562-4.095 0-5.657zM5.409 30h-3.409v-3.409l14.674-14.674 3.409 3.409-14.674 14.674z" />
    </svg>
  ),
  Navbar: (
    <svg
      height="682pt"
      viewBox="-21 -22 682.66623 682"
      width="682pt"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m479.984375 615.234375h-319.972656c-12.621094 0-22.855469 10.234375-22.855469 22.859375h365.683594c0-12.625-10.226563-22.859375-22.855469-22.859375zm0 0" />
      <path d="m308.574219 386.683594c0-6.3125 5.117187-11.429688 11.425781-11.429688 6.3125 0 11.429688 5.117188 11.429688 11.429688v205.699218h22.851562v-559.957031c-.058594-14.476562-9.207031-27.351562-22.851562-32.179687v123.605468c0 6.3125-5.117188 11.421876-11.429688 11.421876-6.308594 0-11.425781-5.109376-11.425781-11.421876v-123.605468c-13.652344 4.828125-22.796875 17.703125-22.855469 32.179687v559.957031h22.855469zm0 0" />
      <path d="m262.859375 78.140625h-170.140625c-12.335938.027344-24.140625 5.007813-32.765625 13.828125l-57.515625 59.074219c-3.402344 4.34375-3.222656 10.488281.421875 14.621093l57.140625 58.636719c8.613281 8.804688 20.398438 13.78125 32.71875 13.828125h170.140625zm-34.277344 137.128906h-137.132812c-6.316407 0-11.429688-5.113281-11.429688-11.425781s5.113281-11.429688 11.429688-11.429688h137.132812c6.304688 0 11.421875 5.117188 11.421875 11.429688s-5.117187 11.425781-11.421875 11.425781zm0-45.710937h-137.132812c-6.316407 0-11.429688-5.117188-11.429688-11.425782 0-6.3125 5.113281-11.429687 11.429688-11.429687h137.132812c6.304688 0 11.421875 5.117187 11.421875 11.429687 0 6.308594-5.117187 11.425782-11.421875 11.425782zm0-45.707032h-137.132812c-6.316407 0-11.429688-5.117187-11.429688-11.429687 0-6.308594 5.113281-11.425781 11.429688-11.425781h137.132812c6.304688 0 11.421875 5.117187 11.421875 11.425781 0 6.3125-5.117187 11.429687-11.421875 11.429687zm0 0" />
      <path d="m637.140625 333.441406-57.136719-58.632812c-8.613281-8.800782-20.402344-13.789063-32.71875-13.828125h-170.148437v159.988281h170.148437c12.332032-.027344 24.136719-5.011719 32.761719-13.828125l57.515625-59.082031c3.402344-4.332032 3.226562-10.480469-.421875-14.617188zm-88.585937 64.671875h-137.132813c-6.3125 0-11.429687-5.117187-11.429687-11.429687s5.117187-11.429688 11.429687-11.429688h137.132813c6.308593 0 11.425781 5.117188 11.425781 11.429688s-5.117188 11.429687-11.425781 11.429687zm0-45.710937h-137.132813c-6.3125 0-11.429687-5.117188-11.429687-11.425782 0-6.3125 5.117187-11.429687 11.429687-11.429687h137.132813c6.308593 0 11.425781 5.117187 11.425781 11.429687 0 6.308594-5.117188 11.425782-11.425781 11.425782zm0-45.710938h-137.132813c-6.3125 0-11.429687-5.117187-11.429687-11.429687 0-6.308594 5.117187-11.425781 11.429687-11.425781h137.132813c6.308593 0 11.425781 5.117187 11.425781 11.425781 0 6.3125-5.117188 11.429687-11.425781 11.429687zm0 0" />
    </svg>
  ),
  Tracktor: (
    <svg viewBox="0 0 60 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M59.8687 14.7222C59.9995 14.5833 59.9995 14.3056 59.8687 14.1667C59.8687 14.0278 59.738 14.0278 59.738 13.8889L53.2021 6.94444C52.9406 6.66667 52.5485 6.66667 52.287 6.94444C52.0256 7.22222 52.0256 7.63889 52.287 7.91667L57.6465 13.6111H46.274V0.694444C46.4047 0.277778 46.1432 0 45.7511 0H19.8687C19.738 0 19.4766 0.138889 19.3459 0.277778L13.4635 6.52778C13.4635 6.52778 13.3328 6.66667 13.3328 6.80556C13.3328 6.94444 13.3328 6.94444 13.3328 7.08333V20.1389H2.22168L7.58116 14.4444C7.8426 14.0278 7.8426 13.4722 7.58116 13.3333C7.31972 13.1944 6.92756 13.0556 6.66612 13.3333L0.130177 20.2778C-0.000541928 20.4167 -0.131261 20.6944 -0.000541933 20.9722C-0.000541933 21.1111 0.130177 21.1111 0.130177 21.25L6.66612 28.1944C6.79684 28.3333 6.92756 28.3333 7.189 28.4722C7.31972 28.4722 7.58116 28.3333 7.71188 28.1944C7.97331 27.9167 7.97331 27.5 7.71188 27.2222L2.3524 21.3889H13.5942V34.4444C13.5942 34.8611 13.8557 35.1389 14.2478 35.1389H40.1302C40.2609 35.1389 40.2609 35.1389 40.3916 35.1389C40.5223 35.1389 40.5223 35 40.6531 35L46.5354 28.75C46.6661 28.6111 46.7968 28.4722 46.7968 28.1944V15.1389H58.1694L52.4178 20.8333C52.1563 21.1111 52.1563 21.5278 52.4178 21.8056C52.6792 22.0833 53.0714 22.0833 53.3328 21.8056L59.8687 14.8611C59.8687 14.8611 59.8687 14.7222 59.8687 14.7222ZM14.7707 21.3889H27.0583C27.4504 21.3889 27.7119 21.1111 27.7119 20.6944C27.7119 20.2778 27.4504 20 27.0583 20H14.7707V7.63889H39.2151V33.6111H14.7707V21.3889ZM45.0975 27.9167L40.5223 32.6389V6.94444C40.5223 6.52778 40.2609 6.25 39.8687 6.25H15.6857L20.1302 1.38889H44.9668L45.0975 27.9167Z"
          fill="black"
          stroke="black"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="60" height="35" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  Conditional: (
    <svg
      width="21"
      height="19"
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2.46718" cy="16.2715" r="2.46718" fill="#5C5F62" />
      <circle cx="17.5722" cy="16.2715" r="2.46718" fill="#5C5F62" />
      <path
        d="M2.51758 14.8978V12.2786C2.51758 11.3516 3.269 10.6002 4.19593 10.6002H15.7156C16.6425 10.6002 17.3939 11.3516 17.3939 12.2786V14.8978"
        stroke="#5C5F62"
        strokeWidth="1.5"
      />
      <circle cx="10.0199" cy="2.46724" r="2.46718" fill="#5C5F62" />
      <path d="M10.019 4.64965V10.9308" stroke="#5C5F62" strokeWidth="1.5" />
    </svg>
  ),
  DeprecatedComponent: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="27"
      viewBox="3 4 21 16"
    >
      <path
        fillRule="evenodd"
        d="M10 7.25c-.69 0-1.25.56-1.25 1.25a.75.75 0 0 1-1.5 0 2.75 2.75 0 1 1 3.758 2.56.61.61 0 0 0-.226.147.154.154 0 0 0-.032.046.75.75 0 0 1-1.5-.003c0-.865.696-1.385 1.208-1.586a1.25 1.25 0 0 0-.458-2.414Z"
      />
      <path d="M10 14.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" />
      <path
        fillRule="evenodd"
        d="M10 17a7 7 0 1 0 0-14 7 7 0 0 0 0 14Zm0-1.5a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11Z"
      />
    </svg>
  ),
  BillingAddress: (
    <svg viewBox="0 0 32 32">
      <path d="M0 2h32v4h-32zM0 8h32v4h-32zM0 14h32v4h-32zM0 20h32v4h-32zM0 26h32v4h-32z" />
    </svg>
  ),
  TransactionDetail: (
    <svg viewBox="0 0 32 32">
      <path d="M0 2h32v4h-32zM0 8h32v4h-32zM0 14h32v4h-32zM0 20h32v4h-32zM0 26h32v4h-32z" />
    </svg>
  ),
  ShippingAddress: (
    <svg viewBox="0 0 32 32">
      <path d="M0 2h32v4h-32zM0 8h32v4h-32zM0 14h32v4h-32zM0 20h32v4h-32zM0 26h32v4h-32z" />
    </svg>
  ),
  ShippingMethod: (
    <svg viewBox="0 0 32 32">
      <path d="M0 2h32v4h-32zM0 8h32v4h-32zM0 14h32v4h-32zM0 20h32v4h-32zM0 26h32v4h-32z" />
    </svg>
  ),
};
