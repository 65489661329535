import { add } from 'date-fns';
import React, { useState } from 'react';

const DateRangeContext = React.createContext({});

const DateRangeProvider = ({ children }) => {
  const saveRange = JSON.parse(sessionStorage.getItem('dateRange')) || {
    start: add(new Date(), { days: -90 }),
    end: new Date(),
  };

  saveRange.start = new Date(saveRange.start);
  saveRange.end = new Date(saveRange.end);
  const [range, setRange] = useState(saveRange);

  return (
    <DateRangeContext.Provider
      value={[
        range,
        (r) => {
          sessionStorage.setItem('dateRange', JSON.stringify(r));
          setRange(r);
        },
      ]}
    >
      {children}
    </DateRangeContext.Provider>
  );
};

export { DateRangeContext, DateRangeProvider };
