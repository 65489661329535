import Config from '../Config';

// Get the Google Analytics tracking ID from the configuration
const trackingId = Config.spently.googleAnalytics.tracking_id;
const GoogleAnalyticsEvent = {
  NEW_TEMPLATE_SET: 'new_template_set',
  PUPBLISH: 'publish_template',
  START_TRAIL: 'start_trail',
};

// Function to send a Google Analytics event
const sendGoogleAnalyticsEvent = (eventName, eventDetail) => {
  if (window.gtag) {
    window.gtag('event', eventName, eventDetail);
  }
};

// Function to insert the data layer update script
const insertDataLayerUpdateScript = () => {
  // Create and execute the configuration script
  const configScript = document.createElement('script');
  // get and send store name to google analytics
  const storeName = window.sessionStorage['spently-shop'];
  configScript.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${trackingId}',{
      'user_id':'${storeName}'
    });
  `;

  document.head.appendChild(configScript);
};

// Function to insert the Google Analytics script
const insertGoogleAnalyticsScript = () => {
  // Create script element
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
  // push config to datalayer after script loaded
  script.onload = insertDataLayerUpdateScript;

  // Append script to head
  document.head.appendChild(script);
};

// Function to attach the Google Analytics script to the window's onload event
const attachGoogleAnalyticsScript = () => {
  if (window.attachEvent) {
    // For older versions of Internet Explorer
    window.attachEvent('onload', insertGoogleAnalyticsScript);
  } else {
    // For modern browsers
    window.addEventListener('load', insertGoogleAnalyticsScript, false);
  }
};

export {
  attachGoogleAnalyticsScript,
  sendGoogleAnalyticsEvent,
  GoogleAnalyticsEvent,
};
