import {
  DataTable,
  Stack,
  Pagination,
  Spinner,
  EmptyState,
  DisplayText,
} from '@shopify/polaris';
import { SearchMajor } from '@shopify/polaris-icons';
import React, { useState, useEffect, useCallback } from 'react';
import theme from '../util/theme';

const PanTable = ({
  url,
  headings,
  types,
  mapper,
  get,
  emptyWarningMessage,
}) => {
  const [state, setState] = useState({
    data: null,
    currentPage: 1,
    pageCount: 1,
  });
  const [loading, setLoading] = useState(true);
  const pageSize = 25;

  const loadData = useCallback(
    async (newPage) => {
      setLoading(true);
      // setState({ ...state, loading: true });
      const p = newPage || state.currentPage;
      const response = await get(`${url}&page=${p}&pageSize=${pageSize}`);
      const mapped = mapper(response);
      setState({
        data: mapped.data,
        currentPage: p,
        pageCount: mapped.pageCount,
      });
      setLoading(false);
    },
    [get, mapper, state, url]
  );

  useEffect(() => {
    if (!state.data) {
      loadData();
    }
  }, [state, loadData]);

  if (loading) {
    return (
      <center>
        <DataTable columnContentTypes={types} headings={headings} rows={[]} />
        <div style={{ paddingTop: 32, paddingBottom: 32 }}>
          <Spinner />
        </div>
      </center>
    );
  }

  if (state.data.length === 0) {
    const { colors } = theme();
    return (
      <EmptyState>
        <SearchMajor
          style={{
            width: 120,
            fill: colors.primary,
            paddingTop: 32,
            paddingBottom: 32,
          }}
        />
        <DisplayText size="small">No Records Found</DisplayText>
        <p>{emptyWarningMessage}</p>
      </EmptyState>
    );
  }

  return (
    <DataTable
      columnContentTypes={types}
      headings={headings}
      rows={state.data}
      footerContent={
        <Stack distribution="center">
          <Pagination
            center
            label={`Page ${state.currentPage} of ${state.pageCount}`}
            hasNext={state.currentPage !== state.pageCount}
            hasPrevious={state.currentPage > 1}
            onNext={() => loadData(state.currentPage + 1)}
            onPrevious={() => loadData(state.currentPage - 1)}
          />
        </Stack>
      }
    />
  );
};

export default PanTable;
