import {
  Card,
  Layout,
  Page,
  Stack,
  RangeSlider,
  DisplayText,
  TextStyle,
  Loading,
  SkeletonDisplayText,
  SkeletonBodyText,
} from '@shopify/polaris';
import React, { useEffect, useState } from 'react';
import PanTable from '../framework/ui/PanTable';
import { formatCurrency, formatDate } from '../framework/util/formatters';
import { getSpently } from '../util/spentlyRest';

const Usage = () => {
  const [billing, setBilling] = useState(null);

  useEffect(() => {
    const loadBilling = async () => {
      const billResponse = await getSpently('/api/account/billing');
      setBilling(billResponse);
    };

    if (!billing) {
      loadBilling();
    }
  }, [billing, setBilling]);

  const renderTop = () => (
    <>
      {billing && (
        <RangeSlider
          label={`${billing.orderNumber} order(s)`}
          min={0}
          max={5000}
          value={billing.orderNumber}
        />
      )}
      {!billing && (
        <>
          <div style={{ paddingBottom: 26 }} />
          <SkeletonBodyText lines={1} />
        </>
      )}
      <Stack distribution="equalSpacing" spacing="extraLoose">
        <p>0</p>
        <p>2500</p>
        <p>5000</p>
      </Stack>

      <Stack vertical alignment="center">
        <br />
        <Stack distribution="center">
          <DisplayText size="small">Plan resets:</DisplayText>
          {billing && (
            <DisplayText size="small">
              {formatDate(billing.resetDate)}
            </DisplayText>
          )}
          {!billing && (
            <div style={{ width: 100 }}>
              <SkeletonDisplayText size="small" />
            </div>
          )}
        </Stack>
        <DisplayText size="small">Revenue from UpOrder this cycle</DisplayText>
        {billing && (
          <DisplayText size="extraLarge">
            <TextStyle variation="positive">${billing.revenue}</TextStyle>
          </DisplayText>
        )}
        {!billing && (
          <div style={{ width: 100 }}>
            <SkeletonDisplayText size="extraLarge" />
          </div>
        )}
      </Stack>
    </>
  );

  const mapper = (records) => ({
    data: records.usageCharges.map((uc) => [
      formatDate(uc.CreatedAt),
      formatCurrency(uc.Price),
      `${formatDate(uc.CycleStart)} to ${formatDate(uc.CycleEnd)}`,
      uc.Description,
    ]),
    pageCount: records.pages,
  });

  return (
    <>
      {!billing && <Loading />}
      <Page title="Usage &amp; Plans">
        <Layout>
          <Layout.Section>
            <Card title="Current Billing Tier" sectioned>
              <div style={{ height: 230 }}>{renderTop()}</div>
            </Card>
          </Layout.Section>
          <Layout.Section secondary>
            <Card title="Analytics" sectioned>
              <div style={{ height: 230 }}>
                <h5>Want to know how well your emails are performing?</h5>
                <br />
                <p>
                  Head over to the new analytics page and find out how each of
                  your emails is performing and get a more in-depth view of
                  where your revenue is coming from.
                </p>
              </div>
            </Card>
          </Layout.Section>
          <Layout.Section fullWidth>
            <Card title="Billing History" sectioned>
              <PanTable
                url="/api/account/billing/history?"
                get={getSpently}
                types={['text', 'text', 'text', 'text']}
                headings={[
                  'Bill Date',
                  'Amount',
                  'Billing Cycle',
                  'Description',
                ]}
                mapper={mapper}
              />
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </>
  );
};

export default Usage;
