/* eslint-disable no-underscore-dangle */
/* eslint-disable max-lines */
import React, { useEffect, useState, useContext, useRef } from 'react';
import {
  Frame,
  Stack,
  Page,
  Scrollable,
  Loading,
  Toast,
} from '@shopify/polaris';
import { getSpently } from '../util/spentlyRest';
import BuilderTopBar from '../widgets/BuilderTopBar';
import ComponentTree from '../widgets/builder/ComponentTree';
import OptionsSideBar from '../widgets/builder/sidebars/OptionsSidebar';
import TipsSidebar from '../widgets/builder/sidebars/TipsSidebar';
import ThemeSidebar from '../widgets/builder/sidebars/ThemeSidebar';
import RenderedComponent from '../widgets/builder/RenderedComponent';
import { TemplateContext } from '../state/TemplateContext';
import HeaderSidebar from '../widgets/builder/sidebars/HeaderSidebar';
import FooterSidebar from '../widgets/builder/sidebars/FooterSidebar';
import SavedModal from '../widgets/builder/SavedModal';
import { BaseComponentContext } from '../state/BaseComponentContext';
import ImageSidebar from '../widgets/builder/sidebars/ImageSidebar';
import MagicModal from '../widgets/builder/MagicModal';
import ErrorModal from '../widgets/builder/modals/ErrorModal';
import OptionsContext from '../state/OptionsContext';
import {
  sendGoogleAnalyticsEvent,
  GoogleAnalyticsEvent,
} from '../widgets/googleAnalytics';
import { sendIntercomEvent, IntercomEvent } from '../widgets/intercom';

const Builder = () => {
  const [device, setDevice] = useState('desktop');
  const [components] = useContext(BaseComponentContext);
  const [saveActive, setSaveActive] = useState(false);
  const [toastDismissed, setToastDismissed] = useState(false);
  const [currentSelected, setCurrentSelected] = useState({});
  const [dropdown, setDropdown] = useState([]);
  const [prods, setProds] = useState(null);
  const [filter, setFilter] = useState([]);
  const [searchTrigger, setSearchTrigger] = useState(false);
  const [productError, setProductError] = useState(false);
  const [customCursor, setCustomCursor] = useState([]);
  const [smartCursor, setSmartCursor] = useState([]);
  const [disabled, setDisable] = useState(false);
  const [template, updateTemplate, setTemplate] = useContext(TemplateContext);
  const [displayOptions, setDisplayOptions] = useState({
    themeVisible: false,
    showAdvanced: false,
    showImages: false,
    showGlobal: false,
  });
  const [errorState, setIsErrorState] = useState({
    isError: false,
    exception: null,
  });
  const query = new URLSearchParams(window.location.search);
  const set = query.get('set') || '';
  const templateId = query.get('template') || query.get('templateId');
  const currentTemplate = useRef();

  // new template set, send google analytics event
  if (set === 'true' && template) {
    sendGoogleAnalyticsEvent(GoogleAnalyticsEvent.NEW_TEMPLATE_SET, {});
  }

  useEffect(() => {
    // always update currentTemplate when template change
    currentTemplate.current = template;

    // make currentSelected and currentTemplate.current consistent to update on template change
    if (
      currentTemplate.current &&
      currentTemplate.current.templateId !== template.templateId
    ) {
      // when template change, reset currentSelected
      setCurrentSelected({});

      currentTemplate.current = template;
    }
  }, [template]);

  useEffect(() => {
    const loadTemplate = async () => {
      const qs = set ? `setId=${set}` : `afId=${query.get('afId')}`;
      const temp = await getSpently(
        `/api/template?${qs}&templateId=${templateId}`
      );

      // send VISITED_TEMPLATEBUILDER event to intercom on builder page template loaded
      sendIntercomEvent(IntercomEvent.VISITED_TEMPLATEBUILDER);
      if (components) {
        setTemplate(temp, components);
      }
    };
    if (!template) {
      loadTemplate();
    }
  });
  const warnUser = (e) => {
    if (template.updates.length > 0) {
      e.preventDefault();
      e.returnValue = '';
    }
  };
  useEffect(() => {
    window.addEventListener('beforeunload', warnUser);
    return () => {
      window.removeEventListener('beforeunload', warnUser);
    };
  });
  const componentChange = (key) => {
    try {
      const parts = key.split('-');
      const currentComponent = components.find((c) => c.key === parts[0]);
      const options = template.components[parts[1]];
      const child =
        !!parts[2] &&
        components.find(
          (c) =>
            c.key ===
            (options.columns[parts[2]].id
              ? options.columns[parts[2]].id
              : options.columns[parts[2]]._id) // some id is store with _id
        );
      let showGlobal = false;
      if (options) {
        showGlobal =
          options && parts[2]
            ? options.columns[parts[2]][child.global]
            : options[currentComponent.global];
      }
      setDisplayOptions({
        themeVisible: false,
        showAdvanced: false,
        showImages: false,
        showGlobal,
      });
      setCurrentSelected({ name: parts[0], key: parts[1], child: parts[2] });
      setTimeout(
        () => document.getElementById('selected-component').scrollIntoView(),
        100
      );
    } catch (error) {
      setIsErrorState({ isError: true, error });
    }
  };

  const styleSettings =
    template && template.options && template.options.Settings
      ? template.options.Settings
      : {};
  const containerStyle = {
    width: device === 'mobile' ? 320 : styleSettings.width,
    margin: '0 auto',
    marginBottom: `${styleSettings.paddingBottom}px`,
    padding: `${styleSettings.paddingVert}px ${styleSettings.paddingHoriz}px`,
    backgroundColor: styleSettings.emailColor,
  };

  const currentComponent =
    components && components.find((c) => c.key === currentSelected.name);
  const handleOptionChange = (name, oldVal, newVal, overrideKey) => {
    updateTemplate(name, oldVal, newVal, overrideKey || currentSelected.key);
  };

  const renderSidebar = () => {
    try {
      if (device === 'wide') {
        return null;
      }
      if (displayOptions.showImages) {
        return <ImageSidebar updateImage={displayOptions.showImages} />;
      }
      if (displayOptions.themeVisible) {
        return (
          <ThemeSidebar
            clearSelected={() =>
              setDisplayOptions({ ...displayOptions, themeVisible: false })
            }
            handleChange={handleOptionChange}
            options={template.options}
          />
        );
      }
      if (currentSelected && currentSelected.name === 'Header') {
        return <HeaderSidebar setDisplayOptions={setDisplayOptions} />;
      }
      if (currentSelected && currentSelected.name === 'Footer') {
        return <FooterSidebar setDisplayOptions={setDisplayOptions} />;
      }

      if (currentComponent && currentTemplate.current) {
        return (
          <OptionsContext.Provider
            value={{
              dropdown,
              setDropdown,
              customCursor,
              setCustomCursor,
              smartCursor,
              setSmartCursor,
              disabled,
              setDisable,
              prods,
              setProds,
              searchTrigger,
              setSearchTrigger,
              productError,
              setProductError,
              filter,
              setFilter,
            }}
          >
            <OptionsSideBar
              key={
                currentSelected.name +
                currentSelected.key +
                currentSelected.child
              }
              components={components}
              displayOptions={displayOptions}
              setDisplayOptions={setDisplayOptions}
              clearSelected={() => {
                setCurrentSelected({});
                setDisplayOptions({ ...displayOptions, themeVisible: false });
              }}
              handleChange={handleOptionChange}
              currentSelected={currentSelected}
              currentSelectedComponent={
                currentTemplate.current.components[currentSelected.key]
              }
            />
          </OptionsContext.Provider>
        );
      }
      return <TipsSidebar />;
    } catch (error) {
      setIsErrorState({ isError: true, error });
      return null;
    }
  };
  const renderTopBar = () => {
    try {
      return (
        <BuilderTopBar
          device={device}
          onAfterSave={() => setSaveActive(true)}
          setDevice={setDevice}
          copmonents={components}
          type={template ? template.templateId : null}
        />
      );
    } catch (error) {
      setIsErrorState({ isError: true, error });
      return null;
    }
  };

  if (errorState.isError) {
    return <ErrorModal error={errorState.error} />;
  }
  return (
    <div className="spently-builder">
      <Frame topBar={renderTopBar()}>
        {(!template || !components) && <Loading />}
        <Page fullWidth style={{ paddingLeft: 0 }}>
          <Stack vertical={false}>
            {device !== 'wide' && (
              <ComponentTree
                onSelect={componentChange}
                selectedKey={currentSelected.key}
                onThemeSettings={() => {
                  setDisplayOptions({
                    ...displayOptions,
                    themeVisible: true,
                  });
                  setCurrentSelected({});
                }}
                setDisplayOptions={setDisplayOptions}
              />
            )}
            <Stack.Item fill>
              {template && components && (
                <Scrollable
                  shadow
                  style={{
                    marginTop: -8,
                    height: 'calc(100vh - 4rem)',
                    backgroundColor: styleSettings.backgroundColor,
                    paddingTop: `${styleSettings.paddingTop}px`,
                    paddingBottom: `${styleSettings.paddingBottom}px`,
                  }}
                >
                  <div style={containerStyle}>
                    <RenderedComponent
                      componentName="Header"
                      renderKey="Header-999"
                      componentChange={componentChange}
                      component={{
                        navBar: template.options.NavigationBar,
                        navLinks: styleSettings.NavLinks
                          ? template.options.Settings.NavLinks.map((data) => ({
                              label: data.text,
                              value: data.url,
                            }))
                          : [
                              { key: '0', url: '', label: 'SHOP' },
                              { key: '1', url: '', label: 'BLOG' },
                              { key: '2', url: '', label: 'ABOUT' },
                              { key: '3', url: '', label: 'FAQ' },
                            ],
                        ...template.options.TemplateHeader,
                      }}
                      global={template.options}
                      components={components}
                      selected={currentSelected.name === 'Header'}
                    />
                    {template.components.map((c, index) => {
                      // eslint-disable-next-line no-underscore-dangle
                      const key = `${c._id || c.id}-${index}`;
                      const selected = `${index}` === currentSelected.key;
                      return (
                        <RenderedComponent
                          key={key}
                          renderKey={key}
                          componentChange={componentChange}
                          component={c}
                          components={components}
                          recType={template.templateId
                            .toString()
                            .substring(0, 2)}
                          selected={selected}
                          setId={set}
                          global={template.options}
                        />
                      );
                    })}
                    <RenderedComponent
                      componentName="Footer"
                      renderKey="Footer-998"
                      recType={template.templateId.toString().substring(0, 2)}
                      componentChange={componentChange}
                      component={template.options.TemplateFooter}
                      components={components}
                      selected={currentSelected.name === 'Footer'}
                      global={template.options}
                    />
                  </div>
                </Scrollable>
              )}
            </Stack.Item>
            {renderSidebar()}
          </Stack>
        </Page>

        {query.get('upgraded') && !toastDismissed && (
          <Toast
            content="Success! Smart Recommendation preferences saved."
            onDismiss={() => setToastDismissed(true)}
          />
        )}
      </Frame>
      <SavedModal
        setId={template && template.templateSetId}
        saveActive={saveActive}
        setSaveActive={setSaveActive}
      />
      {set && <MagicModal />}
    </div>
  );
};
export default Builder;
