import { Stack } from '@shopify/polaris';
import React, { useContext } from 'react';
import { TemplateContext } from '../../../state/TemplateContext';
import BuilderOption from '../BuilderOption';

const EmailSettings = () => {
  const [{ options }, updateTemplate] = useContext(TemplateContext);
  const emailSettings = options.Settings;

  const change = (propName, val) => {
    updateTemplate(propName, emailSettings[propName], val, 'Settings');
  };

  return (
    <Stack spacing="loose" vertical>
      <BuilderOption
        option={{
          type: 'multicolor',
          name: 'backgroundColor',
          label: 'Background color',
          defaultValue: emailSettings.backgroundColor,
        }}
        onChange={change}
      />
      <BuilderOption
        option={{
          type: 'multicolor',
          name: 'emailColor',
          label: 'Email color',
          defaultValue: emailSettings.emailColor,
        }}
        onChange={change}
      />
      <BuilderOption
        option={{
          type: 'number',
          label: 'Inner horizontal padding',
          name: 'paddingHoriz',
          defaultValue: emailSettings.paddingHoriz,
          max: 50,
          unit: 'px',
        }}
        onChange={change}
      />
      <BuilderOption
        option={{
          type: 'number',
          label: 'Inner vertical padding',
          name: 'paddingVert',
          defaultValue: emailSettings.paddingVert,
          unit: 'px',
        }}
        onChange={change}
      />
      <BuilderOption
        option={{
          type: 'number',
          label: 'Space above email',
          name: 'paddingTop',
          defaultValue: emailSettings.paddingTop,
          unit: 'px',
        }}
        onChange={change}
      />
      <BuilderOption
        option={{
          type: 'number',
          label: 'Space below email',
          name: 'paddingBottom',
          defaultValue: emailSettings.paddingBottom,
          unit: 'px',
        }}
        onChange={change}
      />
    </Stack>
  );
};

export default EmailSettings;
