import { Select } from '@shopify/polaris';
import React from 'react';

const fonts = [
  {
    label: 'Shopify Default',
    value:
      // eslint-disable-next-line max-len
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Fira Sans', Droid Sans, 'Helvetica Neue', sans-serif",
  },
  {
    label: 'Arial',
    value: "Arial, 'Helvetica Neue', Helvetica, sans-serif",
  },
  {
    label: 'Georgia',
    value: "'georgia', serif",
  },
  {
    label: 'Comic Sans',
    value: "'Comic Sans MS','Comic Sans',cursive",
  },
  {
    label: 'Courier New',
    value:
      "'Courier New', Courier, 'Lucida Sans Typewriter', 'Lucida Typewriter', monospace",
  },
  {
    label: 'Lucida',
    value:
      "'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Geneva, Verdana, Arial, sans-serif",
  },
  {
    label: 'Tahoma',
    value: 'Tahoma,Verdana, Segoe, sans-serif',
  },
  {
    label: 'Times New Roman',
    value:
      "TimesNewRoman, 'Times New Roman', Times, Baskerville, Georgia, serif",
  },
  {
    label: 'Trebuchet',
    value:
      "'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif",
  },
  {
    label: 'Helvetica',
    value: "Helvetica,'Helvetica Neue', Arial, sans-serif",
  },
  {
    label: 'Calibri',
    value: "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
  },
  {
    label: 'Roboto',
    value: "roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif",
    googleFont: true,
  },
  {
    label: 'Open Sans',
    value: "'open sans', Arial, 'Helvetica Neue', Helvetica, sans-serif",
    googleFont: true,
    special: 'openSans',
  },
  {
    label: 'Oswald',
    value: 'oswald, Helvetica, Lucida, Arial, sans-serif',
    googleFont: true,
  },
  {
    label: 'Ubuntu',
    value: 'ubuntu, Helvetica, Calibri, Tahoma, sans-serif',
    googleFont: true,
  },
  {
    label: 'Lato',
    value: 'lato, Helvetica, Tahoma, Verdana, Segoe, sans-serif',
    googleFont: true,
  },
  {
    label: 'Bitter',
    value:
      "bitter, Georgia, TimesNewRoman, 'Times New Roman', Times, Baskerville, Georgia, serif",
    googleFont: true,
  },
  {
    label: 'Merriweather',
    value: "merriweather, Georgia, 'georgia', serif",
    googleFont: true,
  },
  {
    label: 'Lora',
    value:
      "lora, Georgia, 'Palatino Linotype', 'Book Antiqua', Palatino, Georgia, serif",
    googleFont: true,
  },
  {
    label: 'Great Vibes',
    value: "'great vibes', Brush Script MT, Georgia, Helvetica, cursive",
    googleFont: true,
    special: 'greatVibes',
  },
  {
    label: 'Pacifico',
    value: 'Pacifico, Brush Script MT, Georgia, Helvetica, cursive',
    googleFont: true,
  },
  {
    label: 'Montserrat',
    value: 'Montserrat, Helvetica, sans-serif',
    googleFont: true,
  },
];

const FontSelector = ({ label, value, onChange }) => (
  <Select
    label={label}
    options={fonts.map((f) => ({ label: f.label, value: f.value }))}
    onChange={(evt) => {
      onChange(evt);
    }}
    value={value ? value.replaceAll('"', "'") : fonts[0]}
  />
);

export default FontSelector;
