import React from 'react';

const ModalStyleHack = ({ hideClose }) => {
  const hiddenCss = hideClose
    ? '.Polaris-Modal-CloseButton { display: none; }'
    : '';
  const modalPadding = '.Polaris-Modal-Section { padding: 0 }';
  const buttonFix =
    '.Polaris-Button--primary { background: rgba(0, 71, 98, 1) } ' +
    '.Polaris-Button--primary:hover { background: rgba(97, 97, 97, 1) }';
  const footerOverride =
    '.Polaris-Stack.Polaris-Stack--alignmentCenter { margin-right: 18rem } ' +
    '.Polaris-Modal-Footer { width: 100%; }';
  return (
    <style>
      {hiddenCss}
      {buttonFix}
      {modalPadding}
      {footerOverride}
    </style>
  );
};

export default ModalStyleHack;
