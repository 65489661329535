// AppInsights.js
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import Config from '../Config';
// import { createBrowserHistory } from 'history';

// const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: Config.spently.logging.appInsights.key,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: {},
    },
  },
});
appInsights.loadAppInsights();
export { reactPlugin, appInsights };
