import {
  Checkbox,
  Frame,
  Modal,
  TextContainer,
  InlineError,
} from '@shopify/polaris';
import React, { useState, useCallback } from 'react';
import { postSpently } from '../../util/spentlyRest';

function TermsOfServiceModal({ storeName }) {
  const [active, setActive] = useState(true);
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState(false);
  const [, setCompanyUpdateError] = useState();

  const handleCheck = useCallback(() => {
    if (error) {
      setError(false);
    }
    setChecked((prev) => !prev);
  }, [error]);

  const handleSuccessfulClose = useCallback(async () => {
    if (checked) {
      setActive(false);
      await postSpently(
        `/api/termsofservice`,
        { StoreName: storeName, Accepted: true },
        () => {
          setCompanyUpdateError('Could not save Settings.');
        }
      );
    } else {
      setError(true);
    }
  }, [checked, storeName]);

  const handleUnsucessfulClose = useCallback(async () => {
    setActive(false);
    await postSpently(
      `/api/termsofservice`,
      { StoreName: storeName, Accepted: false },
      () => {
        setCompanyUpdateError('Could not save Settings.');
      }
    );
  }, [storeName]);

  return (
    <div style={{ height: '400px' }}>
      <Frame>
        <Modal
          open={active}
          title="Terms of Service"
          onClose={handleUnsucessfulClose}
          primaryAction={{
            content: 'Continue',
            onAction: handleSuccessfulClose,
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              onAction: handleUnsucessfulClose,
            },
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <div style={{ paddingBottom: '5px' }}>
                <span>
                  Before you can continue, please read and agree to the{' '}
                </span>
                <a
                  style={{ textDecoration: 'none' }}
                  href="https://pantasticapps.com/uporder-terms-of-service"
                  target="_blank"
                  rel="noreferrer"
                >
                  UpOrder Terms of Service,{' '}
                </a>
                <a
                  style={{ textDecoration: 'none', paddinBottom: '8px' }}
                  href="https://pantastic.com/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </div>
            </TextContainer>
            <Checkbox
              label="I have read and agreed to the Terms of Service and Privacy Policy"
              checked={checked}
              onChange={handleCheck}
            />
            {error && (
              <InlineError
                message="It looks like you forgot to agree to our Terms of 
           Service and Privacy Policy. Please check the box to proceed."
              />
            )}
          </Modal.Section>
        </Modal>
      </Frame>
    </div>
  );
}

export default TermsOfServiceModal;
