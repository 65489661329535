import Config from '../../Config';
import { postJson } from './rest';

export const logout = (stay) => {
  sessionStorage.clear();
  if (stay !== false) {
    document.location.reload();
  }
};

export const isLoggedIn = (app) => {
  const val = sessionStorage.getItem(`${app}-shop`);
  return !!val;
};

export const setMustUpgrade = (app, mustUpgrade) => {
  sessionStorage.setItem(`${app}-mustUpgrade`, mustUpgrade);
};

export const handleCode = async (app, shop, successLocation, code) => {
  const results = await postJson(app, `/api/Shopify/Authorize`, {
    store: `${shop}.myshopify.com`,
    code,
  });

  // if the authorize result return reload which mean this store just install the app
  // it will need to reload the page to get the new online token
  if (results.reload) {
    // set location to the new location and refresh
    window.location.href = `${window.location.origin}?shop=${shop}`;

    return;
  }

  sessionStorage.setItem(`${app}-auth`, JSON.stringify(results.token));
  sessionStorage.setItem(`${app}-shop`, shop);
  setMustUpgrade(app, results.mustUpgrade);
  sessionStorage.setItem(`${app}-isOverUsage`, results.isOverUsage);
  sessionStorage.setItem(
    `${app}-featureFlags`,
    results.featureFlags ? JSON.stringify(results.featureFlags.enabled) : '[]'
  );
  sessionStorage.setItem(`${app}-companyId`, results.companyId);

  const ret = sessionStorage.getItem(`${app}-returnUrl`);
  document.location = ret || successLocation;
};

export const login = async (app, shop, successLocation = '/') => {
  if (!shop) {
    return;
  }
  const config = Config[app];

  const query = new URLSearchParams(window.location.search);
  const code = query.get('code');

  if (!code) {
    if (document.location.pathname !== '/') {
      sessionStorage.setItem(`${app}-returnUrl`, document.location.href);
    }
    const { scopes, apiKey, retUrl } = config.shopify;

    document.location =
      `https://${shop}.myshopify.com/admin/oauth/authorize?` +
      `client_id=${apiKey}&scope=${scopes.join(
        ','
      )}&redirect_uri=${retUrl}&state=${app}&grant_options[]=per-user`;
  } else {
    await handleCode(app, shop, successLocation, code);
  }
};

export const checkLogin = async (app, shop, successLocation) => {
  const query = new URLSearchParams(window.location.search);
  const code = query.get('code');

  if (code) {
    login(app, shop, successLocation);
  }
};

export const getUser = (app) => {
  const json = sessionStorage.getItem(`${app}-auth`);
  if (!json) {
    return null;
  }
  const auth = JSON.parse(json);
  return auth.associated_user;
};

export const getShop = (app) => sessionStorage.getItem(`${app}-shop`);
export const getCompanyId = (app) => sessionStorage.getItem(`${app}-companyId`);
export const getMustUpgrade = (app) =>
  sessionStorage.getItem(`${app}-mustUpgrade`) === 'true';
export const getIsOverUsage = () =>
  sessionStorage.getItem('spently-isOverUsage') === 'true';
export const getFeature = (feature) => {
  const features = sessionStorage.getItem(`spently-featureFlags`);
  if (!features) {
    return false;
  }
  const fArray = JSON.parse(features);

  return fArray.includes(feature);
};

export const checkIsPlanUpdated = () => {
  const query = new URLSearchParams(window.location.search);
  const result = query.get('planUpdated') || '';
  return result !== '';
};
