import React from 'react';
import { Card, DisplayText, SkeletonDisplayText } from '@shopify/polaris';
import { translate } from '../../util/localizer';

const SingleValue = ({ titleKey, value }) => {
  const title = translate(titleKey);
  return (
    <Card title={title} sectioned>
      {value !== undefined && <DisplayText size="small">{value}</DisplayText>}
      {value === undefined && <SkeletonDisplayText size="small" />}
    </Card>
  );
};

export default SingleValue;
