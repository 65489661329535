import { Card } from '@shopify/polaris';
import React from 'react';
import PanTable from '../../framework/ui/PanTable';
import { getSpently } from '../../util/spentlyRest';
import { urlDate } from '../../util/formatters';
import { formatDate } from '../../framework/util/formatters';
import { downloadCsv } from '../../framework/util/rest';

const RoiTable = ({ emailType, range }) => {
  const mapper = (response) => {
    const res = response[0];
    return {
      data: res.ROIData.map((r) => [
        r.order,
        r.emailSentDate ? formatDate(r.emailSentDate) : 'N/A',
        r.engagementDate ? formatDate(r.engagementDate) : 'N/A',
        r.email,
        r.value,
        r.discountCode,
        r.category,
      ]),
      pageCount: res.pages,
    };
  };

  const q = `start=${urlDate(range.start)}&end=${urlDate(range.end)} 23:59:59`;

  const downloadFile = async () => {
    downloadCsv(`api/roi/export?${q}`, 'RoiReport');
  };
  return (
    <Card
      title="Revenue"
      actions={[
        {
          content: 'Export',
          onAction: downloadFile,
        },
      ]}
    >
      <PanTable
        types={['text', 'text', 'text', 'text', 'text', 'text']}
        headings={[
          'Order number',
          'Email sent date',
          'Email open date',
          'Customer',
          'Amount',
          'Discount',
          'Category',
        ]}
        url={`/api/roi?start=${urlDate(range.start)}&end=${urlDate(
          range.end
        )} 23:59:59&type=${emailType || 'OI'}`}
        mapper={mapper}
        get={getSpently}
        emptyWarningMessage="Please either change the Date range or the Email type to search for more data"
      />
    </Card>
  );
};
export default RoiTable;
