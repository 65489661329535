import { Button } from '@shopify/polaris';
import React, { useContext, useState } from 'react';
import { getSpently } from '../../../util/spentlyRest';
import OptionsContext from '../../../state/OptionsContext';

const SearchProduct = () => {
  const { prods, setProds, setSearchTrigger, setProductError, filter } =
    useContext(OptionsContext);
  const [loading, setLoading] = useState(false);
  let duplicate = false;

  const handleProductList = () => {
    prods.forEach((p) => {
      if (p.label.toLowerCase() === filter.toLowerCase()) {
        duplicate = true;
        setProductError('This product is already in the dropdown');
        setLoading(false);
      }
    });
  };

  const getMoreResults = async () => {
    if (filter.length > 0) {
      setLoading(true);
      handleProductList(prods);
      if (!duplicate) {
        const response = await getSpently(`/api/Product?title=${filter}`);
        if (response.length > 0) {
          const product = response.map((p) => ({
            label: p.title,
            value: `${p.id}`,
            handle: p.handle,
            image: p.image,
          }));
          setProds(prods.concat(product));
          setSearchTrigger(true);
          setProductError(undefined);
        } else {
          setProductError(`Could not find this product: ${filter}`);
        }
      }
      setLoading(false);
    } else {
      setProductError(`Could not find this product: ${filter}`);
    }
  };

  return (
    <Button disabled={loading} onClick={getMoreResults}>
      Search product
    </Button>
  );
};

export default SearchProduct;
