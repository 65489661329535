import {
  Card,
  Heading,
  Layout,
  List,
  Loading,
  OptionList,
  Page,
  SkeletonPage,
  Toast,
} from '@shopify/polaris';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';
import { TemplateContext } from '../state/TemplateContext';
// import { buildHtmlForTemplate } from '../util/builder';
import {
  emailTypes,
  getSupportedNotificationMenus,
  getNotificaitonEditUrl,
} from '../util/formatters';
import { getSpently } from '../util/spentlyRest';
import {
  sendGoogleAnalyticsEvent,
  GoogleAnalyticsEvent,
} from '../widgets/googleAnalytics';
import { sendIntercomEvent, IntercomEvent } from '../widgets/intercom';

const Publish = () => {
  const [template, , setTemplate] = useContext(TemplateContext);
  const [selected, setSelected] = useState(['OI']);
  const [msg, setMsg] = useState(null);

  const loadTemplate = useCallback(
    async (et) => {
      const query = new URLSearchParams(window.location.search);
      const set = query.get('setId') || '';
      // const qs = set ? `setId=${set}` : `afId=${query.get('afId')}`;
      const temp = await getSpently(
        `/api/Template/${set}/Publish?templateType=${et || selected}`
      );

      // send VISITED_EXPORTALL event to intercom on template loaded
      sendIntercomEvent(IntercomEvent.VISITED_EXPORTALL);

      setTemplate(temp);
      if (query.get('v2Upgrade')) {
        setMsg(' Your template set has been updated!');
      }
    },
    [selected, setTemplate]
  );

  useEffect(() => {
    if (!template) {
      loadTemplate();
    }
  }, [template, selected, setTemplate, loadTemplate]);

  if (!template) {
    return (
      <SkeletonPage title="Publish to Shopify">
        <Loading />
      </SkeletonPage>
    );
  }

  sendGoogleAnalyticsEvent(GoogleAnalyticsEvent.PUPBLISH, {});

  return (
    <Page
      title="Publish to Shopify"
      primaryAction={{
        content: 'Copy to Clipboard',
        onAction: () => {
          // send CLICK_COPY event to intercom on user click copy button
          sendIntercomEvent(IntercomEvent.CLICK_COPY);
          copy(template.html);
          setMsg('Copied');
        },
      }}
    >
      <Layout>
        <Layout.Section oneThird>
          <OptionList
            onChange={(e) => {
              setSelected(e);
              setTemplate(null);
              loadTemplate(e);
            }}
            sections={getSupportedNotificationMenus(template)}
            selected={selected}
          />
        </Layout.Section>
        <Layout.Section>
          <Card>
            <div style={{ padding: 16 }}>
              <Heading>{emailTypes[selected]}</Heading>
              <List type="number">
                <List.Item>
                  Click the <strong>Copy to Clipboard</strong> button to copy
                  the email template code to your clipboard.
                  <div style={{ margin: 16, textAlign: 'center' }}>
                    <iframe
                      title="How to get your templates live"
                      width="460"
                      height="275"
                      src="https://www.youtube.com/embed/joCR2_Jmp-s"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen=""
                    />
                    <h6 className="export__item">
                      How To Get Your Templates Live
                    </h6>
                  </div>
                </List.Item>
                <List.Item>
                  <a
                    href={getNotificaitonEditUrl(selected[0])}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Click here
                  </a>{' '}
                  to go to your Shopify email notification settings.
                  {selected[0] === 'PR' && (
                    <>
                      <br />
                      <em>
                        You must have POS enabled on your Shopify store to
                        activate this template type.
                      </em>
                    </>
                  )}
                </List.Item>
                <List.Item>
                  Delete the existing code and paste in your new UpOrder email
                  template.
                </List.Item>
                <List.Item>
                  Preview your template and <strong>save.</strong>
                </List.Item>
              </List>
            </div>
          </Card>
          {msg && <Toast content={msg} onDismiss={() => setMsg(null)} />}
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default Publish;
