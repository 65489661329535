import { Heading, Stack } from '@shopify/polaris';
import React, { useCallback, useContext, useState } from 'react';
import { TemplateContext } from '../../../state/TemplateContext';
import BuilderOption from '../BuilderOption';
import RightSidebar from './RightSidebar';
import NavigationSideBar from '../themeSettings/NavigationSideBar';
import StyleCollapsible from './StyleCollapsible';

const HeaderSidebar = ({ setDisplayOptions }) => {
  const [{ options }, updateTemplate] = useContext(TemplateContext);
  const [opened, setOpened] = useState(null);
  const values = options.TemplateHeader;

  const change = useCallback(
    (propName, val) => {
      updateTemplate(propName, values[propName], val, 'TemplateHeader');
    },
    [updateTemplate, values]
  );

  const Option = ({ option }) => (
    <BuilderOption
      option={option}
      values={values}
      onChange={change}
      setDisplayOptions={setDisplayOptions}
      key={option.name}
    />
  );

  const ColorOption = useCallback(
    ({ option }) => <Option option={{ ...option, type: 'multicolor' }} />,
    []
  );

  const renderInputOption = useCallback(
    (option) => (
      <BuilderOption
        option={option}
        values={values}
        onChange={change}
        setDisplayOptions={setDisplayOptions}
      />
    ),
    [change, setDisplayOptions, values]
  );

  return (
    <RightSidebar>
      <div id="themeSettings">
        <Stack vertical>
          <Heading>Template Header</Heading>
          <StyleCollapsible
            title="Styling"
            opened={opened}
            setOpened={setOpened}
          >
            <Option
              option={{
                type: 'checkbox',
                name: 'enabled',
                label: 'Use a header',
                defaultValue: true,
              }}
            />
            <ColorOption
              option={{
                name: 'bgColor',
                label: 'Background Color',
                defaultValue: '#ffffff',
              }}
            />
            {renderInputOption({
              type: 'number',
              name: 'height',
              label: 'Height',
              defaultValue: '25',
              min: 25,
              max: 600,
            })}
          </StyleCollapsible>
          <StyleCollapsible title="Logo" opened={opened} setOpened={setOpened}>
            <Option
              option={{
                type: 'checkbox',
                name: 'logoCheck',
                label: 'Include a logo',
                defaultValue: true,
              }}
            />
            <Option
              option={{
                type: 'image',
                name: 'image',
                defaultValue: '/Content/images/placeholder.png',
              }}
            />

            {renderInputOption({
              type: 'text',
              name: 'logoUrl',
              label: 'Logo URL',
            })}

            {renderInputOption({
              type: 'number',
              name: 'logoWidth',
              label: 'Logo width',
              max: 600,
            })}

            {renderInputOption({
              type: 'number',
              name: 'logoVerticalPadding',
              label: 'Logo space above and below',
            })}
            <Option
              option={{
                type: 'position',
                name: 'logoPosition',
                label: 'Logo position',
              }}
            />
          </StyleCollapsible>
          <StyleCollapsible
            title="Navigation Bar"
            opened={opened}
            setOpened={setOpened}
          >
            <Option
              option={{
                type: 'checkbox',
                name: 'navCheck',
                label: 'Include navigation bar',
                defaultValue: true,
              }}
            />
            <NavigationSideBar />
          </StyleCollapsible>
        </Stack>
      </div>
    </RightSidebar>
  );
};

export default HeaderSidebar;
