import { FormLayout, TextField } from '@shopify/polaris';
import React from 'react';

const NavLink = ({ index, value = { text: '', url: '' }, onChange }) => (
  <FormLayout.Group>
    <TextField
      type="text"
      label={`Heading ${index + 1}`}
      value={value.text}
      onChange={(newVal) => {
        onChange({ ...value, text: newVal });
      }}
      autoComplete="off"
    />
    <TextField
      type="url"
      label={`Url ${index + 1}`}
      value={value.url}
      onChange={(newVal) => {
        onChange({ ...value, url: newVal });
      }}
      autoComplete="off"
    />
  </FormLayout.Group>
);
export default NavLink;
