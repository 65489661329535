import React from 'react';
import ErrorModal from '../widgets/builder/modals/ErrorModal';

// create a react ErrorBoundary component that will catch errors and display a message to the user
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: {} };
  }

  // set the state to true if an error is caught
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  // the component should render a message to the user if an error is caught
  render() {
    const { hasError, error } = this.state;
    const { children } = this.props;

    if (hasError) {
      // error handling for the user
      return <ErrorModal error={error} />;
    }

    return children;
  }
}

export default ErrorBoundary;
