import {
  Thumbnail,
  Banner,
  List,
  Button,
  Heading,
  Stack,
  DropZone,
} from '@shopify/polaris';
import React, { useEffect, useState } from 'react';
import { getSpently, uploadSpently } from '../../../util/spentlyRest';
import RightSidebar from './RightSidebar';
import { logError } from '../../../util/logging';

const ImageSidebar = ({ updateImage }) => {
  const [images, setImages] = useState(null);

  useEffect(() => {
    const load = async () => {
      const response = await getSpently('/api/Media');
      setImages(response);
    };

    if (!images) {
      load();
    }
  });
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const hasError = rejectedFiles.length > 0;

  const handleDrop = async (_allImages, accepted, rejected) => {
    // TODO: to match the functionality of the old site, we upload absolutely everything,
    //        that's probably not something we want to do going forward.

    if (accepted.length > 0) {
      await uploadSpently('/api/Media', accepted[0], (error) => {
        logError('could not upload file', error);
      });
      setImages(null);
    }
    setRejectedFiles(rejected);
  };

  const errorMessage = hasError && (
    <Banner
      title="The following images couldn’t be uploaded:"
      status="critical"
    >
      <List type="bullet">
        {rejectedFiles.map((file) => (
          <List.Item key={file.name}>
            {`"${file.name}" is not supported. File type must be .gif, .jpg, .png or .svg.`}
          </List.Item>
        ))}
      </List>
    </Banner>
  );

  return (
    <RightSidebar>
      <Stack vertical>
        <Heading>Image sidebar</Heading>
        <Stack distribution="center">
          {errorMessage}
          {images && (
            <DropZone
              accept="image/*"
              allowMultiple={false}
              type="image"
              onDrop={handleDrop}
            >
              <DropZone.FileUpload />
            </DropZone>
          )}
          {images &&
            images.map((i) => {
              const url = i.imageUrl;
              return (
                <Button
                  key={i.id}
                  onClick={() => {
                    updateImage(i.imageUrl);
                  }}
                >
                  <Thumbnail
                    source={url}
                    size="large"
                    alt="Select this Image"
                  />
                </Button>
              );
            })}
        </Stack>
      </Stack>
    </RightSidebar>
  );
};

export default ImageSidebar;
