import en from '../locales/en.json';
import fr from '../locales/fr.json';

const lang = navigator.language.substr(0, 2);
const languages = {
  en,
  fr,
};

export const getLang = () => lang;

export const translate = (key) => {
  const l = 'en';
  return languages[l][key] || `Missing ${l} - ${key}`;
};
