import { Modal, TextContainer } from '@shopify/polaris';
import React, { useState } from 'react';

const PanConfirmModal = ({ active, title, description, cancel, action }) => {
  const [loading, setLoading] = useState(false);
  return (
    <div style={{ height: '500px' }}>
      <Modal
        instant
        open={active}
        onClose={cancel}
        title={title}
        primaryAction={{
          content: 'Delete',
          loading,
          onAction: () => {
            setLoading(true);
            action();
          },
          destructive: true,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: cancel,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>{description}</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default PanConfirmModal;
