import {
  Page,
  Layout,
  Card,
  Stack,
  Loading,
  Banner,
  EmptyState,
  DisplayText,
} from '@shopify/polaris';
import React, { useContext, useEffect, useState } from 'react';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { useHistory } from 'react-router-dom';
import { CalendarMajor } from '@shopify/polaris-icons';
import DatePickerDropdown from '../components/DatePickerDropdown';
import { getSpently } from '../util/spentlyRest';
import HalfCard from '../widgets/dashboard/HalfCard';
import SingleRow from '../widgets/dashboard/SingleRow';
import SingleValue from '../widgets/dashboard/SingleValue';
import { formatEmailType, urlDate } from '../util/formatters';
import { formatCurrency, formatPercentage } from '../framework/util/formatters';
import { DateRangeContext } from '../state/DateRangeContext';
import { translate } from '../util/localizer';
import theme from '../framework/util/theme';
import TermsOfServiceModal from '../framework/modals/TermsOfServiceModal';

const Dashboard = () => {
  const [results, setResults] = useState(null);
  const [error, setError] = useState(null);
  const [range, setRange] = useContext(DateRangeContext);
  const [openModal, setOpenModal] = useState(false);
  const history = useHistory();
  const { colors } = theme();

  useEffect(() => {
    const load = async () => {
      const r = await getSpently(
        `/api/Dashboard?start=${urlDate(range.start)}&end=${urlDate(
          range.end
        )} 23:59:59`,
        setError
      );
      setResults(r);
      if (!r.acceptsTermsOfService) {
        setOpenModal(true);
      }
    };

    load();
  }, [range]);

  if (error) {
    return (
      <Banner
        title="Could not Retrieve Data"
        action={{ content: 'Report this Error' }}
        status="critical"
      >
        <p>{error.statusText}</p>
      </Banner>
    );
  }

  const data = {
    labels: [],
    datasets: [
      {
        label: 'Revenue',
        maxBarThickness: 180,
        barThickness: 160,
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
      },
    ],
  };

  if (results) {
    results.notificationData
      .sort((a, b) => b.total - a.total)
      .forEach((not) => {
        data.labels.push(formatEmailType(not.emailType));
        data.datasets[0].data.push(not.total.toFixed(2));
        data.datasets[0].backgroundColor.push(colors.graph.fill);
        data.datasets[0].borderColor.push(colors.graph.border);
      });
  }

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
      x: {
        responsive: false,
      },
    },
    plugins: {
      tooltip: {
        callbacks: { label: (value) => formatCurrency(value.parsed.y) },
      },
    },
    onClick: (_, elements) => {
      if (elements[0]) {
        const { index } = elements[0];
        const notData = results.notificationData.sort(
          (a, b) => b.total - a.total
        )[index];
        history.push(`/Reports?emailType=${notData.emailType}`);
      }
    },
  };

  const {
    repeatPurchaseInsights: repeat = {},
    discountsInsights: discounts = {},
    checkoutRecoveryInsights: checkouts = {},
  } = results || {};

  const { lifeTimeRoi, revenuePerPeriod } = results || {};
  const { totalOpens, totalClicks, distinctOpens, distinctClicks } =
    results || {};

  const showChart = results && results.notificationData.length > 0;
  const errorMsg = "We couldn't find any data in the range you selected.";

  return (
    <Page title={translate('Dashboard')} fullWidth>
      {!results && <Loading />}
      <Stack vertical>
        <Layout fullWidth>
          <Layout.Section oneThird>
            <SingleRow
              titleKey="LifetimeRevenue"
              value={formatCurrency(lifeTimeRoi)}
            />
          </Layout.Section>

          <Layout.Section oneThird>
            <SingleRow
              titleKey="RevenuePerPeriod"
              value={formatCurrency(revenuePerPeriod)}
            />
          </Layout.Section>

          <Layout.Section oneThird>
            <Card sectioned>
              <Stack distribution="center">
                <DatePickerDropdown
                  range={range}
                  updateRange={(r) => {
                    setResults(null);
                    setRange(r);
                  }}
                />
              </Stack>
            </Card>
          </Layout.Section>
          <Layout.Section fullWidth>
            <Stack distribution="fillEvenly">
              <SingleValue titleKey="TotalOpens" value={totalOpens} />
              <SingleValue titleKey="UniqueOpens" value={distinctOpens} />
              <SingleValue titleKey="TotalClicks" value={totalClicks} />
              <SingleValue titleKey="UniqueClicks" value={distinctClicks} />
              <SingleValue
                titleKey="ClickToOpenRate"
                value={formatPercentage(distinctClicks, distinctOpens)}
              />
            </Stack>
          </Layout.Section>
          <Layout.Section fullWidth>
            <Card title={translate('AllNotifications')} sectioned>
              {showChart && (
                <div style={{ overflowY: 'hidden' }}>
                  <div
                    style={{
                      width: `${data.labels.length * 10}vw`,
                      minWidth: '100%',
                    }}
                  >
                    <Chart
                      type="bar"
                      data={data}
                      options={options}
                      height={60}
                    />
                  </div>
                </div>
              )}
              {results && !showChart && (
                <EmptyState fullWidth>
                  <CalendarMajor
                    style={{ height: 48, fill: 'var(--p-text-subdued)' }}
                  />
                  <DisplayText size="small">{errorMsg}</DisplayText>
                  <p>Try selecting a different date range.</p>
                </EmptyState>
              )}
            </Card>
          </Layout.Section>
        </Layout>
        <Layout fullWidth>
          <HalfCard
            title={`${repeat.orderCount} repeat purchases driven by UpOrder`}
            leftTitleKey="RepeatCustomerRevenue"
            leftValue={repeat.repeatCustomerRevenue}
            rightTitleKey="RateOfRepeatPurchases"
            rightValue={repeat.repeatPurchaseRate}
          />
          <HalfCard
            title={`${discounts.discountsCount} discounts generated`}
            leftTitleKey="RevenueFromDiscounts"
            leftValue={discounts.discountsRevenue}
            rightTitleKey="DiscountsRedeemed"
            rightValue={discounts.discountsRedeemed}
          />
          <HalfCard
            title={`${checkouts.checkoutEmailsOpened} abandoned checkout emails opened`}
            leftTitleKey="RevenueRecovered"
            leftValue={checkouts.checkoutRecoveryRevenue}
            rightTitleKey="RecoveryRate"
            rightValue={checkouts.checkoutRecoveryRate}
          />
        </Layout>
      </Stack>
      {openModal && <TermsOfServiceModal storeName={results.storeName} />}
    </Page>
  );
};

export default Dashboard;
