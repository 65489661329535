import React from 'react';
import { FormLayout, Checkbox } from '@shopify/polaris';

const SocialOption = ({ value, onChange }) => {
  const socials = [
    {
      label: 'Facebook',
      name: 'Facebook',
      value: true,
    },
    {
      label: 'Twitter',
      name: 'Twitter',
      value: true,
    },
    {
      label: 'Pinterest',
      name: 'Pinterest',
      value: true,
    },
    {
      label: 'Snapchat',
      name: 'Snapchat',
      value: true,
    },
    {
      label: 'LinkedIn',
      name: 'Linkedin',
      value: true,
    },
    {
      label: 'Instagram',
      name: 'Instagram',
      value: true,
    },
    {
      label: 'YouTube',
      name: 'Youtube',
      value: true,
    },
    {
      label: 'Tiktok',
      name: 'Tiktok',
      value: true,
    },
    {
      label: 'RSSFeed',
      name: 'RSSFeed',
      value: false,
    },
    {
      label: 'Messenger',
      name: 'Messenger',
      value: true,
    },
  ];

  const { links } = value;

  // TODO: we need to fix this awful data structure on the servier
  const needsHack = Array.isArray(links[0]);
  const optVal = links.map((l) => {
    if (!needsHack) {
      return l;
    }
    const ret = {};
    l.forEach((val) => {
      ret[val.name] = val.value;
    });

    return ret;
  });
  return (
    <FormLayout.Group condensed>
      {socials.map((ch) => {
        const optIndex = optVal.findIndex(
          (social) => social.name.toLowerCase() === ch.label.toLowerCase()
        );
        const checked = optIndex === -1 ? false : optVal[optIndex].value;

        return (
          <Checkbox
            key={ch.name}
            label={ch.label}
            checked={checked}
            onChange={(val) => {
              let social = optVal.filter(
                (l) => l.name.toLowerCase() === ch.name.toLowerCase()
              )[0];
              if (!social) {
                social = { label: ch.label, name: ch.name };
                optVal.push(social);
              }
              social.value = val;
              // TODO: Yup, we need to fix the gross here too.
              onChange(
                optVal.map((ov) =>
                  needsHack
                    ? [
                        { name: 'label', value: ov.label },
                        { name: 'name', value: ov.name },
                        { name: 'value', value: ov.value },
                      ]
                    : ov
                )
              );
            }}
          />
        );
      })}
    </FormLayout.Group>
  );
};

export default SocialOption;
