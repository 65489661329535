/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { TextField, TextStyle, DisplayText } from '@shopify/polaris';
import React, { useContext, useState } from 'react';
import { TemplateContext } from '../../state/TemplateContext';
import './builder.scss';

const TemplateTitle = () => {
  const [editing, setEditing] = useState(false);
  const [template, updateTemplate] = useContext(TemplateContext);
  const [name, setName] = useState(template && template.name);

  if (!template) {
    return <DisplayText size="small">Loading...</DisplayText>;
  }
  if (editing) {
    return (
      <TextField
        value={name}
        autoFocus
        selectTextOnFocus
        onChange={(val) => {
          setName(val);
          updateTemplate('TITLE', template.name, val);
        }}
        onBlur={() => setEditing(false)}
      />
    );
  }

  if (!name) {
    return (
      <div
        className="builder-title"
        onClick={() => {
          setName(template.name);
          setEditing(true);
        }}
      >
        <DisplayText size="small">
          <TextStyle variation="subdued">{template.name}</TextStyle>
        </DisplayText>
      </div>
    );
  }
  return (
    <div className="builder-title" onClick={() => setEditing(true)}>
      <DisplayText size="small">{name}</DisplayText>
    </div>
  );
};

export default TemplateTitle;
