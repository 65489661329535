import { Card, Stack } from '@shopify/polaris';
import React from 'react';
import DataListRow from '../../components/DataListRow';
import {
  formatCurrency,
  formatPercentage,
} from '../../framework/util/formatters';

const ReportStats = ({ results }) => (
  <Card title="Stats" sectioned>
    <div style={{ height: 400 }}>
      <Stack vertical>
        <DataListRow
          name="Total revenue"
          value={formatCurrency(results.revenue)}
        />
        <DataListRow
          name="Discounts redeemed"
          value={results.discountsRedeemed}
        />
        <DataListRow name="Total clicks" value={results.totalClicks} />
        <DataListRow name="Unique clicks" value={results.distinctClicks} />
        <DataListRow
          name="Click-to-open rate"
          value={formatPercentage(
            results.distinctClicks,
            results.distinctOpens
          )}
        />
        <DataListRow name="Total opens" value={results.totalOpens} />
        <DataListRow name="Unique Opens" value={results.distinctOpens} />
        {results.emailsSent > 0 ? (
          <DataListRow name="Email Sends" value={results.emailsSent} />
        ) : null}
      </Stack>
    </div>
  </Card>
);

export default ReportStats;
