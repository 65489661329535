import React, { useState } from 'react';
import {
  Heading,
  Stack,
  Button,
  Subheading,
  TextStyle,
  Scrollable,
  Collapsible,
} from '@shopify/polaris';
import { CancelSmallMinor } from '@shopify/polaris-icons';
import {
  ButtonSettings,
  EmailSettings,
  DividerSettings,
  DiscountSettings,
  FontSettings,
  RecommendationSettings,
} from '../themeSettings';
import './sidebars.scss';

const ThemeSidebar = ({ options, clearSelected, handleChange }) => {
  const [opened, setOpened] = useState(null);

  const Section = ({ children }) => (
    <Button
      fullWidth
      textAlign="left"
      disclosure={opened === children ? 'down' : 'up'}
      removeUnderline
      onClick={() => setOpened(opened === children ? null : children)}
    >
      <Subheading>
        <TextStyle variation="subdued">{children} Styles</TextStyle>
      </Subheading>
    </Button>
  );
  return (
    <div id="theme-settings">
      <Scrollable
        style={{ padding: '1.5rem 0 0', overflowX: 'hidden', flex: 1 }}
        vertical
      >
        <Stack vertical spacing="tight">
          <Stack spacing="loose" distribution="equalSpacing">
            <Heading>Template Settings</Heading>
            <Button plain onClick={clearSelected} icon={CancelSmallMinor} />
          </Stack>

          <Section>Email</Section>
          <Collapsible open={opened === 'Email'}>
            <EmailSettings />
          </Collapsible>

          <Section>Button</Section>
          <Collapsible open={opened === 'Button'}>
            <ButtonSettings />
          </Collapsible>

          <Section>Discount</Section>
          <Collapsible open={opened === 'Discount'}>
            <DiscountSettings />
          </Collapsible>

          <Section>Recommendation</Section>
          <Collapsible open={opened === 'Recommendation'}>
            <RecommendationSettings />
          </Collapsible>

          <Section>Divider</Section>
          <Collapsible open={opened === 'Divider'}>
            <DividerSettings />
          </Collapsible>

          <Section>Font</Section>
          <Collapsible open={opened === 'Font'}>
            <FontSettings
              values={options.GlobalFont}
              updateTemplate={handleChange}
            />
          </Collapsible>
        </Stack>
      </Scrollable>
    </div>
  );
};

export default ThemeSidebar;
