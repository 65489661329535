import { Button, Modal } from '@shopify/polaris';
import React, { useState, useEffect } from 'react';
import { logError } from '../../../util/logging';
import { getSpently, postSpently } from '../../../util/spentlyRest';
import SocialSettings from '../../settings/SocialSettings';

const EditSocialLinks = ({ label }) => {
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [settings, setSettings] = useState(null);
  const [originalSettings, setOriginalSettings] = useState(null);

  useEffect(() => {
    const loadSettings = async () => {
      const severSettings = await getSpently('/api/settings');
      setOriginalSettings(severSettings);
      setSettings(severSettings);
    };

    if (!settings) {
      loadSettings();
    }
  }, [settings]);

  return (
    <Modal
      instant
      activator={
        <Button
          onClick={() => {
            setOpen(true);
          }}
          disabled={!settings}
        >
          {label}
        </Button>
      }
      open={open}
      onClose={() => setOpen(false)}
      title={label}
      primaryAction={{
        content: 'Save',
        loading: saving,
        onAction: async () => {
          setSaving(true);
          setOriginalSettings(settings);
          await postSpently('/api/settings', settings, () => {
            logError('Could not save Settings.');
          });
          setSaving(false);
          setOpen(false);
        },
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: () => {
            setSettings(originalSettings);
            setOpen(false);
          },
        },
      ]}
    >
      <Modal.Section>
        <SocialSettings
          settings={settings}
          save={(newVals) => setSettings({ ...settings, ...newVals })}
        />
      </Modal.Section>
    </Modal>
  );
};

export default EditSocialLinks;
