/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import Config from '../../Config';

const useMocks = true;

export const setServerUriToSession = () =>
  sessionStorage.setItem('server-uri', Config.spently.server);

export const getAuthenticatedHeaders = (app) => {
  const authJson = sessionStorage.getItem(`${app}-auth`);
  const shop = sessionStorage.getItem(`${app}-shop`);
  let auth = {};
  if (authJson) {
    auth = JSON.parse(authJson);
  }
  return {
    'X-Shopify-Access-Token': auth.access_token,
    'X-Shopify-Shop': shop,
  };
};

export const getJson = async (app, endpoint, errorHandler) => {
  if (useMocks) {
    let results = null;
    try {
      results = require(`../../mocks/${endpoint.replace('/api/', '')}.json`);
    } catch (e) {
      // do nothing;
    }

    if (results) {
      return results;
    }
  }

  const res = await fetch(`${Config[app].server}${endpoint}`, {
    headers: getAuthenticatedHeaders(app),
  });
  if (!res.ok && errorHandler) {
    errorHandler({
      status: res.status,
      statusText: res.statusText,
    });
    return null;
  }

  const json = await res.json();
  return json;
};

export const postJson = async (app, endpoint, data, errorHandler) => {
  const res = await fetch(`${Config[app].server}${endpoint}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: getAuthenticatedHeaders(app),
  });
  if (!res.ok && errorHandler) {
    errorHandler({
      status: res.status,
      statusText: res.statusText,
    });
    return null;
  }

  const json = await res.text();

  if (json) {
    return JSON.parse(json);
  }
  return {};
};

export const putJson = async (app, endpoint, data, errorHandler) => {
  const res = await fetch(`${Config[app].server}${endpoint}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: getAuthenticatedHeaders(app),
  });
  if (!res.ok && errorHandler) {
    errorHandler({
      status: res.status,
      statusText: res.statusText,
    });
    return null;
  }

  const json = await res.text();

  if (json) {
    return JSON.parse(json);
  }
  return {};
};

export const uploadFile = async (app, endpoint, file, errorHandler) => {
  const formData = new FormData();
  formData.append('file', file);
  const res = await fetch(`${Config[app].server}${endpoint}`, {
    method: 'POST',
    body: formData,
    headers: getAuthenticatedHeaders(app),
  });
  if (!res.ok && errorHandler) {
    errorHandler({
      status: res.status,
      statusText: res.statusText,
    });
    return null;
  }

  const json = await res.text();

  if (json) {
    return JSON.parse(json);
  }
  return {};
};

export const deleteJson = async (app, endpoint, errorHandler) => {
  const res = await fetch(`${Config[app].server}${endpoint}`, {
    method: 'DELETE',
    headers: getAuthenticatedHeaders(app),
  });
  if (!res.ok && errorHandler) {
    errorHandler({
      status: res.status,
      statusText: res.statusText,
    });
    return null;
  }

  const json = await res.text();

  if (json) {
    return JSON.parse(json);
  }
  return {};
};

/**
 * Downloads a CSV file from the specified endpoint.
 * @param {string} endpoint - The API endpoint to fetch the CSV file.
 * @param {string} fileName - The desired name for the downloaded CSV file.
 */
export const downloadCsv = async (endpoint, fileName) => {
  // Construct the full URL for the API endpoint
  const url = `${Config.spently.server}/${endpoint}`;

  // Set up the headers for the fetch request, including authentication headers
  const options = {
    headers: getAuthenticatedHeaders('spently'),
  };

  // Fetch the CSV file from the specified endpoint
  const res = await fetch(url, options);

  // Get the blob (binary large object) data from the response
  const blob = await res.blob();

  // Create a link element to simulate a click and trigger the download
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;

  // Append the link to the document body
  document.body.appendChild(link);

  // Simulate a click on the link to trigger the download
  link.click();

  // Remove the link element from the document body
  document.body.removeChild(link);
};
