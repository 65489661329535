import React from 'react';
import ReactDOM from 'react-dom';
import '@shopify/polaris/build/esm/styles.css';
import './cssOverrides/CustomPropertyOverrides.scss';
import enTranslations from '@shopify/polaris/locales/en.json';
import frTranslations from '@shopify/polaris/locales/fr.json';
import { AppProvider, CustomProperties } from '@shopify/polaris';
import * as FullStory from '@fullstory/browser';
import App from './App';
import { getLang } from './util/localizer';
import theme from './framework/util/theme';
import Config from './Config';

const lang = getLang() === 'en' ? enTranslations : frTranslations;
const WrappedApp = () => {
  if (Config.spently.logging.fullStory) {
    FullStory.init(Config.spently.logging.fullStory);
  }

  const appTheme = theme();
  return (
    <AppProvider i18n={lang}>
      <CustomProperties
        colorScheme={appTheme.colorScheme}
        className="CustomPropertyOverrides"
      >
        <App />
      </CustomProperties>
    </AppProvider>
  );
};

ReactDOM.render(<WrappedApp />, document.getElementById('root'));
