const theme = () => ({
  logo: {
    authLogo: '/images/uporder_logo.svg',
    topBarSource: '/images/uporder_logo_light_topbar.svg',
    url: '/',
    accessibilityLabel: 'UpOrder',
  },
  colors: {
    surface: 'white', // '#004762' : '#f6f6f7',
    primary: 'rgba(0, 121, 163, 1)',
    success: '#004762',
    error: '#FF4559',
    graph: {
      fill: 'rgb(111, 175, 236)',
      border: 'rgb(0, 71, 98)',
    },
  },
  colorScheme: 'light',
});

export default theme;
