export const formatCurrency = (val) => {
  if (val === undefined) {
    return '';
  }
  return `$${val.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};

export const formatPercentage = (val1, val2) => {
  if (val1 !== undefined && val2 === undefined) {
    return val1 > 1 ? val1 : `${(val1 * 100).toFixed(0)}%`;
  }
  if (val1 === 0 || val2 === 0) {
    return '0%';
  }
  return `${((val1 / val2) * 100).toFixed(0)}%`;
};

export const formatDate = (date) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const currentDate = new Date(date);
  const day = currentDate.getDate();
  const month = months[currentDate.getMonth()];
  const year = currentDate.getFullYear();

  return `${month} ${day}, ${year}`;
};
