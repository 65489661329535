import React, { useState } from 'react';
import { TopBar, Frame } from '@shopify/polaris';
import { Route, Switch } from 'react-router-dom';
import UserMenu from '../components/UserMenu';
import Nav from '../widgets/navigation/Navigation';
import Dashboard from '../screens/Dashboard';
import Notifications from '../screens/Notifications';
import Reports from '../screens/Reports';
import Usage from '../screens/Usage';
import CompanySettings from '../screens/CompanySettings';
import Tester from '../screens/Tester';
import { TemplateProvider } from '../state/TemplateContext';
import Publish from '../screens/Publish';
import theme from '../framework/util/theme';
import { BaseComponentProvider } from '../state/BaseComponentContext';
import CustomerDataRequestDownload from '../screens/CustomerDataRequest';
import Paywall from '../screens/Paywall';
import {
  getMustUpgrade,
  checkIsPlanUpdated,
  logout,
  getShop,
} from '../framework/util/auth';
import PlanLimitUpgrade from '../widgets/banners/PlanLimitUpgrade';
import Refersion from '../components/Refersion';

const Site = () => {
  const [mobileNav, setMobileNav] = useState(false);
  const { logo } = theme();

  const topBar = (
    <TopBar
      showNavigationToggle
      userMenu={<UserMenu />}
      onNavigationToggle={() => setMobileNav(!mobileNav)}
    />
  );
  // if the url is a call back from plan updated, we want to re authorize to get updated plan info
  if (checkIsPlanUpdated()) {
    const shop = getShop('spently');
    logout(false);
    window.location = window.location.href.replace(
      'planUpdated=true',
      `shop=${shop}`
    );
  }

  const mustUpgrade = getMustUpgrade('spently');
  return (
    <Frame
      topBar={topBar}
      logo={logo}
      showMobileNavigation={mobileNav}
      onNavigationDismiss={() => setMobileNav(false)}
      navigation={<Nav />}
    >
      <PlanLimitUpgrade />
      <Refersion />
      <Switch>
        <Route path="/Analytics">
          <Dashboard />
        </Route>
        <Route path="/Paywall">
          <Paywall />
        </Route>
        <Route path="/Publish">
          {mustUpgrade && <Paywall />}
          {!mustUpgrade && (
            <TemplateProvider>
              <Publish />
            </TemplateProvider>
          )}
        </Route>
        <Route path="/Tester">
          <BaseComponentProvider>
            <Tester />
          </BaseComponentProvider>
        </Route>
        <Route path="/Reports">
          <Reports />
        </Route>
        <Route path="/Notifications">
          <Notifications />
        </Route>
        <Route path="/CompanySettings">
          <CompanySettings />
        </Route>
        <Route path="/Customer">
          <CustomerDataRequestDownload />
        </Route>
        <Route path="/usage">
          {mustUpgrade && <Paywall />}
          {!mustUpgrade && <Usage />}
        </Route>
        <Route path="/">
          <Dashboard />
        </Route>
      </Switch>
    </Frame>
  );
};

export default Site;
