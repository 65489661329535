import {
  ResourceList,
  TextStyle,
  Card,
  ResourceItem,
  Page,
  Icon,
} from '@shopify/polaris';
import React from 'react';
import { SaveMinor } from '@shopify/polaris-icons';
import { downloadCsv } from '../framework/util/rest';

const CustomerDataRequestDownload = () => {
  // Extract the customerObjectId from the current pathname
  const pathParts = document.location.pathname.split('/');
  const customerObjectId = pathParts[pathParts.length - 1];

  // Function to build the API endpoint for a specific resource
  const buildEndpoint = (pathName) =>
    `api/customer/${customerObjectId}/${pathName}`;

  // List of customer data resources
  const customerDataList = [
    {
      id: 1,
      endpoint: buildEndpoint(''),
      name: 'Customer',
    },
    {
      id: 2,
      endpoint: buildEndpoint('purchases'),
      name: 'Purchases',
    },
    {
      id: 3,
      endpoint: buildEndpoint('roi'),
      name: 'ROI',
    },
    {
      id: 4,
      endpoint: buildEndpoint('raw_open'),
      name: 'Raw Open',
    },
    {
      id: 5,
      endpoint: buildEndpoint('raw_click'),
      name: 'Raw Click',
    },
    {
      id: 6,
      endpoint: buildEndpoint('unique_receipt'),
      name: 'Unique Receipt',
    },
  ];

  return (
    <Page title="Files related to your customer">
      <div>
        <Card title="Click the item to download">
          <div style={{ padding: '20px', marginTop: '20px' }}>
            <ResourceList
              items={customerDataList}
              renderItem={(c) => (
                <ResourceItem
                  onClick={() => {
                    downloadCsv(c.endpoint, c.name);
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ width: '300px' }}>
                      <TextStyle variation="strong">{`${c.name} CSV`}</TextStyle>
                    </div>
                    <div>
                      <Icon
                        source={SaveMinor}
                        tone="base"
                        style={{ position: 'absolute', right: 0 }}
                      />
                    </div>
                  </div>
                </ResourceItem>
              )}
            />
          </div>
        </Card>
      </div>
    </Page>
  );
};

export default CustomerDataRequestDownload;
