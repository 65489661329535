/* eslint-disable max-len */
import { MediaCard } from '@shopify/polaris';
import React from 'react';
import '../../cssOverrides/PanStyling.scss';

const PanMediaCard = ({
  title,
  url,
  primaryAction,
  secondaryAction,
  popoverActions,
  fallbackSrc,
  className,
}) => (
  <div className={`pan-media-card ${className}`}>
    <MediaCard
      portrait
      title={title}
      primaryAction={primaryAction}
      secondaryAction={secondaryAction}
      popoverActions={popoverActions}
    >
      <img
        src={url}
        onError={(e) => {
          e.target.onError = null;
          if (fallbackSrc) {
            e.target.src = fallbackSrc;
          }
        }}
        alt={title}
        style={{
          objectFit: 'cover',
          objectPosition: 'center',
          display: 'block',
          margin: '0 auto',
          maxWidth: 200,
          padding: 6,
        }}
      />
    </MediaCard>
  </div>
);

export default PanMediaCard;
