import Config from '../Config';
import { getUser, getShop, getCompanyId } from '../framework/util/auth';

// check if email is from pantastic.com or spentlyhq.com
const isInternalEmail = (email) =>
  // email is from pantastic.com or spentlyhq.com
  email.endsWith('@pantastic.com') || email.endsWith('@spentlyhq.com');

// get valid customer email from session
const getEmailFromSession = () => {
  try {
    const { email } = getUser('spently');
    if (!isInternalEmail(email)) {
      return email;
    }

    return '';
  } catch (ex) {
    return '';
  }
};

// send tracking event to intercom
export const sendIntercomEvent = (eventName) => {
  window.Intercom('trackEvent', eventName);
};

export const IntercomEvent = {
  // every time user go to template builder page
  VISITED_TEMPLATEBUILDER: 'visited-templatebuilder',
  // every time user go to template publish page
  VISITED_EXPORTALL: 'visited-exportall',
  // every time user click “Copy to Clipboard” button on publish page
  CLICK_COPY: 'click-copy',
};

// eslint-disable-next-line import/prefer-default-export
export const getIntercomSettings = () => {
  const shopName = getShop('spently');
  const companyId = getCompanyId('spently');
  const email = getEmailFromSession();
  const shopifyWebsite = `https://${shopName}.myshopify.com`;

  // if email is not from pantastic.com or spentlyhq.com, send email to intercom
  return email
    ? {
        email,
        website: shopifyWebsite,
        app_id: Config.spently.intercom.app_id,
        username: shopName,
        account_type: 'Shopify',
        user_id: companyId,
      }
    : {
        website: shopifyWebsite,
        app_id: Config.spently.intercom.app_id,
        username: shopName,
        account_type: 'Shopify',
        user_id: companyId,
      };
};

// eslint-disable-next-line
export const attachIntercomScript = () => { var w = window; var ic = w.Intercom; if (typeof ic === "function") { ic('reattach_activator'); ic('update', w.intercomSettings); } else { var d = document; var i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; var l = function () { var s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://widget.intercom.io/widget/' + Config.spently.intercom.app_id; var x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); }; if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } } };
