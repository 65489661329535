/* eslint-disable max-lines */
/* eslint-disable react/no-danger, no-console */

import {
  Select,
  TextField,
  Button,
  FormLayout,
  Checkbox,
  Banner,
} from '@shopify/polaris';
import React from 'react';
import FontSelector from '../../framework/ui/forms/FontSelector';
import CodeOption from './options/CodeOption';
import ImageOption from './options/ImageOption';
import MulticolorOption from './options/MulticolorOption';
import NumberOption from './options/NumberOption';
import PositionOption from './options/PositionOption';
import SocialOption from './options/SocialOption';
import ProductsOption from './options/ProductsOption';
import CollectionsDropdown from '../../framework/ui/shopify/CollectionsDropdown';
import EditSocialLinks from './actions/EditSocialLinks';
import EditLinks from './actions/EditLinks';
import LoadMoreCollections from './actions/LoadMoreCollections';
import SearchProduct from './actions/SearchProduct';

const BuilderOption = ({
  option,
  onChange,
  dependsOnValue,
  values,
  setDisplayOptions,
  isGlobal,
}) => {
  const label = option.label || option.name;
  let optVal =
    values && values[option.name] !== undefined
      ? values[option.name]
      : option.defaultValue;

  if (option.name && option.name.indexOf('.') !== -1) {
    const parts = option.name.split('.');
    if (values[parts[0]]) {
      optVal = values[parts[0]][parts[1]];
    }
  }
  const relatedTo = values && values[option.relatedTo];
  const updateValue = (newVal) => {
    onChange(option.name || option.label, newVal);
  };
  const updateTextValue = (newVal) => {
    const updatedValue = newVal.replaceAll('\n', '<br>');
    onChange(option.name || option.label, updatedValue);
  };

  // check if the dependsOnValue is the same as the option.dependsOnValue, if not, we do not render the option
  if (option.dependsOnValue && option.dependsOnValue !== dependsOnValue) {
    return null;
  }

  if (
    (option.relatedTo && !relatedTo) ||
    (option.dependsOnBkwd && dependsOnValue) ||
    (option.dependsOn && !dependsOnValue) ||
    (option.name && option.name.indexOf('gbl') === 0 && !isGlobal) ||
    option.name === 'multiColorGblToggle'
  ) {
    return null;
  }

  /**
   * Retrieves the collection dropdown value based on the provided option value and updated option.
   * @param {string} optValue - The original option value.
   * @param {Object} updatedOption - The updated option object.
   * @returns {string} - The collection dropdown value.
   */
  const getCollectionDropdownValue = (optValue, updatedOption) => {
    // Use the provided optValue or fallback to the first option's value from the updatedOption
    const optionValue =
      optValue || (updatedOption.options[0] && updatedOption.options[0].value);

    // Check if optionValue exists and split it to get the collection dropdown value
    return optionValue?.toString().split('|')[0];
  };

  switch (option.type) {
    case 'message':
      return (
        <Banner status="warning">
          <div dangerouslySetInnerHTML={{ __html: option.message }} />
        </Banner>
      );
    case 'reco-upgrade':
      return (
        <Banner status="warning">
          <div dangerouslySetInnerHTML={{ __html: option.message }} />
        </Banner>
      );

    case 'separator':
      return <hr />;
    case 'collection':
      return (
        <CollectionsDropdown
          onChange={updateValue}
          value={getCollectionDropdownValue(optVal, option)}
          defaultValue={option && option.defaultValue}
          label={label}
          supportLabel={option.supportLabel}
        />
      );
    case 'dropdown':
      return (
        <Select
          label={label}
          options={option.options.map((f) => {
            const withRemoved = { ...f };
            delete withRemoved.googleFont;
            return withRemoved;
          })}
          onChange={updateValue}
          value={optVal || (option.options[0] && option.options[0].value)}
        />
      );
    case 'code':
      return (
        <CodeOption
          onChange={updateValue}
          value={optVal}
          label={option.label}
          placeholder={option.defaultValue}
        />
      );
    case 'image':
      return (
        <ImageOption
          onChange={updateValue}
          value={optVal}
          setDisplayOptions={setDisplayOptions}
        />
      );
    case 'position':
      return (
        <PositionOption
          updateValue={updateValue}
          value={optVal}
          placeholder={option.defaultValue}
          label={label}
        />
      );
    case 'number':
      return (
        <NumberOption
          value={optVal}
          label={label}
          min={option.min}
          max={option.max || 100}
          onChange={updateValue}
          unit={option.unit || 'px'}
        />
      );
    case 'text':
      return <TextField label={label} onChange={updateValue} value={optVal} />;
    case 'textarea':
    case 'editor':
      return (
        <TextField
          label={label}
          onChange={updateTextValue}
          value={optVal.replaceAll('<br>', '\n')}
          multiline={4}
        />
      );
    case 'title':
      return (
        <Checkbox
          label={`Override "${label}" style?`}
          checked={`${optVal}` === 'true'}
          onChange={updateValue}
        />
      );
    case 'products':
      return (
        <ProductsOption
          onChange={updateValue}
          value={optVal || (option.options[0] && option.options[0].value)}
          defaultValue={option && option.defaultValue}
          label={label}
        />
      );
    case 'checkbox':
      return (
        <Checkbox
          label={label}
          checked={`${optVal}` === 'true'}
          onChange={updateValue}
        />
      );
    case 'social':
      return <SocialOption onChange={updateValue} value={values} />;
    case 'checkboxes':
      return (
        <FormLayout.Group condensed>
          {option.defaultValue.map((ch) => {
            const optIndex = optVal.findIndex(
              (social) => social.name.toLowerCase() === ch.label.toLowerCase()
            );
            const checked = optIndex === -1 ? false : optVal[optIndex].value;

            return (
              <Checkbox
                key={ch.name}
                label={ch.label}
                checked={checked}
                onChange={(val) => {
                  optVal[optIndex].value = val;
                  updateValue(optVal);
                }}
              />
            );
          })}
        </FormLayout.Group>
      );
    case 'multicolor':
      return (
        <MulticolorOption label={label} onChange={updateValue} value={optVal} />
      );
    case 'button':
      switch (option.action) {
        case 'editSocialLinks':
          return <EditSocialLinks label={label} />;
        case 'editNavLinks':
          return <EditLinks label={label} />;
        case 'loadMoreCollections':
          return <LoadMoreCollections />;
        case 'searchProduct':
          return <SearchProduct />;
        default:
          return (
            <Button
              onClick={() => {
                console.log(
                  `Button clicked, we need to implement: ${option.action}`
                );
              }}
            >
              {label}
            </Button>
          );
      }
    case 'font':
      return (
        <FontSelector label={label} value={optVal} onChange={updateValue} />
      );
    default:
      console.log(`Found an option we do not support ${option.type}`, option);
      return null;
  }
};

export default BuilderOption;
