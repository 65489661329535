import { Scrollable } from '@shopify/polaris';
import React from 'react';
import theme from '../../../framework/util/theme';

const RightSidebar = ({ styleOverrides, children, footer }) => {
  const { colors } = theme();
  const containerStyles = {
    height: 'calc(100vh - 3.5rem)',
    width: '18.5vw',
    margin: '-16px -28px 0 -16px',
    backgroundColor: colors.surface,
    display: 'flex',
    flexDirection: 'column',
  };

  return (
    <div style={{ ...containerStyles, ...styleOverrides }}>
      <Scrollable style={{ padding: '1.5rem 1rem 0', flex: 1 }}>
        {children}
      </Scrollable>
      {footer}
    </div>
  );
};

export default RightSidebar;
