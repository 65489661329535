/* eslint-disable import/no-dynamic-require */
/* eslint-disable react/no-danger */
import { Loading } from '@shopify/polaris';
import React, { useContext, useState } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';
import { json } from '@codemirror/lang-json';
import { BaseComponentContext } from '../state/BaseComponentContext';
import RenderedComponent from '../widgets/builder/RenderedComponent';

const Tester = () => {
  const [htmlVal, setHtml] = useState('');
  const [options, setOptions] = useState('');
  const [currentComponent, setCurrentComponent] = useState(null);
  const [components] = useContext(BaseComponentContext);

  const comps = [
    {
      key: 'Test',
      html: htmlVal,
      options: currentComponent && currentComponent.options,
    },
  ];

  const formatHTML = (input) => {
    const tab = '\t';
    let result = '';
    let indent = '';

    input.split(/>\s*</).forEach((element) => {
      if (element.match(/^\/\w/)) {
        indent = indent.substring(tab.length);
      }

      result += `${indent}<${element}>\r\n`;

      if (element.match(/^<?\w[^>]*[^\/]$/) && !element.startsWith('input')) {
        indent += tab;
      }
    });

    result = result.replaceAll(/%}{%/gi, '%}\n{%');
    result = result.replaceAll(/>{%/gi, '>\n{%');
    result = result.replaceAll(/%}</gi, '%}\n<');
    result = result.replaceAll(/%}{{/gi, '%}\n{{');
    result = result.replaceAll(/}}{%/gi, '}}\n{%');

    return result.substring(1, result.length - 3);
  };

  if (!components) {
    return <Loading />;
  }

  let optionsAsObj = {};

  try {
    if (options) {
      optionsAsObj = JSON.parse(options);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('json error', error);
  }

  return (
    <div
      style={{
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div style={{ display: 'flex' }}>
        <div style={{ width: '70vw' }}>
          <h1>HTML</h1>
          <select
            onChange={(e) => {
              const compToLoad = components.find(
                (c) => c.key === e.target.value
              );
              setHtml(compToLoad.html);
              setCurrentComponent(compToLoad);
              if (!options && e.target.value) {
                let results = null;
                try {
                  // eslint-disable-next-line global-require
                  results = require(`../mocks/sampleData/${e.target.value}.json`);
                  setOptions(JSON.stringify(results, null, 4));
                } catch (_) {
                  // do nothing;
                }
              }
            }}
          >
            <option>Select Component To Load...</option>
            {components.map((bc) => (
              <option key={bc.key}>{bc.key}</option>
            ))}
          </select>
          <button
            onClick={() => {
              let formatted = htmlVal.replaceAll('\\"', '"');
              formatted = formatHTML(formatted);
              setHtml(formatted);
            }}
            type="button"
          >
            Format
          </button>
          <button
            onClick={() => {
              let unform = htmlVal.replaceAll('"', '\\"');
              unform = unform.replaceAll(/>\s*</gi, '><');
              unform = unform.replaceAll(/%}\s*{%/gi, '%}{%');
              unform = unform.replaceAll(/>\s*{%/gi, '>{%');
              unform = unform.replaceAll(/%}\s*</gi, '%}<');
              unform = unform.replaceAll(/%}\s*{{/gi, '%}{{');
              unform = unform.replaceAll(/}}\s*{%/gi, '}}{%');
              setHtml(unform);
            }}
            type="button"
          >
            Prep for file.
          </button>
          <CodeMirror
            value={htmlVal}
            extensions={[html({})]}
            theme="dark"
            height="700px"
            onChange={setHtml}
          />
        </div>
        <div>
          <h1>Options</h1>
          <button
            onClick={() => {
              const jsonObj = JSON.parse(options);
              setOptions(JSON.stringify(jsonObj, null, 4));
            }}
            type="button"
          >
            Format
          </button>

          <CodeMirror
            value={options}
            onChange={setOptions}
            theme="dark"
            height="700px"
            extensions={[json({})]}
          />
        </div>
      </div>
      <div>
        <div
          style={{ width: 600, backgroundColor: 'white', margin: '24px auto' }}
        >
          <RenderedComponent
            component={optionsAsObj}
            global={optionsAsObj.global}
            components={comps}
            componentName="Test"
            setId="62715d60941f0641c3c74373"
          />
        </div>
      </div>
    </div>
  );
};

export default Tester;
