import { Icon, Stack } from '@shopify/polaris';
import { ExitMajor } from '@shopify/polaris-icons';
import React from 'react';
import Divider from './Divider';
import TemplateTitle from './TemplateTitle';

const BuilderTopBarContext = () => (
  <div
    style={{
      width: 480,
      paddingLeft: 16,
    }}
  >
    <Stack alignment="center">
      <a className="exit-button" href="/Notifications">
        <Icon source={ExitMajor} color="base" />
        <span className="exit-button-text">Exit</span>
      </a>
      <Divider height={56} />
      <TemplateTitle />
    </Stack>
  </div>
);

export default BuilderTopBarContext;
