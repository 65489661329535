import { Select } from '@shopify/polaris';
import React, { useEffect, useState, useContext } from 'react';
import { getSpently } from '../../../util/spentlyRest';
import { getShop } from '../../util/auth';
import OptionsContext from '../../../state/OptionsContext';

const AllDropdownOption = {
  label: 'All Products',
  value: 'all',
  id: 'all',
};

const CollectionsDropdown = ({
  value,
  onChange,
  defaultValue,
  label,
  isSetup,
  supportLabel,
}) => {
  const [cols, setCols] = useState(null);
  const shop = getShop('spently');

  const {
    dropdown,
    setDropdown,
    customCursor,
    setCustomCursor,
    smartCursor,
    setSmartCursor,
  } = useContext(OptionsContext);

  function findCommonElements(arr1, arr2) {
    return arr1.some((item) => arr2.includes(item));
  }

  useEffect(() => {
    // add all option in the collection dropdown if default value is all,
    // otherwise remove all option for the dropdown
    const toggleAllOption = (toUpdatecols) => {
      if (!toUpdatecols) {
        return toUpdatecols;
      }

      const allOptionIndex = toUpdatecols.indexOf(AllDropdownOption);

      if (defaultValue === 'all' && allOptionIndex < 0) {
        toUpdatecols.push(AllDropdownOption);
      } else if (defaultValue !== 'all' && allOptionIndex > -1) {
        toUpdatecols.splice(allOptionIndex, 1);
      }

      return toUpdatecols;
    };

    const loadCollections = async () => {
      const colRes = await getSpently('/api/Collections');
      const fromStore = colRes.collections.map((c) => ({
        label: c.title,
        value: `${c.id}`,
        id: c.id,
      }));

      setCols(fromStore);
      setDropdown(fromStore);
      setCustomCursor(colRes.customLink);
      setSmartCursor(colRes.smartLink);

      if (colRes[0] && isSetup) {
        onChange(colRes[0].id);
      }
    };
    let matchCollections = null;

    if (dropdown.length !== 0 && cols != null) {
      const temp = findCommonElements(dropdown, cols);
      matchCollections = temp;
    }
    // First time load
    if (!cols && dropdown.length === 0) {
      loadCollections();
    }
    // Has more collections meaning we need to update dropdown
    else if (cols && matchCollections === null) {
      setDropdown(toggleAllOption(cols));
    }
    // If the two collections dont match it means we need to merge collections
    // and update cols
    else if (matchCollections === false) {
      const mergedCollections = cols.concat(dropdown);
      setCols(mergedCollections);
      setDropdown(mergedCollections);
    }
    // due to multiple component renders this is a fall back to ensure we
    // always update the dropdown menu
    else {
      setCols(toggleAllOption(dropdown));
    }
  }, [
    cols,
    defaultValue,
    shop,
    isSetup,
    onChange,
    dropdown,
    customCursor,
    smartCursor,
    setDropdown,
    setCustomCursor,
    setSmartCursor,
    supportLabel,
  ]);
  return (
    <>
      <Select
        value={value || ''}
        onChange={(selectedCollectionId) => {
          if (!supportLabel) {
            onChange(selectedCollectionId);
            return;
          }

          const collectionName = cols.find(
            (collection) => collection.id === selectedCollectionId
          ).label;

          onChange([selectedCollectionId, collectionName].join('|'));
        }}
        options={cols}
        label={label}
      />
    </>
  );
};

export default CollectionsDropdown;
