/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
  ActionList,
  FormLayout,
  Icon,
  Stack,
  Subheading,
  TextField,
  TextStyle,
} from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';
import React, { useState } from 'react';

const PanFilteredSelect = ({ items, title }) => {
  const [filter, setFilter] = useState('');

  // filter the menu item with provided filter keyword
  const choices = [];

  items.forEach((singleItem) => {
    const filteredItems = singleItem.items.filter(
      (item) =>
        (item.content.toLowerCase ? item.content : item.key)
          .toLowerCase()
          .indexOf(filter.toLowerCase()) > -1
    );

    if (filteredItems.length > 0) {
      choices.push({
        title: singleItem.title,
        items: filteredItems,
      });
    }
  });

  const simulateTabForDown = (evt) => {
    if (evt.keyCode === 40) {
      const listHolder = document.getElementById('list-holder');
      if (listHolder) {
        listHolder.getElementsByTagName('button')[0].focus();
      }
    }
  };

  return (
    <div style={{ padding: '0.25rem' }}>
      <FormLayout>
        <div onKeyDown={simulateTabForDown}>
          <TextField
            autoFocus
            value={filter}
            onClearButtonClick={() => setFilter('')}
            onChange={setFilter}
            clearButton
            prefix={<Icon source={SearchMinor} />}
          />
        </div>
        {title && (
          <div style={{ paddingLeft: '1rem' }}>
            <Subheading>
              <TextStyle variation="subdued">{` ${title}`}</TextStyle>
            </Subheading>
          </div>
        )}
        <div style={{ marginTop: -16 }}>
          {choices.length > 0 && (
            <div id="list-holder">
              <ActionList sections={choices} actionRole="menuitem" />
            </div>
          )}
          {choices.length === 0 && (
            <Stack alignment="center" vertical>
              <TextStyle variation="strong">No results found</TextStyle>
              <TextStyle>Try another search query</TextStyle>
              <TextStyle>
                <br />
              </TextStyle>
            </Stack>
          )}
        </div>
      </FormLayout>
    </div>
  );
};

export default PanFilteredSelect;
