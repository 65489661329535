import React from 'react';
import {
  Card,
  Stack,
  DisplayText,
  TextStyle,
  SkeletonDisplayText,
} from '@shopify/polaris';
import { translate } from '../../util/localizer';

const SingleRow = ({ titleKey, value }) => {
  const title = translate(titleKey);
  return (
    <Card sectioned>
      <div style={{ minHeight: 52 }}>
        {/* TODO: this is a hack that needs to be removed */}
        <Stack distribution="equalSpacing">
          <DisplayText size="small">{title}</DisplayText>
          {value && (
            <DisplayText size="small">
              <TextStyle variation="positive">{value}</TextStyle>
            </DisplayText>
          )}
          {!value && (
            <div style={{ width: 90 }}>
              <SkeletonDisplayText size="small" />
            </div>
          )}
        </Stack>
      </div>
    </Card>
  );
};

export default SingleRow;
